import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

class FrontPage extends Component {
  // bump commit

  constructor(props) {
    super(props);

    this.state = {
      imports: {},
      reloadInterval: 5000,
      reloadTimer: undefined,
      allowNewImport: true,
      newImportName: '',
      importWarning: '',
      showYamlInputComponent: false,
    };
  }

  loadImports() {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    };

    fetch(process.env.REACT_APP_BE_API_HOST + '/api/import/list', requestOptions)
      .then(res => res.json())
      .then((data) => {
        this.setState({ imports: data['imports'] });
        this.checkImportStatus();
      })
      .catch(console.log);

  }



  checkImportStatus() {
    const startedImports = this.state.imports.find(im => im.status === 'STARTED');
    // const desiredInterval = (startedImports && Object.keys(startedImports).length > 0) ? 3000 : 60000;
    const allowNewImport = (startedImports && Object.keys(startedImports).length > 0) ? false : true;
    this.setState({
      // reloadInterval: desiredInterval,
      allowNewImport: allowNewImport,
    });
  }



  handleImportNameChange = event => {
    this.setState({ newImportName: event.target.value });
  }



  handleImportSubmit = event => {

    event.preventDefault();

    if (!this.state.newImportName || this.state.newImportName.length === 0) {

      this.setState({ importWarning: 'Photoset name may not be empty!' });

    } else {

      const existingNames = this.state.imports.find(im => im.name === this.state.newImportName);
      // console.log(existingNames);
      // console.log(existingNames.length);
      const existingNameConflict = (existingNames && Object.keys(existingNames).length > 0) ? true : false;

      if (existingNameConflict) {

        this.setState({ importWarning: 'A photoset with the name \'' + this.state.newImportName + '\' already exists!' });

      } else {

        this.setState({ importWarning: '' });

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ "name": this.state.newImportName }),
        };

        fetch(process.env.REACT_APP_BE_API_HOST + '/api/import/start', requestOptions)
          .then(res => res.json())
          .then((data) => {
            if (data['success']) {
              this.loadImports();
              this.setState({ newImportName: '' });
              // this.setState({ reloadInterval: 3000 });
            } else {
              console.log('ERROR in /api/import/start response');
              console.log(requestOptions.body);
              console.log(data);
            }
          })
          .catch(console.log);

      }
    }

  }

  componentDidMount() {
    this.loadImports();
    this.setState({ reloadTimer: setInterval(() => this.loadImports(), this.state.reloadInterval) });
  }

  componentWillUnmount() {
    clearInterval(this.state.reloadTimer);
  }

  render() {

    // const imageSets = [
    //   'MUSEUM : LANDMARKS/LIBRARY/',
    //   'MUSEUM : LANDMARKS/MUSEUM/',
    //   'MUSEUM : LANDMARKS/ZOO/',
    //   'HOTEL',
    //   'HOMES NEIGHBOURHOOD/00 HOUSE/01 MODERN/Merirahu 58',
    // ];

    return (
      <div className="container is-fluid">
        <div className="section frontpage">
          <div className="columns">



            <div className="column">

              <h2 className="title is-3">Browse images</h2>
              <p><Link to={`/browse/`}>Browse images</Link></p>

            </div>



            <div className="column">

              <h2 className="title is-3">Locations</h2>
              <p><Link to={`/locations/`}>List all locations</Link></p>

            </div>



            <div className="column">

              <h2 className="title is-3">Photosets</h2>

              <ul>
                {this.state.imports.length > 0 && this.state.imports.map((im) => (
                  <div key={im.name}>
                    {['FINISHED', 'STARTED'].includes(im.status) &&
                      <div key={im.name} className="photoset">

                        {im.status === 'FINISHED' &&
                          <>
                            <Link to={`/images/${im.name}`}>{im.name}</Link>
                            <br /><small>
                              {im.importedCount} photos
                              {Number.isInteger(im.extractedCount) && <>, {im.extractedCount} enriched</>}
                            </small>
                          </>
                        }
                        {im.status === 'STARTED' &&
                          <>
                            <span className={`tag is-warning`}><FontAwesomeIcon icon={faCircleNotch} spin size="xs" style={{ marginRight: '0.4rem' }} /> Importing...</span> <span>{im.name}</span>
                            <br /><small>{im.importedCount} photos imported</small>
                          </>
                        }

                        <br />
                      </div>
                    }
                  </div>
                ))}
              </ul>

            </div>



            <div className="column">

              {/* <h2 className="title is-3">Import new photoset</h2>

              <form onSubmit={this.handleImportSubmit}>

                <div className="columns is-multiline">

                  <div className="column is-full">
                    <p>Upon starting the import, all files in the <em>locationunit-enrichment-import</em> S3 bucket will be imported, until the bucket is empty.</p>
                  </div>


                  {this.state.importWarning && (this.state.importWarning.length > 0) &&
                    <div className="column is-full">
                      <article className="message is-warning">
                        <div className="message-header">
                          <p>{this.state.importWarning}</p>
                        </div>
                      </article>
                    </div>
                  }


                  <div className="column is-three-quarters">
                    <div className="field">

                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          name="name"
                          placeholder="Name your photoset"
                          onChange={this.handleImportNameChange}
                          value={(this.state.allowNewImport) ? this.state.newImportName : 'Import in progress...'}
                          disabled={(!this.state.allowNewImport)}
                        />
                      </div>

                    </div>
                  </div>


                  <div className="column is-one-quarter">
                    <button
                      className="button level-item is-submit is-primary"
                      type="submit"
                      disabled={(!this.state.allowNewImport)}
                    >Submit</button>
                  </div>


                </div>

              </form>

              <hr></hr> */}

              <h2 className="title is-3">Update tagging structure</h2>
              <div className="columns is-multiline">
                <div className="column is-full">
                  <button
                    className="button level-item is-submit is-primary"
                    onClick={this.props.openYamlComponent}
                  >Open</button>
                </div>
              </div>
            </div>


            {/* 
          <div className="column">

            <h2 className="title is-3">Photosets (LEGACY)</h2>

            <ul>
              {imageSets.map((imageSet) => {
                const imageSetEncoded = encodeURIComponent(imageSet);
                const imageSetKey = imageSetEncoded.substring(-10);
                return (
                  <li key={imageSetKey}>
                    <Link to={`/images/${imageSetEncoded}`}>{imageSet}</Link>
                  </li>
                );
              })}
            </ul>

          </div> */}

          </div>
        </div >
      </div >
    );

  }

}

export default FrontPage;