import { useState, useEffect } from 'react';
import { useMapEvents } from 'react-leaflet'

const MapEditable = ({ 
  center, mapZoom, 
  handleMapCenterChange, 
  handleMapCenterZoomChange,
  saveNewMarkerShift, 
  setMapIsDragged, 
  coordinates
}) => {
  
  const [mapPannable, setMapPannable] = useState(true);

  const getNewMarkerShift = () => {
    const mapBoundsDegrees = map.getBounds()
    const ne = mapBoundsDegrees._northEast.lng
    const sw = mapBoundsDegrees._southWest.lng
    const mapDegreesWidth = ne - sw
    const newMarkerLngShift = mapDegreesWidth / 10
    saveNewMarkerShift(newMarkerLngShift)
    //console.log(ne - sw)
  } 
  
  const map = useMapEvents({
    dragstart: () => {
      if (coordinates.length === 1) {
        setMapIsDragged(true)
      }
      setMapPannable(false)
    },
    drag: () => {
      const center = map.getCenter()
      handleMapCenterChange(center);
    },
    dragend: () => {
      setMapIsDragged(false)
      setMapPannable(true)
    },
    zoomend: () => {
      const center = map.getCenter()
      handleMapCenterZoomChange(center);

      getNewMarkerShift();
    },
  });

  useEffect(() => {
    if (mapPannable) {
      map.panTo(center);
    }
    // eslint-disable-next-line
  },[center])

  useEffect(() => {
    if (coordinates.length > 1) {
        setTimeout(() => {
          map.invalidateSize();
          map.fitBounds(coordinates, {padding: [50, 50]})
        }, 100)
    }
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    if (mapPannable) {
      map.setView(center, mapZoom)
    }
    // eslint-disable-next-line
  },[mapZoom])

  useEffect(() => {
    getNewMarkerShift();
    // eslint-disable-next-line
  },[])
  
  return null;
}

export { MapEditable };