import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Selecto from 'react-selecto';
import { load } from 'js-yaml';

import { ImageSummaryRow, ImageSummaryReferenceRow } from '../components/imageSummary';
import ImageDetail from '../components/imageDetail';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

class ImageList extends Component {

  constructor(props) {
    super(props);
    // this.imageDetailComponent = React.createRef();

    this.state = {
      isLoading: true,
      showOnlyStarred: false,
      prefix: '',
      images: [],
      referenceData: {},
      selectedImageIds: [],
      previousImageTags: null,
      previouslySavedTags: [],
      tagTree: load(this.props.tagTreeYaml),
    };

    // console.log('ImageList constructed');
    //console.log(props.user['http://example.com/uuid'])
  }

  selectAllRows = () => {
    console.log("Selecting all rows!")
    let updatedSelectedRows = [];

    this.state.images.forEach(image => {
      updatedSelectedRows.push(String(image.id));
    })

    this.setState({ selectedImageIds: updatedSelectedRows, previousImageTags: null })
  }

  deselectAllRows = () => {
    console.log("Deselect all!")
    this.setState({ selectedImageIds: [], previousImageTags: null })
  }

  loadImages() {

    // if (typeof prefix === 'undefined' || !prefix) prefix = this.state.prefix;

    const payload = {
      'prefix': this.state.prefix
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    };

    fetch(process.env.REACT_APP_BE_API_HOST + '/api/list', requestOptions)
      .then(res => res.json())
      .then((data) => {
        console.log(data['images']['content'])
        this.setState({
          images: data['images']['content'],
          isLoading: false,
        }, () => this.scrollToActiveRow())
      })
      .then(() => {
        const urlIds = this.props.match.params.imageIds?.split('-');
        const urlAttributeType = this.props.match.params.attributeType;
        if (urlIds && urlAttributeType) {
          const images = urlIds.map(urlId => this.state.images.find(image => String(image.id) === urlId)).filter(Boolean);
          this.selectImages(images, urlAttributeType);
          this.scrollToActiveRow();
        }

      })
      .catch(console.log);

    // console.log(this.state.images)
  }



  reloadImages(ids) {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'ids': ids
      })
    };

    fetch(process.env.REACT_APP_BE_API_HOST + '/api/details', requestOptions)
      .then(res => res.json())
      .then((data) => {
        if (data['images']) {
          const dataImages = data['images'];
          let newStateImages = [...this.state.images];
          let currentlyEditingImages = [...this.props.currentlyEditingImages]
          ids.forEach(idToReplace => {
            const indexInState = newStateImages.findIndex(image => image.id === idToReplace);
            const indexInData = dataImages.findIndex(image => image.id === idToReplace);
            newStateImages[indexInState] = dataImages[indexInData];

            if (currentlyEditingImages.length !== 0) {
              const indexInCurrentlyEditingImages = currentlyEditingImages.findIndex(image => image.id === idToReplace);
              currentlyEditingImages[indexInCurrentlyEditingImages] = dataImages[indexInData];
            }

          });
          // console.log(newStateImages)
          this.setState({ images: newStateImages }, () => {
            if (currentlyEditingImages.length !== 0) {
              this.props.setCurrentlyEditingImages([...currentlyEditingImages])
              this.setPreviousImageTags([...currentlyEditingImages]);
            }
          });
        }
      })
      .catch(console.log);

  }

  loadReferenceData() {

    const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json' } };
    fetch(process.env.REACT_APP_BE_API_HOST + '/api/ref-data', requestOptions)
      .then(res => res.json())
      .then((data) => {
        // console.log(data)
        this.setState({ referenceData: data['ref'] });

      })
      .catch(console.log);
  }



  handleStarClick(image) {
    // console.log('star click', image);
    const nextStarredState = (image.starred === 'YES') ? 'NO' : 'YES';

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'ids': [image.id],
        'name': 'STARRED',
        'values': [nextStarredState]
      })
    };

    fetch(process.env.REACT_APP_BE_API_HOST + '/api/update', requestOptions)
      .then(res => res.json())
      .then((data) => {
        // console.log(data);
        this.reloadImages([image.id]);
      })
      .catch(console.log);
  }


  handleAttributeClick(image, attributeType) {

    let image_ids = '';
    let images = [];
    // console.log(`state len ${this.state.selectedImages.length}`);

    if (this.state.selectedImageIds.includes(String(image.id)) && this.state.selectedImageIds.length > 1) {

      image_ids = this.state.selectedImageIds.join('-');
      this.state.selectedImageIds.forEach(id => {
        images.push(this.state.images.find(image => String(image.id) === id));
      })

    } else {

      image_ids = image.id;
      images = [image];

    }

    // - - - - - - -

    // console.log('change ' + attributeType + ' for ' + image_ids);

    // const newPath = this.props.match.url + '/edit/' + image_ids;
    // this.props.history.push(newPath);
    this.props.history.push(`/images/${this.state.prefix}/edit/${image_ids}/${attributeType}`);
    this.selectImages(images, attributeType);
  }

  selectImages(images, attributeType) {
    this.props.setCurrentlyEditingImages(images);
    this.props.setCurrentlyEditingAttribute(attributeType);
    this.props.setDetailModalIsActive(true);
    this.setPreviousImageTags(images);
  }

  scrollToActiveRow() {
    const firstActiveRow = document.getElementsByClassName('imagesummaryrow--is-active')[0];
    if (firstActiveRow) {
      console.log('scroll to ', firstActiveRow);
      firstActiveRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      // console.log('nowhere to scroll to');
    }
  }

  setPreviousImageTags(images) {
    const prevTags = this.getPreviousImageTags(images);
    this.setState({ previousImageTags: prevTags });
  }

  getPreviousImageTags(images) {
    if (!images || (Array.isArray(images) && images.length === 0)) {
      return null;
    }
    const firstImageIndex = this.state.images.findIndex(image => image.id === images[0].id);
    if (firstImageIndex === 0) {
      return null;
    } else {
      const previousImage = this.state.images[firstImageIndex - 1];
      const prevTags = {
        'CONTENT_TAG': previousImage.contentTags,
        'SITUATION': previousImage.situation,
        'TIME_OF_DAY': previousImage.timeOfDay,
        'TIME_OF_YEAR': previousImage.timeOfYear,
        'TONALITY': previousImage.tonality,
        'VANTAGE_POINT': previousImage.vantagePoint,
      };
      return prevTags;
    }

  }

  setPreviouslySavedTags(values) {
    this.setState({ previouslySavedTags: values });
  }

  /*
  addToSelectedImages(id) {

    let newSelectedImages = [...this.state.selectedImages];
    newSelectedImages.push(this.state.images.find(image => String(image.id) === id));
    this.setState({ selectedImages: newSelectedImages });

  }

  removeFromSelectedImages(id) {

    let newSelectedImages = [...this.state.selectedImages];
    const removeIndex = this.state.images.findIndex(image => String(image.id) === id);
    newSelectedImages.splice(removeIndex, 1);
    this.setState({ selectedImages: newSelectedImages });

  }
  */

  componentDidMount() {
    this.setState({ user: this.props.user });

    const { prefix } = this.props.match.params;
    const decodedPrefix = decodeURIComponent(prefix);

    this.setState({ user: this.props.user });

    // set prefix in state, load images in callback
    this.setState({ prefix: decodedPrefix }, () => this.loadImages());
    this.setState({ user: this.props.user });

    this.props.loadLocations();
    this.loadReferenceData();

  }

  componentDidUpdate(prevProps) {
    if (prevProps.tagTreeYaml !== this.props.tagTreeYaml) {
      this.setState({ tagTree: load(this.props.tagTreeYaml) });
    }
    this.scrollToActiveRow();
  }

  render() {
    // console.log(this.props.match.path);
    return (
      <>
        {this.props.detailModalIsActive &&
          <ImageDetail {...this.props}
            isActive={this.props.detailModalIsActive}
            imageViewerIsActive={this.props.imageViewerIsActive}
            prefix={this.state.prefix}

            showOnlyStarred={this.state.showOnlyStarred}

            locations={this.props.locations}
            referenceData={this.state.referenceData}

            allImages={this.state.images}
            images={this.props.currentlyEditingImages}
            getPreviousImageTags={() => this.getPreviousImageTags()}
            previousImageTags={this.state.previousImageTags}
            setPreviouslySavedTags={values => this.setPreviouslySavedTags(values)}
            previouslySavedTags={this.state.previouslySavedTags}

            attribute={this.props.currentlyEditingAttribute}
            setCurrentlyEditingAttribute={(newAttributeType) => {
              this.props.history.replace(`/images/${this.state.prefix}/edit/${this.props.match.params.imageIds}/${newAttributeType}`);
              this.props.setCurrentlyEditingAttribute(newAttributeType);
            }}
            reloadImages={(imageIds) => this.reloadImages(imageIds)}
            loadReferenceData={() => this.loadReferenceData()}
            loadLocations={() => this.props.loadLocations()}

            setCurrentlyEditingImages={(images) => {
              this.props.setCurrentlyEditingImages(images);
              this.setState({ previousImageTags: this.getPreviousImageTags(images) });
            }
            }
            closeDetail={() => {
              this.props.history.push(`/images/${this.state.prefix}`);
              this.setState({ previousImageTags: null });
              this.props.closeDetail();
            }}

            imageToLightbox={(image) => this.props.imageToLightbox(image)}
            manyImagesToLightbox={(images) => this.props.manyImagesToLightbox(images)}
            lightboxImages={this.props.lightboxImages}
            removeImageFromLightbox={(image) => this.props.removeImageFromLightbox(image)}
            handleViewerCloseClick={() => this.props.closeViewer()}
            showLargeImages={(image) => this.props.showLargeImages(image)}
            imageViewerShowsImagePath={this.props.imageViewerShowsImagePath}
            imageViewerShowsImage={this.props.imageViewerShowsImage}
            getLocationForId={(id) => this.props.getLocationForId(id)}
            tagTreeYaml={this.props.tagTreeYaml}
          />
        }


        <div className="section imagelist">
          <div className="container is-fluid">
            <div className="level">
              <div className="level-left level-item">
                <h2 className="title is-3">{this.state.prefix}&hellip;</h2>
              </div>

              <div className="level-right level-item">
                <button
                  onClick={() => this.setState({ showOnlyStarred: !this.state.showOnlyStarred })}
                  className="button is-info is-outlined"
                  type="button">
                  {this.state.showOnlyStarred ? "Show all" : "Show only starred"}
                </button>

                {this.state.selectedImageIds.length === 0 ?
                  <button
                    onClick={this.selectAllRows}
                    className="button is-info is-outlined"
                    type="button"
                  >
                    Select all rows
                  </button>
                  :
                  <button
                    onClick={this.deselectAllRows}
                    className="button is-info is-outlined"
                    type="button"
                  >
                    Deselect all rows
                  </button>
                }
              </div>
            </div>

            {/* <Link to={`${this.props.match.url}/edit/1`}>Edit number one</Link> */}
            <div className={`imagelist_images ${this.state.showOnlyStarred ? 'imagelist__showonlystarred' : ''}`}>

              <div className="imagelist__dragmask"></div>

              <ImageSummaryReferenceRow />

              {(this.state.isLoading || !this.state.referenceData.simpleAttributes) &&
                <div className="section has-text-centered">
                  <FontAwesomeIcon icon={faCircleNotch} spin size="4x" color="#ddd" />
                </div>
              }

              {this.state.images && this.state.referenceData.simpleAttributes && Object.keys(this.state.images).map((key) => {

                const image = this.state.images[key];
                const imageIsActive = this.props.currentlyEditingImages.includes(image);
                const imageIsSelected = this.state.selectedImageIds.includes(String(image.id));

                return (
                  <ImageSummaryRow
                    key={`image_${image.id}`}
                    image={image}
                    location={this.props.getLocationForId(image.locationId)}
                    isActive={imageIsActive}
                    isSelected={imageIsSelected}
                    prefix={this.state.prefix}
                    referenceData={this.state.referenceData}
                    onStarClick={(id) => this.handleStarClick(id)}
                    onAttributeClick={(id, attribute) => this.handleAttributeClick(id, attribute)}
                    tagTree={this.state.tagTree}
                  />
                );

              })}

            </div>
          </div>
        </div>
        <Selecto
          // The container to add a selection element
          container={[".imagelist__images"]}
          // The area to drag selection element (default: container)
          dragContainer={".imagelist__dragmask"}
          // Targets to select. You can register a queryselector or an Element.
          selectableTargets={[".imagesummaryrow:not(.imagesummaryrow--reference) .is-drag-selectable"]}
          // Whether to select by click (default: true)
          selectByClick={false}
          // Whether to select from the target inside (default: true)
          selectFromInside={true}
          // After the select, whether to select the next target with the selected target (deselected if the target is selected again).
          continueSelect={false}
          // Determines which key to continue selecting the next target via keydown and keyup.
          toggleContinueSelect={"shift"}
          // The container for keydown and keyup events
          keyContainer={window}
          // The rate at which the target overlaps the drag area to be selected. (default: 100)
          hitRate={1}
          onSelect={e => {
            // console.log(e);
            let selectedIds = [];
            e.selected.forEach(el => {
              const imageId = el.getAttribute('data-imageid');
              selectedIds.push(imageId);
            });
            this.setState({ selectedImageIds: selectedIds });
            /*
            e.added.forEach(el => {
                const imageId = el.getAttribute('data-imageid');
                this.addToSelectedImages(imageId);
            });
            e.removed.forEach(el => {
                const imageId = el.getAttribute('data-imageid');
                this.removeFromSelectedImages(imageId);
            });
            */
          }}
        />

      </>
    );
  };

}

export default withRouter(ImageList);