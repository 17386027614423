import React from 'react';
import ReactDOMServer from "react-dom/server";
import { Marker} from 'react-leaflet'
import Logo from "../lu-tools-logo-white-background.svg";
import L from "leaflet";

const MinimapLocationMarkerComponent = ({ location, iconType }) => {

  const mapMarkerIcon = iconType === "locuIcon" ? L.divIcon({
    className: "custom-icon",
    iconAnchor:   [15, 30], // point of the icon which will correspond to marker's location
    html: ReactDOMServer.renderToString(
      <img src={Logo} alt="Logo" width="30" height="30" />,
    ),
  }) 
  :
  L.divIcon({
    className: "custom-icon-dot",
    iconAnchor:   [3, 14], // point of the icon which will correspond to marker's location
    html: ReactDOMServer.renderToString(
      <span>&#8226;</span>,
    ),
  }) ;

  return (
    <>
      {location == null ? null : 
        <Marker position={location} icon={mapMarkerIcon} interactive={false}>
        </Marker>
      }
    </>
  );
}

export { MinimapLocationMarkerComponent };