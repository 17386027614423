import React from 'react';
import { ImagesIcon, LightboxCreatorIcon, ViewIcon, RadioToggleOnIcon, RadioToggleOffIcon, EditIcon, DownloadImagesIcon } from './icons.js';

const LightboxSummary = props => {
    let lightbox = props.lightbox;
    let lightboxId = props.lightbox.id;
    let isCurrentLightbox = lightboxId === props.currentLightbox.id;
    let rowDisplayStyle = isCurrentLightbox ? '--active' : '';

    return (
        <>
            <div className="lightboxsummary">
                <div className={`lightboxsummary__level-container lightboxsummary__level-container${rowDisplayStyle} mb-0`}>
                    <div className="level is-mobile lightboxsummary__level mb-0">
                    <div className="level-left mr-1">
                        <div className="level-item">
                                <button 
                                    className="lightboxsummary__button button is-link is-inverted" 
                                    onClick={() => props.handleCurrentLightboxChange(lightboxId)}>
                                      {isCurrentLightbox ? 
                                        <RadioToggleOnIcon fill="#3273dc" size="18"  /> 
                                        :
                                        <RadioToggleOffIcon fill="#666666" size="18"  /> 
                                      }
                                </button>
                        </div>
                        <div className="level-item lightboxsummary__level--name mr-0">
                            {props.lightbox.name}
                        </div>
                    </div>
                        <div className="level-right">
                          <div className="level-item">
                              <span 
                                  className="lightboxsummary__images-count">
                                      <LightboxCreatorIcon fill="#666666" size="18"  /> 
                                      <span>{props.lightbox.createdBy}</span>
                              </span>
                          </div>
                          <div className="level-item ml-5">
                              <span 
                                  className="lightboxsummary__images-count">
                                      <ImagesIcon fill="#666666" size="18"  /> 
                                      {props.lightbox.images.length === 1 ?
                                      <span>1 photo</span>
                                      :
                                      <span>{props.lightbox.images.length} photos</span>
                                      }
                              </span>
                          </div>
                          {props.allowEdit &&
                          <div className="level-item ml-5">
                              <button 
                                  className="lightboxsummary__button button is-link is-inverted" 
                                  onClick={() => props.handleEditClick(props.lightbox)}>
                                      <EditIcon fill="#3273dc" size="18"  /> 
                                      <span>edit</span>
                              </button>
                          </div>
                          }
                          {props.lightbox.images.length > 0 &&
                          <div className="level-item ml-5">
                              <button 
                                  className="lightboxsummary__button button is-link is-inverted" 
                                  onClick={() => props.showLargeLightboxImagesById(lightboxId)}>
                                      <ViewIcon fill="#3273dc" size="18"  /> 
                                      <span>view</span>
                              </button>
                          </div>
                          }
                          {lightbox.images.length > 0 &&
                          <div className="level-item ml-5">
                              <button 
                                  className="lightboxsummary__button button is-link is-inverted" 
                                  onClick={() => props.downloadImages(lightbox)}>
                                      <DownloadImagesIcon fill="#3273dc" size="18"  /> 
                                      <span>Download</span>
                              </button>
                          </div>
                          }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export { LightboxSummary };