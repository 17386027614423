import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ContactDetails from './contactDetails';

import { SituationIcon, ContentTagsIcon, LocationCategoryIcon, DownloadImagesIcon, ImagesIcon, CheckedIcon, RemoveFromLightboxIcon, LocationNotesIcon, EditIcon } from './icons.js';
import { MiniMapComponent } from './miniMapComponent'

const SearchSummary = props => {

    const [mapHeight, setMapHeight] = useState(0);

    const handleImageLoad = (event) => {
        const imageHeight = event.target.clientHeight;
        if (imageHeight < 200) {
            setMapHeight(imageHeight)
        } else {
            setMapHeight(150)
        }
    }

    useEffect(() => {
        if (Object.keys(props.location).length !== 0 && 
        props.location.constructor === Object && 
        props.location.length === 0 && mapHeight === 0) {
            setMapHeight(150);
        }
        // eslint-disable-next-line
    },[props.location])


    const imageColumnSize = 'is-one-third'
    let displayTitle = 'No image provided';
    // console.log(props.location)
    // console.log(props.searchRelevantImages)   
    let searchRelevantImagesContentTags = []; 

    props.searchRelevantImages.forEach((image => {
        image.contentTags.forEach((tag)=> {
            if (!searchRelevantImagesContentTags.some((tg) => tg === tag)) {
                searchRelevantImagesContentTags.push(tag);
            }
        })
    }))

    let searchQueryKeywords = props.searchQueryPayload.keywords;
    let mapCenter = props.location.coords.length !== 0 ? [props.location.coords[0].lat, props.location.coords[0].lng] : []

    return (
        <>
            <div className="searchsummary">
                <div className="searchsummary__level-container">
                    <div className="level mb-0">
                        <div className="level-left">
                            <div className="level-item searchsummary__level--name mr-0">
                                {props.location.name}
                            </div>
                        </div>
                        <div className="level-right">
                            {props.searchRelevantImages.length !== 0 &&
                                <div className="level-item">
                                    <span className="searchsummary__images-count">
                                        <ImagesIcon fill="#666666" size="18"  /> 
                                        {props.searchRelevantImages.length === 1 ?
                                        <span>1 photo</span>
                                        :
                                        <span>{props.searchRelevantImages.length} photos</span>
                                        }
                                    </span>
                                </div>
                            }
                            {props.searchRelevantImages.length > 1 &&
                                <div className="level-item ml-5">
                                    <button className="searchsummary__button button is-link is-inverted" 
                                        onClick={() => props.manyImagesToLightbox(props.searchRelevantImages)}>
                                        <DownloadImagesIcon fill="#3273dc" size="18"  /> 
                                        <span>add all to lightbox</span>
                                    </button>
                                </div>
                            }
                            
                            
                        </div>
                    </div>
                    <div className="columns is-marginless">
                        <div className="column is-3 pl-0">
                            {mapCenter.length !== 0 ? 
                                <div style={{ height: mapHeight }} className="minimap__component-expanded mb-4" onClick={() => props.openMapViewer(props.location)}>
                                    <MiniMapComponent onClick={() => props.openMapViewer(props.location)}
                                        mapCenter={mapCenter}
                                        mapZoom={14}
                                        coordinates={props.location.coords}
                                        mapVersion={"medium"}
                                    />
                                </div>
                                :
                                <div style={{ height: mapHeight }} className="minimap__component-expanded-empty mb-4"><div>Not added to the map yet</div></div>
                            }
                            <div>
                                {(props.location.attributes.CONTACT_PHONE || props.location.attributes.CONTACT_EMAIL || props.location.attributes.CONTACT_NAME) &&
                                    <div className="">
                                        <ContactDetails 
                                            name={props.location.attributes.CONTACT_NAME}
                                            phone={props.location.attributes.CONTACT_PHONE}
                                            email={props.location.attributes.CONTACT_EMAIL}
                                            isRowExpanded={props.isRowExpanded}

                                        />
                                    </div>
                                }
                                {props.location.attributes.CATEGORY &&
                                    <div className="searchsummary__attribute-info--expanded ">
                                        <LocationCategoryIcon fill="black" size="18"  /> 
                                        <span className="searchsummary__attribute-value">
                                            {String(props.location.attributes.CATEGORY).toLowerCase().replace('_', ' ')}
                                        </span>
                                    </div>
                                }
                                {props.location.attributes.SITUATION &&
                                    <div className="searchsummary__attribute-info--expanded ">
                                        <SituationIcon fill="black" size="18"  /> 
                                        <span className="searchsummary__attribute-value">
                                            {String(props.location.attributes.SITUATION).toLowerCase().replace('_', ' ')}
                                        </span>
                                    </div>
                                }
                                {searchRelevantImagesContentTags.length > 0 &&
                                    <div className="searchsummary__attribute-info--expanded keywords">
                                        <ContentTagsIcon fill="black" size="18"  /> 
                                        {searchRelevantImagesContentTags.map((tag) => {
                                            let displayColor = searchQueryKeywords.includes(tag) ? 'is-success' : '';
                                            return (
                                                <div className="tags" key={tag}>
                                                <span className={`tag is-medium searchsummary__attribute-value searchsummary__attribute-value-types ${displayColor} `} key={tag}>
                                                    {String(tag).toLowerCase().replace('_', ' ')}
                                                </span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                                {props.location.attributes.NOTES &&
                                    <div className="columns is-paddingless contactdetails--expanded contactdetails--expanded-notes">
                                        <span className="column is-paddingless is-narrow contactdetails__notes">
                                            <LocationNotesIcon fill="black" size="18"  />
                                        </span>
                                        <span className="column contactdetails__fragment--notes--expanded">{props.location.attributes.NOTES}</span>
                                        
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="column is-9 ">
                            <div className="searchsummary__expanded-row-images columns is-multiline is-vcentered">
                                {props.searchRelevantImages.map((image) => {
                                    return (
                                        <div className={`column ${imageColumnSize}`} key={`relevant-image_column_${image.id}`}>
                                            <div className="searchsummary__expanded-row-image">
                                                <LazyLoadImage 
                                                    className={`searchsummary__image searchsummary__image--can-be-enlarged`} 
                                                    key={`image_${image.id}`} 
                                                    src={image.sources.m} 
                                                    alt={displayTitle} 
                                                    onClick={() => props.showLargeImages(image, props.searchRelevantImages)}
                                                    onLoad={handleImageLoad}
                                                />

                                                {props.lightboxImages.some(lightboxImage => lightboxImage.id === image.id) &&
                                                    <span className="searchsummary__expandedimages-already-in-lightbox">
                                                        <CheckedIcon fill="green" size="12"  />
                                                    </span>
                                                }
                                                {!props.lightboxImages.some(lightboxImage => lightboxImage.id === image.id) ? 
                                                    <span className="searchsummary__expandedimages-add-image-to-lightbox"
                                                        onClick={() => props.imageToLightbox(image)}>
                                                        <DownloadImagesIcon fill="white" size="12"  />
                                                    </span>
                                                :
                                                    <span className="searchsummary__expandedimages-remove-image-from-lightbox"
                                                        onClick={() => props.removeImageFromLightbox(image)}>
                                                        <RemoveFromLightboxIcon fill="red" size="12"  />
                                                    </span>
                                                }
                                                {props.showEditIcon &&
                                                    <span className="searchsummary__expandedimages-edit-image"
                                                        onClick={() => props.handleImageEditClick(image, props.searchRelevantImages)}>
                                                        <EditIcon fill="white" size="12"  />
                                                    </span>
                                                } 
                                            </div>
                                        </div>
                                    );
                        
                                })}

                                <div className={`searchsummary__otherimages column is-one-third`} key={`image_column_otherImages`}>
                                    
                                    <div className="columns is-multiline is-variable is-1 searchsummary__otherimages-row-image">
                                        {props.otherImages.slice(-4).map((image) => {
                                            return (
                                                <div className={`column is-half py-1 `} key={`other-image_column_${image.id}`}>
                                                    <div className="searchsummary__otherimage-container">
                                                    <LazyLoadImage
                                                        className={`searchsummary__otherimage searchsummary__image--can-be-enlarged`} 
                                                        key={`image_${image.id}`} 
                                                        src={image.sources.s} 
                                                        alt={displayTitle} 
                                                        onClick={() => props.showLargeImages(image, props.otherImages)}
                                                    />
                                                    {props.showEditIcon &&
                                                    <span className="searchsummary__expandedimages-edit-image"
                                                        onClick={() => props.handleImageEditClick(image, props.otherImages)}>
                                                        <EditIcon fill="white" size="12"  />
                                                    </span>
                                                    } 
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>    
                            </div>
                        </div>          
                    </div>
                </div>
            </div>
        </>
    );
}


export { SearchSummary };