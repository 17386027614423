import React from 'react';
import ReactDOMServer from "react-dom/server";
import { Marker, Popup } from 'react-leaflet'
import Logo from "../lu-tools-logo-white-background.svg";
import L from "leaflet";

const LargeMapLocationMarkerComponent = ({ position, location }) => {

  const mapMarkerIcon = L.divIcon({
    className: "custom-icon",
    iconAnchor:   [15, 30], // point of the icon which will correspond to marker's location
    popupAnchor:  [0, -30], // point from which the popup should open relative to the iconAnchor
    html: ReactDOMServer.renderToString(
      <img src={Logo} alt="Logo" width="30" height="30" />,
    ),
  });

  return (
    <>
      {position == null ? null : 
        <Marker 
          position={position} 
          icon={mapMarkerIcon}
        >
          <Popup >
            <h2><b>{location.name}</b></h2>
          </Popup>
        </Marker>
      }
    </>
  );
}

export { LargeMapLocationMarkerComponent };