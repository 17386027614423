import React, { useState, useEffect } from 'react';
import { LinkToggleOffIcon, LinkToggleOnIcon } from "./icons.js";
import { DetailHeader, DetailSection } from './modalDetails.js';

const LightboxModal = props => {

  // console.log(props)

  const lightbox = props.lightbox;
  const [editedLightboxName, setEditedLightboxName] = useState("");
  const [importWarning, setImportWarning] = useState("");
  const [linkCopied, setLinkCopied] = useState(false);
  const baseUrl = window.location.origin;

  useEffect(() => {
    if  (props.lightbox) {
      setEditedLightboxName(props.lightbox.name)
    }
    // eslint-disable-next-line
  }, []);

  const handleEditedLightBoxNameChange = (event) => {
      setEditedLightboxName(event.target.value);
  };

  const handleEditLightboxSubmit = (event) => {
    event.preventDefault();

    if (!editedLightboxName || editedLightboxName.length < 2) {

      setImportWarning('Lightbox name has to be at least 2 characters long!');

    } else {

      if ((props.lightbox.name !== editedLightboxName) && props.allLightboxes.some((lb) => lb.name === editedLightboxName)) {
        setImportWarning('A lightbox with the name \'' + editedLightboxName + '\' already exists!');
      } else {

        setImportWarning("");

        props.editLightbox(props.lightbox, editedLightboxName);
        props.handleCloseClick();
        setEditedLightboxName("");
      }
    }
  };

  const copyToClipboard = (containerid) => {
    var range = document.createRange();
    range.selectNode(document.getElementById(containerid));
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand("copy");
    window.getSelection().removeAllRanges(); // to deselect

    setLinkCopied(true);
  };

    let modalClass = props.lightboxDetailModalIsActive ? "is-active" : "";

    return (
        <>
        <div className={`modal ${modalClass}`}>
          <div className="modal-background" onClick={() => props.handleCloseClick()}></div>
            <div className="modal-card">
              <section className="modal-card-body">
                <DetailHeader 
                  handleCloseClick={props.handleCloseClick} 
                  details={props.lightbox}
                  label={"lightbox"}
                />
                <form onSubmit={handleEditLightboxSubmit}>
                <div className="columns is-variable is-5">
                  <div className="modal__details column is-narrow">
                    {props.lightbox &&
                      <DetailSection
                        details={props.lightbox}
                      />
                    }
                  </div>
                  
                  <div className="column">
                    <div className="field">
                      <label htmlFor="" className="label title is-5">Name</label>
                      {importWarning && (importWarning.length > 0) && 
                        <div className="">
                          <article className="message is-warning">
                            <div className="message-header">
                              <p>{importWarning}</p>
                            </div>
                          </article>
                        </div>
                      }
                      <div className="control">
                        <input type="text" className="input" name="name" placeholder={props.lightbox.name} onChange={handleEditedLightBoxNameChange} value={editedLightboxName} />
                      </div>
                    </div>
                    <div className="level">
                      <div className="level-left modal__clickable px-1" onClick={() => props.handleLightboxSharing(lightbox)}>
                        <div className="level-item">
                          <div className="columns is-variable is-1">
                            <div className="column is-narrow">
                              <div>
                                  {props.lightbox.shareKey ? 
                                    <LinkToggleOnIcon fill="#CC0000" size="20"  /> 
                                    :
                                    <LinkToggleOffIcon fill="#666666" size="20"  /> 
                                  }
                                </div>
                            </div>
                            <div className="column is-narrow">
                              <span>
                                  <span className="header__lightbox--title title is-5">
                                    Allow sharing
                                  </span>
                                  {props.lightbox.shareKey ? 
                                    <p id="shareLink" className="share-link">{baseUrl + `/shared/${props.lightbox.shareKey}`}</p>
                                    :
                                    <p className="share-link">Enable this to create a shareable link</p>
                                  }
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {props.lightbox.shareKey && 
                      <div className="level-right">
                        <div className="level-item">
                          <button
                            className="button is-light"
                            type="button"
                            onClick={() => copyToClipboard('shareLink')}
                          >
                            {!linkCopied ? <span>Copy link</span> : <span>Copied!</span>} 
                          </button>
                        </div>
                      </div>
                      }
                    </div>
                    {props.lightbox.shareKey &&
                    <div className="level">
                      <div className="level-left modal__clickable px-1" onClick={() => props.handleLightboxWatermarking(lightbox)}>
                        <div className="level-item">
                          <div className="columns is-variable is-1">
                            <div className="column is-narrow">
                              <div>
                                  {props.lightbox.watermark ? 
                                    <LinkToggleOnIcon fill="#CC0000" size="20"  /> 
                                    :
                                    <LinkToggleOffIcon fill="#666666" size="20"  /> 
                                  }
                                </div>
                            </div>
                            <div className="column is-narrow">
                              <span>
                                  <span className="header__lightbox--title title is-5">
                                    Watermark images
                                  </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    }
                    <div className="level">
                      <div className="level-left">
                      </div>
                      <div className="level-right">
                        <div className="level-item">
                          <button
                            className="button is-submit is-primary"
                            type="submit"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </form>
              </section>
            </div>
        </div>
        </>
    );
}


export { LightboxModal };