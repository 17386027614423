import React, { useState, useEffect } from 'react';
import ContactDetails from './contactDetails';
import { DetailSection } from './modalDetails';
import { SituationIcon, ContentTagsIcon, LocationCategoryIcon, EditIcon, CollapseIcon, DownloadImagesIcon, ImagesIcon, CheckedIcon, RemoveFromLightboxIcon  } from './icons.js';
import {MiniMapComponent} from './miniMapComponent'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const LocationSummary = props => {

    const [mapHeight, setMapHeight] = useState(0);

    const handleImageLoad = (event) => {
        const imageHeight = event.target.clientHeight;
        if (imageHeight < 200) {
            setMapHeight(imageHeight)
        } else {
            setMapHeight(150)
        }
    }

    useEffect(() => {
        if (Object.keys(props.expandedLocation).length !== 0 && 
        props.expandedLocation.constructor === Object && 
        props.expandedLocationImages.length === 0 && mapHeight === 0) {
            setMapHeight(150);
        }
        // eslint-disable-next-line
    },[props.expandedLocation])
    
    const imageColumnSize = (props.expandedLocationImages.length === 1) ? '' : 'is-one-third'
    let displayTitle = 'No image provided';
    const isRowExpanded = (props.location.id === props.expandedLocation.id) ? true : false;
    let mapCenter = props.location.coords.length !== 0 ? [props.location.coords[0].lat, props.location.coords[0].lng] : []

    // To secure that all other changes (rows collapsing and expanding) 
    // on the DOM have occurred before we scroll to the top of the expanded row 
    const handleExpand = (loc) => (e) => {
        props.expandLocationlistRow(loc);
        if (e.currentTarget.id) {
            let id = e.currentTarget.id
            setTimeout(() => {
                props.setScrollValue(id);
            }, 200);
        }
    };
    
    return (
        <>
        {isRowExpanded && 
            <div id={`${props.location.id}`}  className="locationsummary" >
                <div className="locationsummary__level-container">
                    <div className="level locationsummary__level mb-0">
                        <div className="level-left">
                            <div className="level-item locationsummary__level--name mr-0">
                                {props.location.name}
                            </div>
                        </div>
                        <div className="level-right">
                            {props.expandedLocationImages.length !== 0 &&
                                <div className="level-item">
                                    <span 
                                        className="locationsummary__images-count">
                                            <ImagesIcon fill="#666666" size="18"  /> 
                                            {props.expandedLocationImages.length === 1 ?
                                            <span>1 photo</span>
                                            :
                                            <span>{props.expandedLocationImages.length} photos</span>
                                            }
                                    </span>
                                </div>
                            }
                            {props.expandedLocationImages.length > 1 &&
                                <div className="level-item ml-5">
                                    <button 
                                        className="locationsummary__button button is-link is-inverted" 
                                        onClick={() => props.manyImagesToLightbox(props.expandedLocationImages)}>
                                            <DownloadImagesIcon fill="#3273dc" size="18"  /> 
                                            <span>add all to lightbox</span>
                                    </button>
                                </div>
                            }
                            <div className="level-item ml-5">
                                <button 
                                    className="locationsummary__button button is-link is-inverted" 
                                    onClick={() => props.handleEditClick(props.location)}>
                                        <EditIcon fill="#3273dc" size="18"  /> 
                                        <span>edit</span>
                                </button>
                            </div>
                            <div className="level-item ml-5">
                                <button 
                                    className="locationsummary__button button is-link is-inverted" 
                                    onClick={() => props.collapseLocationlistRow()}>
                                        <CollapseIcon fill="#3273dc" size="18"  /> 
                                        <span>collapse</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="columns is-marginless">
                        <div className="column is-3 pl-0">
                        {mapCenter.length !== 0 ? 
                            <div style={{ height: mapHeight }} className="minimap__component-expanded mb-4" onClick={() => props.openMapViewer(props.location)}>
                                <MiniMapComponent onClick={() => props.openMapViewer(props.location)}
                                    mapCenter={mapCenter}
                                    mapZoom={14}
                                    coordinates={props.location.coords}
                                    mapVersion={"medium"}
                                />
                            </div>
                            :
                            <div style={{ height: mapHeight }} className="minimap__component-expanded-empty mb-4">
                                <div>Not added to the map yet
                                    {props.expandedLocationImageObjects.length !== 0 && 
                                    <>
                                        <br />
                                        <br />
                                        <span>
                                            But click "edit" to see where <br /> {props.expandedLocationImageObjects.length} location {props.expandedLocationImageObjects.length === 1 ? "image was" : "images were"} taken
                                        </span>
                                    </>
                                    }
                                </div>
                            </div>
                        }
                            <div>
                                {(props.expandedLocation.attributes.CONTACT_NAME || props.expandedLocation.attributes.CONTACT_PHONE || props.expandedLocation.attributes.CONTACT_EMAIL || props.expandedLocation.attributes.NOTES) &&
                                    <div className="">
                                            <ContactDetails
                                                name={props.expandedLocation.attributes.CONTACT_NAME} 
                                                notes={props.expandedLocation.attributes.NOTES}
                                                phone={props.expandedLocation.attributes.CONTACT_PHONE}
                                                email={props.expandedLocation.attributes.CONTACT_EMAIL}
                                                isRowExpanded={isRowExpanded}

                                            />
                                    </div>
                                }
                                <DetailSection 
                                    details={props.location}
                                />
                            </div>
                        </div>

                        <div className="column is-9 ">
                            <div className="locationsummary__expanded-row-images columns is-multiline">
                                {props.expandedLocationImages.map((image) => {
                                    return (
                                        <div className={`column ${imageColumnSize}`} key={`image_column_${image.id}`}>
                                            <div className="locationsummary__expanded-row-image">
                                                <LazyLoadImage 
                                                    className={`locationsummary__image locationsummary__image--can-be-enlarged`} 
                                                    key={`image_${image.id}`} 
                                                    src={imageColumnSize === '' ? image.sources.m : image.sources.m} 
                                                    alt={displayTitle} 
                                                    onClick={() => props.showLargeImages(image)}
                                                    onLoad={handleImageLoad}
                                                />

                                                {props.lightboxImages.some(lightboxImage => lightboxImage.id === image.id) &&
                                                    <span className="locationsummary__expandedimages-already-in-lightbox">
                                                        <CheckedIcon fill="green" size="12"  />
                                                    </span>
                                                }
                                                {!props.lightboxImages.some(lightboxImage => lightboxImage.id === image.id) ? 
                                                    <span className="locationsummary__expandedimages-add-image-to-lightbox"
                                                        onClick={() => props.imageToLightbox(image)}>
                                                        <DownloadImagesIcon fill="white" size="12"  />
                                                    </span>
                                                :
                                                    <span className="locationsummary__expandedimages-remove-image-from-lightbox"
                                                        onClick={() => props.removeImageFromLightbox(image)}>
                                                        <RemoveFromLightboxIcon fill="red" size="12"  />
                                                    </span>
                                                }
                                                {props.showEditIcon &&
                                                    <span className="searchsummary__expandedimages-edit-image"
                                                        onClick={() => props.handleImageEditClick(image)}>
                                                        <EditIcon fill="white" size="12"  />
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    );
                        
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        {!isRowExpanded &&
            <div id={`${props.location.id}`}  className="locationsummary" onClick={handleExpand(props.location)}>
                <div className="locationsummary__level-container">
                    <div className="columns">
                        <div className="column is-narrow">
                        {mapCenter.length !== 0 ? 
                            <div className="minimap__component mt-1 is-zoom-in" onClick={() => props.openMapViewer(props.location)}>
                                <MiniMapComponent 
                                    mapCenter={mapCenter}
                                    mapZoom={14}
                                    coordinates={props.location.coords}
                                    mapVersion={"small"}
                                />
                            </div>
                            :
                            <div className="minimap__component-empty mt-1"></div>
                        }
                        </div>
                        <div className="column">
                            <div className="level locationsummary__level mb-0">
                                <div className="level-left">
                                    <div className="level-item locationsummary__level--name mr-0">
                                        {props.location.name}
                                    </div>
                                    <div className="level-item">
                                        <ContactDetails 
                                            name={props.location.attributes.CONTACT_NAME} 
                                            notes={props.location.attributes.NOTES}
                                            phone={props.location.attributes.CONTACT_PHONE}
                                            email={props.location.attributes.CONTACT_EMAIL}
                                            imageNrInLocation={props.imageNrInLocation}
                                            isRowExpanded={isRowExpanded}
                                        />
                                    </div>
                                </div>
                                <div className="level-right">
                                    <div className="level-item">
                                        <button 
                                            className="locationsummary__button button is-link is-inverted" 
                                            onClick={() => props.handleEditClick(props.location)}>
                                                <EditIcon fill="#3273dc" size="18"  /> 
                                                <span>edit</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="level">
                                <div className="level-left">
                                    {props.location.attributes.CATEGORY &&
                                        <div className="level-item locationlist__attribute-info mr-5">
                                            <LocationCategoryIcon fill="black" size="18"  /> 
                                            <span className="locationlist__attribute-value">
                                                {String(props.location.attributes.CATEGORY).toLowerCase().replace('_', ' ')}
                                            </span>
                                        </div>
                                    }
                                    {props.location.attributes.SITUATION &&
                                        <div className="level-item locationlist__attribute-info mr-5">
                                            <SituationIcon fill="black" size="18"  /> 
                                            <span className="locationlist__attribute-value">
                                                {String(props.location.attributes.SITUATION).toLowerCase().replace('_', ' ')}
                                            </span>
                                        </div>
                                    }
                                    {props.location.types.length > 0 &&
                                        <div className="level-item locationlist__attribute-info" >
                                            <ContentTagsIcon fill="black" size="18"  /> 
                                            {props.location.types.map((type) => (
                                                <span className="locationlist__attribute-value" key={type}>
                                                    {String(type).toLowerCase().replace('_', ' ')}
                                                </span>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    );
}


export { LocationSummary };