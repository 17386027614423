import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import ImageDetail from '../components/imageDetail';
import LocationDetail from '../components/locationDetail';
import { LocationSummary } from '../components/locationSummary';
import ImageViewer from '../components/imageViewer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import MapViewer from '../components/mapViewer';

class LocationList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            locations: [],
            imageNrInAllLocations: [],
            referenceData: {},
            locationReferenceData: {},
            expandedLocation: {},
            expandedLocationImages: [],
            mapViewerIsActive: false,
            mapViewerShowsLocation:{},
            showEditIcon: false,
        }
    }

    onKeyDown = (event) => {
        if (event.keyCode === 18) {
            event.preventDefault();
            this.setState({ showEditIcon: true });
        }
    }
      
    onKeyUp = (event) => {
        if (event.keyCode === 18) {
            event.preventDefault();
            this.setState({ showEditIcon: false });
        }
    }

    handleImageEditClick = (image) => {
        this.props.setCurrentlyEditingImages([image]);
        this.props.setCurrentlyEditingAttribute("CONTENT_TAG");
        this.props.setDetailModalIsActive(true);
    }

    reloadImages(ids) {
        
        const requestOptions = { 
          method: 'POST', 
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            'ids': ids
          }) 
        };
    
        fetch(process.env.REACT_APP_BE_API_HOST + '/api/details', requestOptions)
        .then(res => res.json())
        .then((data) => {
            if (data['images']) {
                const dataImages = data['images'];

                let newExpandedLocationImages = [...this.state.expandedLocationImages];

                ids.forEach(idToReplace => {
                    const indexInData = dataImages.findIndex(image => image.id === idToReplace);

                    if (newExpandedLocationImages.findIndex(image => image.id === idToReplace) !== -1) {
                        const imageIndexInState = newExpandedLocationImages.findIndex(image => image.id === idToReplace);
                        newExpandedLocationImages[imageIndexInState] = dataImages[indexInData];
                    }
                });

                this.setState({ 
                    expandedLocationImages: newExpandedLocationImages,
                });
            }
        })
        .catch(console.log);
        this.expandLocationlistRow(this.state.expandedLocation);
    }

    loadReferenceData() {

        const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json' } };
        fetch(process.env.REACT_APP_BE_API_HOST + '/api/ref-data', requestOptions)
        .then(res => res.json())
        .then((data) => {
        // console.log(data)
        this.setState({ referenceData: data['ref'] });
        })
        .catch(console.log);
    }

    loadLocationReferenceData() {

        const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json' } };
        fetch(process.env.REACT_APP_BE_API_HOST + '/api/location/ref-data', requestOptions)
            .then(res => res.json())
            .then((data) => {
                // console.log(data)
                const types = data['ref']['types'];
                const sortedTypes = types.sort(function(a, b) {
                    var textA = a.toUpperCase();
                    var textB = b.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })
                // console.log(sortedTypes);
                this.setState({ locationReferenceData: {'types': sortedTypes} });
            })
            .catch(console.log);
    }

    loadLocations() {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        };

        fetch(process.env.REACT_APP_BE_API_HOST + '/api/location/list', requestOptions)
            .then(res => res.json())
            .then((data) => {
                // console.log(data)
                const sortedLocations = data['locations'].sort(function(a, b) {
                    var textA = a.name.toUpperCase();
                    var textB = b.name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })
                this.setState({ 
                  locations: sortedLocations,
                  isLoading: false,
                }, () => {
                    this.getNrOfImagesInAllLocations()
                    if (Object.keys(this.state.expandedLocation).length !== 0 &&
                    this.state.expandedLocation.constructor === Object) {
                        let loc = this.state.locations.find((loc) => loc.id === this.state.expandedLocation.id)
                        this.expandLocationlistRow(loc);
                    }
                })
            })
            .catch(console.log);
    }

    async getNrOfImagesInAllLocations() {
        let locationImageNrInfo=[];

        for (let i = 0; i < this.state.locations.length; i++) {
      
            const locImages = await this.loadLocationImages(this.state.locations[i].id);
            locationImageNrInfo.push({
                "locationId": this.state.locations[i].id,
                "nrOfImages": locImages.length,
            });
        }

        this.setState({ imageNrInAllLocations: locationImageNrInfo});
    }

    loadLocationImages(locationId){

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "locationId":locationId
            })
        };
    
        return fetch(process.env.REACT_APP_BE_API_HOST + `/api/location/list-images`, requestOptions)
          .then(res => res.json())
          .then((data) => {
            // console.log(data)
            return data.images;
          })
          .catch(console.log);
    }

    getLocationForId(id) {
        const indexInState = this.state.locations.findIndex(location => location.id === id);
        return this.state.locations[indexInState];
      }

    saveLocation(location) {
        console.log('Saving location: ');
        // console.log(location);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                'location': location,
                'markAsFresh': false 
            })
        };

        // console.log(requestOptions.body);

        fetch(process.env.REACT_APP_BE_API_HOST + '/api/location/save', requestOptions)
            .then(res => res.json())
            .then((data) => {
                // console.log(data);

                this.loadLocationReferenceData();
                this.loadLocations();
                
            })
            .catch(console.log);

        
        this.props.closeDetail();
    }


    showAddLocationDialog() {
        this.props.setLocationDetailModalIsActive(true);
        this.props.setCurrentlyAddingNewLocation(true);
    }


    componentDidMount() {
        window.addEventListener("keydown", this.onKeyDown);
        window.addEventListener("keyup", this.onKeyUp);
        this.loadLocations();
        this.loadReferenceData();
        this.loadLocationReferenceData();
    }

    setScrollValue = (id) => {
        let element = document.getElementById(id);
        if (element) {
            const yOffset = -10; 
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'}); 
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentlyEditingImages !== this.props.currentlyEditingImages) {
            this.loadLocations();
          }
        if (prevProps.lightboxImages !== this.props.lightboxImages) {
          this.expandLocationlistRow(this.state.expandedLocation);
        }
    }

    async expandLocationlistRow(loc) {
        const expandedLocImages = await this.loadLocationImages(loc.id);
        this.setState({expandedLocationImages: expandedLocImages});
        this.setState({expandedLocation: loc});
    }
    
    collapseLocationlistRow() {
        this.setState({expandedLocation: {}});
        this.setState({expandedLocationImages: []});
    }

    openMapViewer(location) {
        this.setState( { mapViewerShowsLocation: location, mapViewerIsActive: true })
    }

    handleMapViewerCloseClick() {
        this.setState({ mapViewerIsActive: false, mapViewerShowsLocation: {} })
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown)
        window.removeEventListener('keyup', this.onKeyUp)
        this.props.closeDetail();
    }

    render() {
        // console.log(this.props)
        let expandedLocationImageObjects = [];
        this.state.expandedLocationImages.forEach((image) => {
            if (image.gpsCoords) {
                expandedLocationImageObjects.push({
                    "coords" : image.gpsCoords,
                    "source" : image.sources.l,
                })
            }
        })
        return (
            <>
                {/* <Route 
                    path={[`${this.props.match.path}/edit/:ids`, `${this.props.match.path}/add`]} 
                    render={(props) => (
                        <LocationDetail {...props}
                            isActive={this.state.detailModalIsActive}
                            isAddingNewLocation={this.state.currentlyAddingNewLocation}
                            currentlyEditingLocation={this.state.currentlyEditingLocation}
                            handleCloseClick={() => this.closeDetail()}
                        />
                    )}
                /> */}
                {this.props.locationDetailModalIsActive && 
                
                <LocationDetail
                    isActive={this.props.locationDetailModalIsActive}
                    isAddingNewLocation={this.props.currentlyAddingNewLocation}
                    location={this.props.currentlyEditingLocation}
                    existingLocations={this.state.locations}
                    referenceData={this.state.locationReferenceData}
                    handleCloseClick={() => this.props.closeDetail()}
                    expandedLocationImageObjects={expandedLocationImageObjects}
                    handleSaveClick={(location) => this.saveLocation(location)}
                />
                }
                <div className="container is-fluid">
                    <div className="section locationlist">
                        <div className="columns is-5">
                            <div className="column is-3 locationlist__left">
                                <div className="locationlist__title">
                                    <h1 className="title is-3 ">Locations</h1>
                                </div>
                                <div className="locationlist__text">
                                    To create a new location, you must specify a name. No other info is mandatory.
                                </div>
                                <div className="locationlist__add-new-location-button">
                                    <Link 
                                        to={`${this.props.match.path}/add`} 
                                        className="button is-outline is-primary" 
                                        onClick={() => this.showAddLocationDialog()}
                                    >Add a location</Link>
                                </div>
                            </div>

                            {this.state.isLoading &&
                                <div className="section has-text-centered">
                                    <FontAwesomeIcon icon={faCircleNotch} spin size="4x" color="#ddd" /> 
                                </div>
                            }
                            <div className="column is-9 locationlist__right">
                                {this.state.locations.map((location) => {
                                    let imageNrInLocation = this.state.imageNrInAllLocations.length !== 0 ?
                                     this.state.imageNrInAllLocations.find((loc) => loc.locationId === location.id)?.nrOfImages : false;
                                     
                                    return (
                                    <LocationSummary 
                                        key={`location_${location.id}`}
                                        location={location} 
                                        handleEditClick={(loc) => this.props.editLocation(loc)}
                                        expandLocationlistRow={(loc) => this.expandLocationlistRow(loc)}
                                        setScrollValue={(id) => this.setScrollValue(id)}
                                        collapseLocationlistRow={() => this.collapseLocationlistRow()}
                                        expandedLocation={this.state.expandedLocation}
                                        expandedLocationImages={this.state.expandedLocationImages}
                                        manyImagesToLightbox={(images) => this.props.manyImagesToLightbox(images)}
                                        showLargeImages= {(image) => this.props.showLargeExpandedLocationImages(image)}
                                        imageToLightbox={(image) => this.props.imageToLightbox(image)}
                                        lightboxImages={this.props.lightboxImages}
                                        removeImageFromLightbox={(image) => this.props.removeImageFromLightbox(image)}
                                        handleViewerCloseClick={() => this.props.closeViewer()}
                                        imageViewerShowsImagePath={this.props.imageViewerShowsImagePath}
                                        imageViewerShowsImage={this.props.imageViewerShowsImage}
                                        locationDetailModalIsActive={this.props.locationDetailModalIsActive}
                                        expandedLocationImageViewerIsActive={this.props.expandedLocationImageViewerIsActive}
                                        imageNrInLocation={imageNrInLocation}
                                        openMapViewer={(location) => this.openMapViewer(location)}
                                        showEditIcon={this.state.showEditIcon}
                                        handleImageEditClick={(image)=>this.handleImageEditClick(image)}
                                        expandedLocationImageObjects={expandedLocationImageObjects}
                                    />
                                )})}
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.expandedLocationImageViewerIsActive &&
                    <ImageViewer 
                        sharedMode={false}
                        images={this.state.expandedLocationImages} 
                        imageViewerIsActive={this.props.expandedLocationImageViewerIsActive}
                        handleViewerCloseClick={() => this.props.closeViewer()}
                        lightboxImages={this.props.lightboxImages}
                        imageToLightbox={(image) => this.props.imageToLightbox(image)}
                        showLargeImages= {(image) => this.props.showLargeExpandedLocationImages(image)}
                        imageViewerShowsImagePath={this.props.imageViewerShowsImagePath}
                        imageViewerShowsImage={this.props.imageViewerShowsImage}
                        removeImageFromLightbox={(image) => this.props.removeImageFromLightbox(image)}
                        getLocationForId={(id) => this.getLocationForId(id)}
                        lightboxCreator={false}
                        lightboxName={false}
                        user={false}
                    />
                }
                {this.props.currentlyEditingImages && this.props.currentlyEditingImages.length !== 0 &&
                    <ImageDetail {...this.props} 
                        isActive={this.props.detailModalIsActive}
                        imageViewerIsActive={this.props.imageViewerIsActive}
                        prefix={""}
                        locations={this.state.locations}
                        referenceData={this.state.referenceData}
                        allImages={this.props.currentlyEditingImages}
                        images={this.props.currentlyEditingImages}
                        attribute={this.props.currentlyEditingAttribute}
                        setCurrentlyEditingAttribute={this.props.setCurrentlyEditingAttribute}
                        reloadImages={(imageIds) => this.reloadImages(imageIds)}
                        loadReferenceData={() => this.loadReferenceData()}
                        loadLocations={() => this.loadLocations()}     
                        setCurrentlyEditingImages={(images) => this.props.setCurrentlyEditingImages(images)}
                        closeDetail={() => this.props.closeDetail()}
                        imageToLightbox={(image) => this.props.imageToLightbox(image)}
                        manyImagesToLightbox={(images) => this.props.manyImagesToLightbox(images)}
                        lightboxImages={this.props.lightboxImages}
                        removeImageFromLightbox={(image) => this.props.removeImageFromLightbox(image)}
                        handleViewerCloseClick={() => this.props.closeViewer()}
                        showLargeImages= {(image) => this.props.showLargeImages(image)}
                        imageViewerShowsImagePath={this.props.imageViewerShowsImagePath}
                        imageViewerShowsImage={this.props.imageViewerShowsImage}
                        getLocationForId={(id) => this.props.getLocationForId(id)}
                    />
                }
                {this.state.mapViewerIsActive && 
                    <MapViewer
                        mapViewerIsActive={this.state.mapViewerIsActive}
                        location={this.state.mapViewerShowsLocation}
                        allLocations={this.state.locations}
                        handleViewerCloseClick={() => this.handleMapViewerCloseClick()}
                    />
                }
            </>
        );
    }
}

export default withRouter(LocationList);