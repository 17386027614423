import React, { useRef, useImperativeHandle, forwardRef} from "react"
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
// import { Capitalize } from "../utils/capitalize";

export const TagSelectFormCheckboxes = forwardRef(({
    options,
    // selectedTagOptions,
    tagGroup,
    setCurrentlySelectingTagGroup,
    handleStartSavingTags,
    // temporaryTagSelectionFormSelectedTags,
    // setTemporaryTagFormSelectedTags,
    // temporarySelectionHistory,
    // setTemporarySelectionHistory,
    savedTagSelectionHistory,
    showNextImageDetail
}, ref) => {

    const formRef = useRef();
    const switchImageAfterSave = useRef();

    const submitForm = () => {
        if (formRef.current) {
          formRef.current.handleSubmit();
        }
      }

    useImperativeHandle(ref, () => ({
        saveTags (switchImage) {
            switchImageAfterSave.current = switchImage;
            submitForm();
        }
      }), []);

    // console.log(tagGroup)
    const { allow } = tagGroup
    const type = allow;
    // console.log(type)
    const minTagsToSelect = type === "zero-or-more" ? 0 : 1;

    // console.log(savedTagSelectionHistory)
    // console.log(tagGroup)

    const prefilledTags = (savedTagSelectionHistory.find((group) => group.group.name === tagGroup.name))?.selectedTags
    // const lowerCasePreFilledTags = prefilledTags && prefilledTags.map((tag) => (tag))
    // console.log(prefilledTags)

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                // picked: selectedTagOptions,
                picked: prefilledTags && prefilledTags.length !== 0 ? prefilledTags : '',
            }}
            validationSchema={
                Yup.object().shape({
                    picked: minTagsToSelect > 0 ? Yup.array().of(Yup.string()).min(minTagsToSelect).required(`Select at least ${minTagsToSelect} options`) : Yup.array().of(Yup.string())
                })
            }
            innerRef={formRef}
            onSubmit={(values, actions) => {
                // console.log(values)
                // console.log(actions)
                // await new Promise((r) => setTimeout(r, 500));
                // alert(JSON.stringify(values, null, 2));
                // console.log(values.picked)

                let nextGroupOption;
                let nextGroupName = "";

                let selectedTags = [];

                // let newSelection;

                if (Array.isArray(values.picked) && values.picked.length !== 0) {

                    values.picked.forEach((value) => {
                        selectedTags.push(tagGroup.name + ":" + value)
                    })

                    const firstPickedValue = values.picked[0]
                    // console.log(values.picked)
                    // console.log(firstPickedValue)

                    // console.log(options)

                    nextGroupOption = options.find((tag) => tag.name.toLowerCase() === firstPickedValue.toLowerCase())
                    // console.log(nextGroupOption)

                    nextGroupName = ("to" in nextGroupOption) ? nextGroupOption.to : null
                    // console.log(nextGroupName)

                    // newSelection = {
                    //     group: tagGroup,
                    //     selectedTags: values.picked
                    // }

                } else if (!values.picked || (Array.isArray(values.picked) && values.picked.length === 0)) {
                    // console.log(options)
                    nextGroupName = ("to" in options[0]) ? options[0].to : null

                    // newSelection = {
                    //     group: tagGroup,
                    //     selectedTags: []
                    // }

                } else {
                    // console.log(options)
                    nextGroupOption = options.find((tag) => tag.name === values.picked)
                    // console.log(nextGroupOption)

                    nextGroupName = ("to" in nextGroupOption) ? nextGroupOption.to : null
                    // console.log(nextGroupName)

                    selectedTags.push(tagGroup.name + ":" + values.picked)

                    // newSelection = {
                    //     group: tagGroup,
                    //     selectedTags: [values.picked]
                    // }
                }
                // stateSelectedTags
                // setTemporaryTagFormSelectedTags(stateSelectedTags)

                // //update history
                // console.log(temporarySelectionHistory)
                // const stateSelectionHistory = [...temporarySelectionHistory]
                // stateSelectionHistory.push(newSelection)
                // // setTemporarySelectionHistory(stateSelectionHistory)


                if (nextGroupName) {
                    setCurrentlySelectingTagGroup(nextGroupName)
                } else {
                    setCurrentlySelectingTagGroup("")
                }

                console.log("Form is starting to save these selected tags: ", selectedTags)
                handleStartSavingTags(selectedTags, tagGroup, switchImageAfterSave.current ? true : false);
                switchImageAfterSave.current = null;

                // With checkboxes necessary to reset form
                actions.resetForm({
                    values: {
                        picked: []
                    }
                })
                if(!nextGroupName) {
                    showNextImageDetail();
                }
            }}
        >
            {({ errors, touched }) => (
                <Form>
                    <div className="tag-selection-container__options-container">
                        <div role="group" aria-labelledby="checkbox-group">
                            {options.map((option, i) => {
                                // console.log(option.name)
                                return (
                                    <span key={tagGroup.name + option.name + i + "checkbox"} className="tag-selection-container__option">
                                        <label style={{ cursor: "pointer" }}>
                                            <Field
                                                id="locationType"
                                                name="picked"
                                                type="checkbox"
                                                value={option.name.toLowerCase()}
                                                style={{ cursor: "pointer" }}
                                            />
                                            {option.name}
                                        </label>
                                    </span>
                                )
                            })}
                        </div>
                        {touched.picked && errors.picked && <div className="tag-selection-container__error-message">{errors.picked}</div>}
                        {/* <div className="submit-button-container">
                            <button type="submit">Submit</button>
                        </div> */}
                    </div>
                </Form>
            )}
        </Formik>
    )
})