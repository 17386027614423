import React from 'react';
import { useAuth0 } from '../contexts/auth0-context';

const LoginGuard = (props) => {

    const { loginWithRedirect } = useAuth0();
    let hintClassName = '';
    let buttonClassName = '';

    const login = () => loginWithRedirect({ appState: { target: window.location.href } });

    if (props.redirect) {
        // setTimeout(() => { loginWithRedirect(); }, 2000);
        login()
    } else {
        hintClassName = 'loginguard__hint--faded';
        buttonClassName = 'is-loading';
    }

    return (
        <div className="hero layout loginguard is-fullheight">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <button onClick={login} className={`button loginguard__button is-warning ${buttonClassName}`}>Login</button>
                    <p className={`loginguard__hint ${hintClassName}`}><small>If you are not redirected automatically, <br />please click above to log in.</small></p>
                </div>
            </div>
        </div>
    );

}

export default LoginGuard;