import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const LoadingComponent = () => {

  return(
    <div className={`modal is-active `}>
      <div className="section has-text-centered">
          <FontAwesomeIcon icon={faCircleNotch} spin size="4x" color="#ddd" />
          <p><b>Loading lightbox, <br />please wait.</b></p> 
      </div>
    </div>
  )
}

export { LoadingComponent };