import React, { Component, createRef } from 'react';
// import { Attribute, SelectableAttribute, SelectableAttributeField } from './attribute';
import { Attribute } from './attribute';
import AttributeSelector from './attributeSelector';
import LocationSelector from './locationSelector';
import ImageViewer from './imageViewer';
import { load } from 'js-yaml';
import TagSelectionForm from './TagSelectionForm';
import { DownloadImagesIcon, RemoveFromLightboxIcon, CheckedIcon } from './icons.js';

class ImageDetails extends Component {

  constructor(props) {
    super(props);

    /*
      + create a newSelectedTags list
      + from regular tags, add to this list, and remove from this list
      + from select element, also use this list
      + show toggleable tags selected / non-selected states 
      + use this list for saving tags
      + when viewing an image with existing tags, construct newSelectedTags from existing tags
      + clean up (this.toggleTag, this.constructor), rename things so there is no ambivalence
      + now you can carry tags across over saves – no need to reset tags list after save
        make sure that old tags update when select is modified and vice versa
      + when skipping to the next image, should also compile tags and fill select
    */

    this.state = {
      selectedTags: [],
      previouslySavedTags: [],
      selectableCreatedTags: [],
      tagFieldOptions: [],
      newCreatedLocation: {},
      imageHeight: null,
      imageWidth: null,
      disabledCreatableOptions: [],
      warning: false,
      creatableSelectDisplayedTags: [],
      tagYamlStructure: null,
      yamlError: false,
      currentlySelectingTagGroup: null,
      currentlySelectingTags: [],
      // temporaryTagSelectionFormSelectedTags: [],
      // temporaryTagSelectionHistory: [],
      savedTagSelectionHistory: [],
    }

    this.tagTreeRef = createRef();
    this.imageDetailsContainerRef = createRef(null);
    this.imageAttributesEditContainerRef = createRef(null);
    this.imageDetailsArrowRef = createRef(null);
    this.imageAttributesEditArrowRef = createRef(null);

    this.keyboardEventFunction = this.keyboardEventFunction.bind(this);
    this.showPreviousImageDetail = this.showPreviousImageDetail.bind(this);
    this.showNextImageDetail = this.showNextImageDetail.bind(this);
  }

  handleTagFieldSelectChange = values => {

    // console.log(values)

    if (values != null) {
      // console.log(values)
      const previouslySelectedTags = this.state.creatableSelectDisplayedTags;
      const allExistingTagOptions = this.state.tagFieldOptions;

      const previousSelectableCreatedTags = this.state.selectableCreatedTags;
      const updatedSelectableCreatedTags = values.filter(createdTag => previousSelectableCreatedTags.includes(createdTag));
      // console.log(updatedSelectableCreatedTags)

      let allExistingAndFreshlyCreatedTags = previouslySelectedTags.concat(allExistingTagOptions);

      const allTagsFromSelectComponent = [];
      for (const value of values) {
        const indexOfNewTagOption = allExistingAndFreshlyCreatedTags.findIndex((tag) => tag.value === value.value)
        if (indexOfNewTagOption === -1) {

          // If completely new created tag option:
          // Then need to make a new object of new created tags because their object structure differs
          const newTagObject = { value: `${value.label}`, label: `OTHER – ${value.label}` }
          // console.log(newTagObject)
          updatedSelectableCreatedTags.push(newTagObject)
          allTagsFromSelectComponent.push(newTagObject)
        } else {
          allTagsFromSelectComponent.push(value)
        }
      }
      this.setState({ creatableSelectDisplayedTags: allTagsFromSelectComponent });
      this.setState({ selectableCreatedTags: updatedSelectableCreatedTags });
    } else {
      // console.log(values)
      this.setState({ creatableSelectDisplayedTags: [] });
      this.setState({ selectableCreatedTags: [] });
    }

    // Roald's existing comments:
    // TODO there should not be a selectedTags and selectedTagsFromSelect, 
    // rather the two elements should modify the same object

    // let selectedTagsFromSelect = [];
    // values.forEach(tag => {
    //   selectedTagsFromSelect.push(tag['value']);
    // });
    // console.log(values);

  }

  compileSelectedTags(requestedTags) {

    let compiledTags = [];
    let tagFieldOptions = this.state.tagFieldOptions;

    // console.log(requestedTags)
    // console.log(tagFieldOptions)
    // console.log(requestedTags)
    // console.log(tagFieldOptions)
    // console.log(requestedTags)
    if (tagFieldOptions.length !== 0) {
      requestedTags.forEach(tag => {
        // console.log(tag)
        // console.log(tagFieldOptions)
        let index = tagFieldOptions.findIndex(tagField => tagField.value.toLowerCase() === tag.toLowerCase());
        // console.log(index)
        if (index !== -1) {
          // console.log(tag)
          compiledTags.push({
            "label": tagFieldOptions[index].label,
            "value": tag,
          });
        }
      });
    }

    // console.error("Compiled tags")
    // console.log("Tag tree updated starting from COMPILE SELECTED TAGS")

    this.setState({ selectedTags: compiledTags }, () => this.handleUpdateTagTree(this.state.tagYamlStructure, this.state.selectedTags));

    // let's get the tagTree here and go trhough all database tags and add to tagHistory the ones that exist in the tree

    if (tagFieldOptions.length !== 0) {
      requestedTags.forEach(tag => {
        // console.log(tag)


        // let index = tagFieldOptions.findIndex(tagField => tagField.value.toLowerCase() === tag.toLowerCase());
        // // console.log(index)
        // if (index !== -1) {
        //   compiledTags.push({
        //     "label": tagFieldOptions[index].label,
        //     "value": tag,
        //   });
        // }
      });
    }

  }

  updateSelectedTags(requestedTags) {
    // console.log("tag compiling started in updateSelectedTags")
    this.compileSelectedTags(requestedTags)
    this.setState({
      selectableCreatedTags: [],
    });
  }

  compileTagFieldOptions(updatedRefData) {

    // console.log(!!updatedRefData)
    // console.log(updatedRefData)

    const refData = updatedRefData ?? this.props.referenceData


    // console.log(refData)

    // console.log(this.props.referenceData)
    if (typeof refData !== 'undefined') {

      // TODO 2020-11-02 
      // - once all options are shown in the Select, start on handleTagFieldSelectChange

      /*
        TODO add attributes to this list

        WARNING this method is duplicated nearly 1:1 in browsePage and imageDetail. Make it not so.
      */

      const tagOptions = [];

      if (typeof refData.tagGroups !== 'undefined') {
        // get all grouped tags, and add them to the tags options group
        for (const [groupName, tags] of Object.entries(refData.tagGroups)) {
          tags.forEach(tag => {
            tagOptions.push({ value: `${tag}`, label: `${groupName} - ${tag}` });
          })
        }
      }

      if (typeof refData.otherTags !== 'undefined') {
        // do the same for all user-defined "other" tags
        refData.otherTags.forEach(tag => {
          tagOptions.push({ value: `${tag}`, label: `OTHER – ${tag}` });
        });
      }
      // console.log(tagOptions)
      // console.log(this.props.images[0].contentTags)
      // console.log("tag compiling started in compileTagFieldOptions")
      this.setState({ tagFieldOptions: tagOptions }, () => this.compileSelectedTags(this.props.images[0].contentTags))

    }
  }

  compileDisabledCreatableSelectOptions = async () => {
    let disabledOptions = [];
    if (this.props.referenceData.simpleAttributes) {
      this.props.referenceData.simpleAttributes.forEach((attr) => {
        // console.log(attr)
        attr.values.forEach((value) => {
          disabledOptions.push(value.toLowerCase());
        })
      })
    }

    // console.log(disabledOptions)

    // let response = await fetch('/struktuur-proto.yaml')
    // let yamlString = await response.text()

    // const data = await load(yamlString)

    const data = await load(this.props.tagTreeYaml);
    // console.log(data.groups);

    data.groups.forEach((group) => {
      group.tags.forEach((tag) => {
        disabledOptions.push(tag.name.toLowerCase())
      })
    });

    const uniqueDisabledOptions = [...new Set(disabledOptions)];

    // console.log("setting disabled Creatable Options!")
    this.setState({ disabledCreatableOptions: uniqueDisabledOptions });
  }

  // setTemporarySelectionHistory = (selectionHistory) => {
  //   // console.log(selectionHistory)
  //   this.setState({
  //     temporaryTagSelectionHistory: selectionHistory
  //   })
  // }

  setSavedSelectionHistory = () => {

  }

  setCurrentlySelectingTagGroup = (currentlySelectingTagGroupName) => {

    // console.log("Changing currently selecting tag group into: ", currentlySelectingTagGroupName)

    if (currentlySelectingTagGroupName !== "") {
      const selectedGroup = this.state.tagYamlStructure.find((group) => group.name === currentlySelectingTagGroupName)



      this.setState({
        currentlySelectingTagGroup: selectedGroup
      }, () => this.handleProvidingCurrentTagSelection(this.state.currentlySelectingTagGroup))

    } else {
      this.setState({
        currentlySelectingTagGroup: null
      })
    }

  }

  // setTemporaryTagFormSelectedTags = (tags) => {
  //   this.setState({
  //     temporaryTagSelectionFormSelectedTags: tags
  //   })
  // }

  handleStartSavingTags = (tagTreeTags, tagGroup, switchImage = false) => {
    this.saveTags(tagTreeTags, tagGroup, switchImage);
  }


  handleProvidingCurrentTagSelection = (currentlySelectingTagGroup) => {

    // console.log(currentlySelectingTagGroup)
    if (currentlySelectingTagGroup && ("tags" in currentlySelectingTagGroup) && ("allow" in currentlySelectingTagGroup)) {

      // Let's start putting together new tags to present:
      // let tagObjects = []

      let tagObjects = currentlySelectingTagGroup.tags.map((tag) => {
        return {
          name: tag.name,
          to: ("to" in currentlySelectingTagGroup) ? currentlySelectingTagGroup.to : (("to" in tag) ? tag.to : null),
          groupName: currentlySelectingTagGroup.name
        }
      })

      if (("include" in currentlySelectingTagGroup) && currentlySelectingTagGroup.include.length > 0) {

        currentlySelectingTagGroup.include.forEach((includedTagGroup) => {

          const group = this.state.tagYamlStructure.find((gr) => gr.name === includedTagGroup.name)

          group.tags.forEach((tag) => {

            tagObjects.push({
              name: tag.name,
              to: ("to" in includedTagGroup) ? includedTagGroup.to : (("to" in group) ? group.to : (("to" in tag) ? tag.to : null)),
              groupName: currentlySelectingTagGroup.name
            })

          })

        })

      }
      // console.log(tagObjects)
      this.setState({ currentlySelectingTags: tagObjects })
    }


  }

  getTagGroup = (tag) => {

    if (!this.state.tagYamlStructure) return

    let tagGroup = false

    this.state.tagYamlStructure.forEach((group) => {
      // console.log(tag)
      // console.log(group.tags)
      // console.log(group)
      if (group.tags.some((groupTag) => groupTag.name.toLowerCase() === tag.toLowerCase())) {
        tagGroup = group.name
      }

    })

    return tagGroup

  }

  compileTagTreeHistory = (currentGroup, restOfTreeTags, tagTreeHistory, count) => {

    console.groupCollapsed("Compiling tag tree history, step nr ", count)
    console.log("Tag tree history at this step is ", tagTreeHistory)
    console.log("Tags remaining to be added to history are ", restOfTreeTags)
    console.log("Tag group at current step is ", currentGroup.name)
    console.log("All group tags at current step are ", currentGroup.tags.map((tag) => tag.name))


    const stateTagTreeHistory = [...tagTreeHistory]
    let remainingTags = [...restOfTreeTags]

    let continueCheckingTree = true;
    let nextGroup = false


    let currentGroupTags = []

    currentGroup.tags.forEach((groupTag) => {
      currentGroupTags.push(groupTag.name.toLowerCase())
    })

    if ("include" in currentGroup && currentGroup.include.length > 0) {
      currentGroup.include.forEach((includedTagGroup) => {

        const group = this.state.tagYamlStructure.find((gr) => gr.name === includedTagGroup.name)

        group.tags.forEach((tag) => {
          currentGroupTags.push(tag.name.toLowerCase())
        })

      })
    }

    if (currentGroup.allow === "exactly-one") {
      console.info("Current step group type is EXACTLY-ONE")

      const indexOfSelectionInSavedTags = restOfTreeTags.findIndex((tag) => (currentGroup.name.toLowerCase() === tag.split(":")[0] && currentGroupTags.includes(tag.split(":")[1])))

      if (indexOfSelectionInSavedTags !== -1) {
        const indexOfSelectionInGroupTags = currentGroup.tags.findIndex((tag) => tag.name.toLowerCase() === restOfTreeTags[indexOfSelectionInSavedTags].split(":")[1])


        console.info("Adding following tag to tag tree history:", remainingTags[indexOfSelectionInSavedTags].split(":")[1])

        stateTagTreeHistory.push({
          group: currentGroup,
          selectedTags: [restOfTreeTags[indexOfSelectionInSavedTags].split(":")[1]]
        })

        remainingTags.splice(indexOfSelectionInSavedTags, 1);

        nextGroup = ("to" in currentGroup.tags[indexOfSelectionInGroupTags]) ? currentGroup.tags[indexOfSelectionInGroupTags].to : ("to" in currentGroup) ? currentGroup.to : currentGroup.name
      }

      if (remainingTags.length === 0 || !nextGroup) {
        continueCheckingTree = false
      }


      console.groupEnd("Compiling tag tree history, step nr ", count)

      return {
        continueCheckingTree: continueCheckingTree,
        savedTags: remainingTags,
        currentHistory: stateTagTreeHistory,
        nextGroup: nextGroup,
        updatedCount: count
      }

    } else if (currentGroup.allow === "zero-or-more") {
      console.info("Current step group type is ZERO-OR-MORE")
      // console.log("allowed zero-or-more")

      const indexesOfSelectionsInGroupTags = []
      const indexesOfSelectionsInSavedTags = []

      restOfTreeTags.forEach((tag) => {

        // console.log(tag)
        // console.log(currentGroupTags)

        const indexOfGroupTag = currentGroupTags.findIndex((groupTag => (currentGroup.name.toLowerCase() === tag.split(":")[0] && groupTag.toLowerCase() === tag.split(":")[1].toLowerCase())))

        // console.log(indexOfGroupTag)

        if (indexOfGroupTag !== -1) {

          indexesOfSelectionsInGroupTags.push(indexOfGroupTag)

          const indexOfSavedTag = restOfTreeTags.findIndex((savedTag => (currentGroup.name.toLowerCase() === savedTag.split(":")[0] && savedTag.split(":")[1].toLowerCase() === currentGroupTags[indexOfGroupTag].toLowerCase())))
          indexesOfSelectionsInSavedTags.push(indexOfSavedTag)

        }

      })

      let updatedRemainingTags = [...remainingTags]


      const tagsToHistory = []

      indexesOfSelectionsInSavedTags.forEach((indexOfSavedTag) => {

        tagsToHistory.push(restOfTreeTags[indexOfSavedTag].split(":")[1])



      })

      console.info("Adding following tags to tag tree history:", tagsToHistory)

      stateTagTreeHistory.push({
        group: currentGroup,
        selectedTags: tagsToHistory
      })

      updatedRemainingTags = remainingTags.filter(remTag => !tagsToHistory.includes(remTag.split(":")[1]));
      nextGroup = currentGroup.to


      if (updatedRemainingTags.length === 0 || !nextGroup) {
        // console.log("no more tags")
        continueCheckingTree = false
      }

      console.groupEnd("Compiling tag tree history, step nr ", count)

      return {
        continueCheckingTree: continueCheckingTree,
        savedTags: updatedRemainingTags,
        currentHistory: stateTagTreeHistory,
        nextGroup: nextGroup,
        updatedCount: count
      }

    } else {
      console.info("Current step group type is ONE-OR-MORE")
      // one-or-more

      // console.log("allowed one-or-more")

      const indexesOfSelectionsInGroupTags = []
      const indexesOfSelectionsInSavedTags = []

      restOfTreeTags.forEach((tag) => {

        // console.log(tag)
        // console.log(currentGroupTags)

        const indexOfGroupTag = currentGroupTags.findIndex((groupTag => (currentGroup.name.toLowerCase() === tag.split(":")[0] && groupTag === tag.split(":")[1])))

        // console.log(indexOfGroupTag)

        if (indexOfGroupTag !== -1) {

          indexesOfSelectionsInGroupTags.push(indexOfGroupTag)

          const indexOfSavedTag = restOfTreeTags.findIndex((savedTag => (currentGroup.name.toLowerCase() === savedTag.split(":")[0] && savedTag.split(":")[1] === currentGroupTags[indexOfGroupTag].toLowerCase())))

          // console.log(indexOfSavedTag)

          indexesOfSelectionsInSavedTags.push(indexOfSavedTag)

        }

      })

      let updatedRemainingTags = [...remainingTags]
      // console.log(indexesOfSelectionsInSavedTags)
      if (indexesOfSelectionsInSavedTags.length !== 0) {

        const tagsToHistory = []

        indexesOfSelectionsInSavedTags.forEach((indexOfSavedTag) => {

          tagsToHistory.push(restOfTreeTags[indexOfSavedTag].split(":")[1])



        })

        console.info("Current step group type is ONE-OR-MORE")
        console.info("Adding following tags to tag tree history:", tagsToHistory)

        // console.log(tagsToHistory)

        stateTagTreeHistory.push({
          group: currentGroup,
          selectedTags: tagsToHistory
        })

        updatedRemainingTags = remainingTags.filter(remTag => !tagsToHistory.includes(remTag.split(":")[1]));
        // console.log(updatedRemainingTags)


        // console.log(currentGroup)
        nextGroup = currentGroup.to
        // console.log(nextGroup)



        // console.log(selectionFound)

        // console.log(remainingTags)
      }

      if (updatedRemainingTags.length === 0 || !nextGroup) {
        // console.log("no more tags")
        continueCheckingTree = false
      }

      console.groupEnd("Compiling tag tree history, step nr ", count)

      return {
        continueCheckingTree: continueCheckingTree,
        savedTags: updatedRemainingTags,
        currentHistory: stateTagTreeHistory,
        nextGroup: nextGroup,
        updatedCount: count
      }
    }

  }

  handleUpdateTagTree = (yamlStructure, allSelectedTags) => {

    // console.log("All selected tags:")
    // console.log(allSelectedTags)

    let currentlyEditingtagTreeGroup = this.state.currentlySelectingTagGroup
    // console.log("currentlyEditingtagTreeGroup ", currentlyEditingtagTreeGroup)

    // const yamlStructure = this.state.yamlStructure

    if (yamlStructure) {

      currentlyEditingtagTreeGroup = null;

      // console.info("The yaml structure is: ", yamlStructure)
      const defaultStartingGroup = yamlStructure.find((group) => group.start === "here")

      // console.log(allSelectedTags)

      const creatableSelectTags = []
      const tagTreeTags = []


      allSelectedTags.forEach((selectedTag) => {

        let isInAnyTagTreeGroup = false

        this.state.tagYamlStructure.forEach((group => {

          group.tags.forEach((groupTag) => {

            // console.log(selectedTag.value.split(":")[1])

            const selectedTagValueWithoutGroupPrefix = selectedTag.value.includes(":") ? selectedTag.value.split(":")[1] : selectedTag.value

            if (groupTag.name.toLowerCase() === selectedTagValueWithoutGroupPrefix.toLowerCase()) {
              isInAnyTagTreeGroup = true
            }
          })

        }))

        // console.log(selectedTag)
        // console.log(isInAnyTagTreeGroup)

        if (!isInAnyTagTreeGroup) {
          creatableSelectTags.push(selectedTag)
        } else {
          tagTreeTags.push(selectedTag.value.toLowerCase())
        }

      })

      // console.log(creatableSelectTags)
      // console.log(tagTreeTags)

      // const allSavedTags = this.state.selectedTags.map((savedTag) => savedTag.value.toLowerCase())

      let remainingSavedTags = [...tagTreeTags]

      let tagTreeHistoryCompiled = remainingSavedTags.length !== 0 ? false : true

      let currentGroup = defaultStartingGroup
      let tagTreeHistory = []
      let nextGroupIndex;
      let count = 1

      while (!tagTreeHistoryCompiled) {

        console.log(currentGroup.name)

        // try responding to first selection:
        const {
          continueCheckingTree,
          savedTags,
          currentHistory,
          nextGroup,
          updatedCount
        } = this.compileTagTreeHistory(currentGroup, remainingSavedTags, tagTreeHistory, count)

        // console.info("Finished doing check nr : ", updatedCount)
        // console.info("Tags remaining to check: ", savedTags)
        // console.info("Current history constructed:", currentHistory)
        // console.info("Next group to head to ", nextGroup)
        // console.info("Continue checking tree? ", continueCheckingTree)

        nextGroupIndex = this.state.tagYamlStructure.findIndex((group) => group.name === nextGroup)
        console.log(`nextGroupIndex ${nextGroupIndex}`);

        // console.log(nextGroupIndex)
        if (nextGroupIndex !== -1) {
          // console.log(nextGroupIndex)
          currentGroup = { ...this.state.tagYamlStructure[nextGroupIndex] }
          // console.log(currentGroup)
        }


        remainingSavedTags = [...savedTags]
        tagTreeHistory = [...currentHistory]

        count = updatedCount + 1

        if (!continueCheckingTree) tagTreeHistoryCompiled = true
      }

      console.log("%cDone compiling prefilled tag tree!", "color: cyan;")
      // console.log(currentGroup)


      // yamlStructure.forEach((group) => {

      //   // console.log(group)
      //   this.state.selectedTags.forEach((selectedTag) => {
      //     // console.log(selectedTag)


      //     group.tags.forEach((tag) => {

      //       // console.log(tag)

      //       if ((tag.name).toLowerCase() === (selectedTag.value).toLowerCase()) {

      //         // console.log(`I found that selected tag ${selectedTag.value} is in group: ${group.name}`)

      //         const indexOfGroupAlreadyInArray = tagTreeHistory.findIndex((existingGroup) => existingGroup.group.name === group.name)

      //         // console.log(indexOfGroupAlreadyInArray)

      //         if (indexOfGroupAlreadyInArray !== -1) {

      //           const existingGroup = { ...tagTreeHistory[indexOfGroupAlreadyInArray] }
      //           existingGroup.selectedTags.push(selectedTag.value)

      //           tagTreeHistory[indexOfGroupAlreadyInArray] = existingGroup

      //         } else {

      //           tagTreeHistory.push(
      //             {
      //               group: group,
      //               selectedTags: [selectedTag.value]
      //             }
      //           )


      //         }


      //       }

      //     })




      //   })

      // })



      this.setState({ creatableSelectDisplayedTags: creatableSelectTags });

      // console.log(prefilledTagTreeGroups)




      // const customStartingGroup;
      // console.log("Currently editing: ", currentlyEditingtagTreeGroup)
      // console.log("Current group: ", currentGroup)


      const startingGroup = currentlyEditingtagTreeGroup ? currentlyEditingtagTreeGroup : currentGroup
      // console.log("Starting group: ", startingGroup)
      // console.log("CHANGING TAG TREE HISTORY:", tagTreeHistory)

      // console.log("Changing currently selecting tag group into: ", startingGroup)

      this.setState({
        // temporaryTagSelectionFormSelectedTags: [],
        // temporaryTagSelectionHistory: [],
        currentlySelectingTagGroup: startingGroup,
        savedTagSelectionHistory: tagTreeHistory,
        creatableSelectDisplayedTags: creatableSelectTags,
      }, () => this.handleProvidingCurrentTagSelection(startingGroup))

    }
  }

  initTagTree = async (inputContent) => {
    // console.log("handleTextAreaSubmit called")

    if (inputContent === '') {

      const newYamlError = {
        reason: "No yaml content!",
        snippet: null
      }

      this.setState({
        yamlError: newYamlError
      })

    } else {
      try {
        const data = await load(inputContent)
        // console.log(data)

        // console.error("textAreaSubmit")
        // console.log(this.state.currentlySelectingTagGroup)
        // console.log("2")
        // console.log("Tag tree updated in HANDLE TEXT AREA SUBMIT")
        this.setState({
          tagYamlStructure: data.groups,
          yamlError: false
        }, () => this.handleUpdateTagTree(this.state.tagYamlStructure, this.state.selectedTags))

        // console.log(yamlAreaSelector.open)

      } catch (e) {
        // console.log(e);
        // console.log(e.mark.snippet);

        const reason = e.reason;
        const snippet = e.mark?.snippet

        const newYamlError = {
          reason,
          snippet
        }

        this.setState({
          yamlError: newYamlError
        })
      }
    }
  }

  componentDidMount() {
    // console.log(this.props.referenceData)
    // console.log(this.props.images)
    if (this.props.referenceData && this.props.referenceData.length !== 0) {
      this.compileTagFieldOptions(this.props.referenceData);
      this.compileDisabledCreatableSelectOptions();
    }
    this.initTagTree(this.props.tagTreeYaml);

    document.addEventListener("keyup", this.keyboardEventFunction, false);

  }


  componentDidUpdate(prevProps) {

    // console.log(this.props.referenceData)
    // console.log(this.props.images)

    if (prevProps.referenceData !== this.props.referenceData) {
      // console.info("ref data changed!")
      // console.log(this.props.referenceData)
      // console.log(this.props.images)
      // console.log(prevProps.referenceData)
      // console.log(this.props.referenceData)
      // console.log("tagFieldOptionsCompiled on ref data update")
      this.compileTagFieldOptions(this.props.referenceData);
      this.compileDisabledCreatableSelectOptions();

    }
    if (prevProps.images !== this.props.images) {
      // console.log("images changed!")
      // console.log("1:      " + JSON.stringify(prevProps.images))
      // console.log("2:      " + JSON.stringify(this.props.images))

      if (this.props.images.length !== 0) {

        let restartTagTree = false

        if (prevProps.images.length !== this.props.images.length) restartTagTree = true

        const previousImagesIds = prevProps.images.map((prevImageObj) => prevImageObj.id)
        const currentImagesIds = this.props.images.map((currImageObj) => currImageObj.id)

        // console.log(previousImagesIds)
        // console.log(currentImagesIds)

        if (JSON.stringify(previousImagesIds.sort()) !== JSON.stringify(currentImagesIds.sort())) restartTagTree = true

        if (restartTagTree) {

          // console.log("Changing currently selecting tag group into: ", null)


          this.setState({
            // selectedTags: [...this.props.images[0].contentTags], // TODO multi-image
            selectableCreatedTags: [],
            currentlySelectingTagGroup: null,
          });

        }

        if (prevProps.tagTreeYaml !== this.props.tagTreeYaml) {
          this.initTagTree(this.props.tagTreeYaml);
        }

        // this.setState({ selectedTagsFromSelect: [] });
        // this.setState({ selectedTags: [] });
        // console.log("tagFieldOptionsCompiled on image update")
        this.compileTagFieldOptions(this.props.referenceData);
        this.compileDisabledCreatableSelectOptions();
      }

    }

    if (this.imageDetailsContainerRef?.current && this.imageDetailsArrowRef?.current) {
      const detailsContainer = this.imageDetailsContainerRef?.current;
      if (detailsContainer.scrollHeight <= detailsContainer.clientHeight ||
        (detailsContainer.scrollHeight - detailsContainer.scrollTop - detailsContainer.clientHeight < 1)) {
        this.imageDetailsArrowRef.current.style.display = 'none';
      } else {
        this.imageDetailsArrowRef.current.style.display = 'block';
      }
    }

    if (this.imageAttributesEditContainerRef?.current && this.imageAttributesEditArrowRef?.current) {
      const attributesEditContainer = this.imageAttributesEditContainerRef?.current;
      if (attributesEditContainer.scrollHeight <= attributesEditContainer.clientHeight ||
        (attributesEditContainer.scrollHeight - attributesEditContainer.scrollTop - attributesEditContainer.clientHeight < 1)) {
        this.imageAttributesEditArrowRef.current.style.display = 'none';
      } else {
        this.imageAttributesEditArrowRef.current.style.display = 'block';
      }
    }

  }

  componentWillUnmount() {
    this.props.closeDetail();
    document.removeEventListener("keyup", this.keyboardEventFunction, false);
  }

  toggleTag = (tag, group) => {
    // console.log(tag)
    // console.log(group)
    const tagObject = {
      "label": `${group} - ${tag}`,
      "value": tag,
    }

    let tags = this.state.selectedTags;
    let newSelectableCreatedTags = this.state.selectableCreatedTags;

    if (tags !== null && tags.some(tag => tag.value === tagObject.value)) {
      tags = tags.filter(tag => tag.value !== tagObject.value);
      newSelectableCreatedTags = newSelectableCreatedTags.filter(tag => tag.value !== tagObject.value);
    } else {
      tags.push(tagObject);
    }

    this.setState({ selectedTags: tags });
    this.setState({ selectableCreatedTags: newSelectableCreatedTags });
    // this.forceUpdate();
  }

  // old version:
  // saveTags() {

  //   // state.selectedTags are ones which have been checkmarked
  //   let saveTags = [];
  //   this.state.selectedTags.forEach(tag => { saveTags.push(tag.value) });
  //   this.setState({ previouslySavedTags: saveTags });
  //   this.saveAttribute(saveTags);
  // }

  // for yaml structure implementation:
  saveTags(newTagTreeTags, tagGroup, switchImage) {

    // console.log(newTagTreeTags)
    // console.log(tagGroup)
    // console.log(switchImage)

    let saveTags = [];

    // These are all the tags in CreatableSelect:
    this.state.creatableSelectDisplayedTags.forEach(tag => { saveTags.push(tag.value.toLowerCase()) });
    // console.log(saveTags)

    // console.log(saveTags)
    // console.log(newTagTreeTags)

    let updatedTagTreeHistory = [...this.state.savedTagSelectionHistory]

    // console.log(updatedTagTreeHistory)



    // if we are saving by submitting tag tree selection
    // console.log(tagGroup)
    // console.log(tagGroup != null)
    if (tagGroup != null) {
      // console.log("HERE!")

      const indexOfCurrentGroupInHistory = updatedTagTreeHistory.findIndex((historyItem) => historyItem.group.name === tagGroup.name)

      // console.log(updatedTagTreeHistory)
      // console.log(indexOfCurrentGroupInHistory)

      if (indexOfCurrentGroupInHistory !== -1) {
        // this group has previously added tags

        // console.log("%cChanging existing saved group!", "color: red;")


        const thisGroupPreviouslySavedTags = [...updatedTagTreeHistory[indexOfCurrentGroupInHistory].selectedTags]
        // console.log(thisGroupPreviouslySavedTags)
        const updatedHistoryItem = { ...updatedTagTreeHistory[indexOfCurrentGroupInHistory] }
        // console.log(updatedHistoryItem)

        const updatedHistoryItemSelectedTags = [...newTagTreeTags.map((newTag) => newTag.split(":")[1])]
        console.log(updatedHistoryItemSelectedTags)

        updatedHistoryItem.selectedTags = updatedHistoryItemSelectedTags

        updatedTagTreeHistory[indexOfCurrentGroupInHistory] = updatedHistoryItem
        // console.log(updatedTagTreeHistory)

        // cut off the end when exactly-one allowed
        if (tagGroup.allow === "exactly-one") {

          // console.log(thisGroupPreviouslySavedTags)
          // console.log(updatedHistoryItemSelectedTags)
          // console.log(thisGroupPreviouslySavedTags[0] === updatedHistoryItemSelectedTags[0])

          // if submitted value changed (previously saved value was different) 
          if (thisGroupPreviouslySavedTags[0] !== updatedHistoryItemSelectedTags[0]) updatedTagTreeHistory.splice(indexOfCurrentGroupInHistory + 1)

        }

        // Append the history to saveTags array:
        // console.log(updatedTagTreeHistory)
        updatedTagTreeHistory.forEach((savedTagTreeHistoryItem) => {

          // console.log(savedTagTreeHistoryItem)

          savedTagTreeHistoryItem.selectedTags.forEach((savedGroupTag) => {
            // console.log("%csavedTag!", "color: red;")
            // console.log(savedGroupTag)


            saveTags.push(savedTagTreeHistoryItem.group.name.toLowerCase() + ":" + savedGroupTag.toLowerCase())

          })
        })

      } else {
        // new selection group appended:
        // console.log("%cAdding completely new group!", "color: red;")


        // console.log(updatedTagTreeHistory)
        updatedTagTreeHistory.forEach((savedTagTreeHistoryItem) => {

          savedTagTreeHistoryItem.selectedTags.forEach((savedGroupTag) => {
            saveTags.push(savedTagTreeHistoryItem.group.name.toLowerCase() + ":" + savedGroupTag.toLowerCase())

          })
        })
        newTagTreeTags.forEach(tag => { saveTags.push(tag.toLowerCase()) });

        // console.log(saveTags)

      }
    } else {
      // if we are saving hitting the save button (does not consider current unsubmitted tag tree selection )

      // TAG TREE HISTORY TAGS INTO SAVETAGS
      // console.log(updatedTagTreeHistory)
      updatedTagTreeHistory.forEach((savedTagTreeHistoryItem) => {

        savedTagTreeHistoryItem.selectedTags.forEach((savedGroupTag) => {
          saveTags.push(savedTagTreeHistoryItem.group.name.toLowerCase() + ":" + savedGroupTag.toLowerCase())

        })
      })


    }


    // console.log(this.state.savedTagSelectionHistory)


    // console.log(saveTags)

    if (switchImage) {
      // console.log("switching image")
      //this.setState({ previouslySavedTags: saveTags });
      this.props.setPreviouslySavedTags(saveTags);
      this.saveAttributeAndShowNextImageDetail(saveTags);
    } else {
      // console.log("not switching image")
      //this.setState({ previouslySavedTags: saveTags });
      this.props.setPreviouslySavedTags(saveTags);
      this.saveAttributeAndStayOnCurrentModal(saveTags);
    }

  }

  showNextImageDetail() {

    const nImg = this.props.images.length;
    if (nImg === 0) {
      console.log('Invalid function call, nothing is being edited');
      return false;
    } else if (nImg === 1) {

      const currentImage = this.props.images[0];
      const imageIndex = this.props.allImages.findIndex(image => image.id === currentImage.id);


      const totalImages = this.props.allImages.length;

      if (imageIndex === totalImages - 1) {

        console.log('at the end of the list');
        this.closeImageDetail();

      } else {

        let nextImageIndex = imageIndex + 1;

        // if in "starred only" mode, then should find the next image where starred is 'YES'
        if (this.props.showOnlyStarred) {
          nextImageIndex = this.props.allImages.findIndex((image, index) => index > imageIndex && image.starred === 'YES');
          console.log(`nextImageIndex ${nextImageIndex}`);
          if (!nextImageIndex || nextImageIndex < 0) {
            this.closeImageDetail();
            return false;
          }
        }
        const nextImage = this.props.allImages[nextImageIndex];
        this.props.setCurrentlyEditingImages([nextImage])

      }

    } else {
      this.closeImageDetail();
      // console.log('Multiple images were open, so showNextImageDetail will sit this one out.');
    }
  }

  showPreviousImageDetail() {
    const nImg = this.props.images.length;
    if (nImg === 0) {
      console.log('Invalid function call, nothing is being edited');
      return false;
    } else if (nImg === 1) {

      const currentImage = this.props.images[0];
      const imageIndex = this.props.allImages.findIndex(image => image.id === currentImage.id);

      if (imageIndex === 0) {
        console.log('at the beginning of the list');
        this.closeImageDetail();
      } else {
        let previousImageIndex = imageIndex - 1;
        if (this.props.showOnlyStarred) {
          previousImageIndex = this.props.allImages.findLastIndex((image, index) => index < imageIndex && image.starred === 'YES');
          if (!previousImageIndex || previousImageIndex < 0) {
            this.closeImageDetail();
            return false;
          }
        }
        const previousImage = this.props.allImages[previousImageIndex];
        this.props.setCurrentlyEditingImages([previousImage]);
      }
    } else {
      this.closeImageDetail();
      // console.log('Multiple images were open, so showPreviousImageDetail will sit this one out.');
    }
  }

  keyboardEventFunction(event) {
    // console.log(event.keyCode);

    if (event.keyCode === 37) {
      // Left arrow 
      this.showPreviousImageDetail();

    } else if (event.keyCode === 39) {
      // Right arrow
      this.showNextImageDetail();

    } else if (event.keyCode === 81) {
      // Letter Q, as in Next Question
      this.tagTreeRef.current.saveTags(false)

    } else if (event.keyCode === 83) {
      // Letter S, as in Save
      this.tagTreeRef.current.saveTags(true)
    }
  }

  closeImageDetail() {

    this.props.closeDetail();
    this.props.setCurrentlyEditingImages([]);
    // const encodedPrefix = encodeURIComponent(this.props.prefix);
    // this.props.history.push(`/images/${encodedPrefix}`)
  }

  saveAttributeAndShowNextImageDetail(values) {

    let imageIds = [];
    this.props.images.forEach((image) => { imageIds.push(image['id']) });
    const attrName = this.props.attribute;

    // console.log(`Please save ${values} for attribute ${attrName} for images :`);
    // console.log(imageIds);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'ids': imageIds,
        'name': attrName,
        'values': values,
      })
    };

    fetch(process.env.REACT_APP_BE_API_HOST + '/api/update', requestOptions)
      .then(res => res.json())
      .then((data) => {

        if (data['success']) {
          this.props.reloadImages(imageIds);
          this.props.loadReferenceData();
        } else {
          console.log('ERROR in /api/update response');
          console.log(requestOptions.body)
          console.log(data);
        }

      })
      .then(() => {
        this.showNextImageDetail();
      })
      .catch(console.log);
  }

  saveAttributeAndStayOnCurrentModal(values) {

    let imageIds = [];
    this.props.images.forEach((image) => { imageIds.push(image['id']) });
    const attrName = this.props.attribute;

    // console.log(`Please save ${values} for attribute ${attrName} for images :`);
    // console.log(imageIds);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'ids': imageIds,
        'name': attrName,
        'values': values,
      })
    };

    fetch(process.env.REACT_APP_BE_API_HOST + '/api/update', requestOptions)
      .then(res => res.json())
      .then((data) => {

        // console.log(imageIds)

        if (data['success']) {
          this.props.reloadImages(imageIds);
          this.props.loadReferenceData();
        } else {
          console.log('ERROR in /api/update response');
          console.log(requestOptions.body)
          console.log(data);
        }

      })
      .then(() => {
        // this.showNextImageDetail();
        // this.handleInitiateTagTree(this.state.tagYamlStructure)
      })
      .catch(console.log);
  }

  async saveLocation(event) {

    // console.log(event)
    await this.createLocationIfNecessary(event);

    let imageIds = [];
    this.props.images.forEach((image) => {
      imageIds.push(image.id);
    });

    const locationId = (Object.keys(this.state.newCreatedLocation).length === 0 && this.state.newCreatedLocation.constructor === Object) ? event.value : this.state.newCreatedLocation.id

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'locationId': locationId,
        'imageIds': imageIds,
      })
    };

    fetch(process.env.REACT_APP_BE_API_HOST + '/api/location/add-images', requestOptions)
      .then(res => res.json())
      .then((data) => {

        if (data['success']) {
          this.props.reloadImages(imageIds);
        } else {
          console.log('ERROR in /api/location/add-images response');
          console.log(data);
          console.log(requestOptions.body)
        }
      })
      .catch(console.log);

    this.setState({ newCreatedLocation: {} });
    this.showNextImageDetail();
  }

  createLocationIfNecessary(event) {
    if (!this.props.locations.some((loc) => (loc.name === event.label))) {
      console.log('Creating new location: ' + event.label);
      // console.log(this.props.locations)
      // console.log(event);

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          'location': {
            'name': event.label,
            'coords': [],
            'attributes': {
              'CONTACT_NAME': '',
              'CONTACT_EMAIL': '',
              'CONTACT_PHONE': '',
              'CATEGORY': 'FIXED',
              'SITUATION': 'NA',
              'NOTES': '',
            }
          },
          'markAsFresh': false
        })
      };

      // console.log(requestOptions.body);

      return fetch(process.env.REACT_APP_BE_API_HOST + '/api/location/save', requestOptions)
        .then(res => res.json())
        .then((data) => {
          // console.log(data);
          this.setState({ newCreatedLocation: data });

          // there is currently sadly no way query a specific location 
          // to swap it out in state, so let's just reload the lot
          this.props.loadLocations();
          this.props.loadReferenceData();
        })
        .catch(console.log);
    }
  }

  handleAttributeSelectionClick(currentlyEditingAttribute) {
    this.props.setCurrentlyEditingAttribute(currentlyEditingAttribute);
  }

  handleImageLoad = (event) => {
    if (event.target.clientHeight && event.target.clientWidth) {
      const height = event.target.clientHeight;
      const width = event.target.clientWidth;

      this.setState({ imageHeight: height, imageWidth: width });
    }
  }

  isValidOptionToCreate = (tag) => {

    let disabledTags = [...this.state.disabledCreatableOptions]
    let validOption = true;

    if (tag == null || tag === "") {
      validOption = false;
    } else if (disabledTags.some((dtag) => dtag.toLowerCase() === tag.toLowerCase())) {
      validOption = false;
    } else {
      validOption = true;
    }
    return validOption
  }

  isValidCreatableSelectOptionDisabled = (tag) => {

    // console.log(tag)

    let disabledTags = [...this.state.disabledCreatableOptions]

    // (option) => option.rating !== 'safe'


    let disabledOption = false;

    if (disabledTags.some((dtag) => dtag.toLowerCase() === tag.value.toLowerCase())) {
      disabledOption = true;
    }
    return disabledOption
  }

  isWarningShown = (inputValue) => {
    let warning = false;
    this.state.disabledCreatableOptions.forEach((disabledTag) => {
      if (disabledTag === inputValue) {
        warning = true;
      }
    })
    this.setState({ warning: warning });
  };

  onScrollHandler(source, event) {
    const el = event.currentTarget;
    console.log('scroll handler');
    if (el) {
      if (el.scrollHeight - el.scrollTop - el.clientHeight < 1) {
        if (source === 'imageDetails') {
          if (this.imageDetailsArrowRef?.current) {
            this.imageDetailsArrowRef.current.style.display = 'none';
          }
        } else if (source === 'imageAttributes') {
          if (this.imageAttributesEditArrowRef?.current) {
            this.imageAttributesEditArrowRef.current.style.display = 'none';
          }
        }
      } else {
        if (source === 'imageDetails') {
          if (this.imageDetailsArrowRef?.current) {
            this.imageDetailsArrowRef.current.style.display = 'block';
          }
        } else if (source === 'imageAttributes') {
          if (this.imageAttributesEditArrowRef?.current) {
            this.imageAttributesEditArrowRef.current.style.display = 'block';
          }
        }
      }
    }
  }


  render() {

    let currentAttachedLocationId = false;
    if (this.props.images && this.props.images.length !== 0) {
      currentAttachedLocationId = this.props.images[0].locationId;
    }
    const attachedLocation = currentAttachedLocationId ? this.props.getLocationForId(currentAttachedLocationId) : {};
    const hasDefinedLocation = (attachedLocation) ? true : false;

    const modalClass = (this.props.isActive) ? 'is-active' : '';

    let displayTitle = 'No image provided!';
    if (typeof this.props.images.length === 'undefined' || this.props.images.length === 0) {
      // theoretically could do something more intelligent, but nah
      return false;
    }

    if (this.props.images.length === 1) {
      displayTitle = this.props.images[0].path.replace(this.props.prefix, '');

    } else if (this.props.images.length > 1) {
      displayTitle = this.props.images.length + ' images';
    }


    // console.log(this.props.referenceData);
    let simpleAttributes = (this.props.referenceData.simpleAttributes) ? this.props.referenceData.simpleAttributes : [];
    // console.log('simpleAttributes: ', simpleAttributes);
    let currentAttribute = {};
    let currentAttributeIsSimple = false;


    if (this.props.attribute === 'CONTENT_TAG') {
      currentAttribute = {
        'name': 'CONTENT_TAG',
        'displayName': 'Content tags',
      };
    } else if (this.props.attribute === 'location') {
      currentAttribute = {
        'name': 'LOCATION',
        'displayName': 'Location',
      };
    } else {
      simpleAttributes.forEach(attribute => {
        if (attribute.name === this.props.attribute) {
          currentAttribute = attribute;
          currentAttributeIsSimple = true;
        }
      });
    }

    const sliceVariable = this.props.images.length === 9 ? 9 : 8;
    const imageColumnSize = (this.props.images.length === 1) ? '' : 'is-one-third'


    // console.log(creatableSelectTags)

    const handleJumpToTagTreeStep = (groupName) => {

      this.setCurrentlySelectingTagGroup(groupName)

    }

    // console.log(this.state.savedTagSelectionHistory)



    return (
      <>
        <div className={`modal ${modalClass}`}>

          <div className="modal-background" onClick={() => this.closeImageDetail()}></div>
          <div className="modal-card">

            <header className="modal-card-head">
              <p className="modal-card-title">
                <span>
                  {displayTitle}
                </span>
                {this.props.images.length > 1 &&
                  <span className="modal-card-title__add-all-to-lightbox" onClick={() => this.props.manyImagesToLightbox(this.props.images)}>
                    <span className="modal-card-title__add-all-to-lightbox-icon">
                      <DownloadImagesIcon fill="#2F80ED" size="14" />
                    </span>
                    <span className="modal-card-title__add-all-to-lightbox-text">
                      add all to lightbox
                    </span>
                  </span>
                }
              </p>
              <button className="delete" aria-label="close" onClick={() => this.closeImageDetail()}></button>
            </header>

            <section className="modal-card-body no-scroll">
              <div className="columns imagedetail">

                <div className="column is-half">
                  <div className='scroll-container scrollbox h100p'>
                    <div className='vertical-scroll h100p' ref={this.imageDetailsContainerRef} onScroll={(event) => this.onScrollHandler('imageDetails', event)}>
                      <div className="columns is-multiline w100p">
                        <div>
                          {this.props.images.slice(0, sliceVariable).map((image) => {
                            return (
                              <div className={`column ${imageColumnSize}`} key={`image_column_${image.id}`}>
                                <div className="imagedetail__selectedimages-image">
                                  <img
                                    className={`imagedetail__image imagedetail__image--can-be-enlarged`}
                                    key={`image_${image.id}`}
                                    src={image.sources.m}
                                    alt={displayTitle}
                                    onClick={() => this.props.showLargeImages(image)}
                                    onLoad={this.handleImageLoad}
                                  />
                                  {this.props.lightboxImages.some(lightboxImage => lightboxImage.id === image.id) &&
                                    <span className="imagedetail__selectedimages-already-in-lightbox">
                                      <CheckedIcon fill="green" size="12" />
                                    </span>
                                  }
                                  {!this.props.lightboxImages.some(lightboxImage => lightboxImage.id === image.id) ?
                                    <span className="imagedetail__selectedimages-add-image-to-lightbox"
                                      onClick={() => this.props.imageToLightbox(image)}>
                                      <DownloadImagesIcon fill="white" size="12" />
                                    </span>
                                    :
                                    <span className="imagedetail__selectedimages-remove-image-from-lightbox"
                                      onClick={() => this.props.removeImageFromLightbox(image)}>
                                      <RemoveFromLightboxIcon fill="red" size="12" />
                                    </span>
                                  }
                                </div>
                              </div>
                            );
                          })}
                          {this.props.images.length > 9 &&
                            <div className={`column ${imageColumnSize}`} key={`more_images_thumbnail`}>
                              <div style={{ height: this.state.imageHeight, width: this.state.imageWidth }} className="imagedetail__thumbnail-container">
                                <div>...and {this.props.images.length - 8} more</div>
                              </div>
                            </div>
                          }
                        </div>
                        <div>
                          <div
                            className={`column imagedetail__attribute tags is-narrow is-clickable ${(currentAttribute.name === 'CONTENT_TAG') ? 'is-selected' : ''}`}
                            onClick={() => this.handleAttributeSelectionClick('CONTENT_TAG')}
                          >
                            <h3 className="title imagedetail__attribute-title is-6">Content tags</h3>
                            {this.props.images[0].contentTags.map((tag) => (
                              <Attribute key={`detailtag_${tag.replace(' ', '_')}`} type="tag" value={tag} />
                            ))}
                          </div>

                          {simpleAttributes.map((attr) => {
                            return (
                              <div
                                key={`attr_${attr.name}`}
                                className={`column imagedetail__attribute tags is-narrow is-clickable ${(currentAttribute.name === attr.name) ? 'is-selected' : ''}`}
                                onClick={() => this.handleAttributeSelectionClick(attr.name)}
                              >
                                <h3 className="title imagedetail__attribute-title is-6">{attr.displayName}</h3>
                                <Attribute type={attr.name} value={this.props.images[0][attr.apiName]} />
                              </div>
                            );
                          })}

                          <div
                            className={`column imagedetail__attribute is-narrow is-clickable ${(currentAttribute.name === 'LOCATION') ? 'is-selected' : ''}`}
                            onClick={() => this.handleAttributeSelectionClick('location')}
                          >
                            <h3 className="title imagedetail__attribute-title is-6">Location</h3>
                            {hasDefinedLocation && (
                              <Attribute type="location" value={attachedLocation.name} />
                            )}
                            {!hasDefinedLocation && (
                              <Attribute type="location" value="" />
                            )}

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='arrow-svg' ref={this.imageDetailsArrowRef}></div>
                  </div>
                </div>

                <div className="column is-half">
                  <div className='scroll-container scrollbox h100p'>
                    <div className='vertical-scroll h100p' ref={this.imageAttributesEditContainerRef} onScroll={(event) => this.onScrollHandler('imageAttributes', event)}>
                      <div className="columns is-multiline w100p">
                        {this.state.warning &&
                          <div className='column is-full'>
                            <article className="message is-warning">
                              <div className="message-header">
                                <p>"This tag cannot be added as an additional tag"</p>
                              </div>
                            </article>
                          </div>
                        }
                        <div className='column is-full'>
                          <h1 className="title is-full">{currentAttribute.displayName}</h1>
                        </div>
                        <div className='column is-full'>
                          {currentAttributeIsSimple && (
                            <AttributeSelector
                              attribute={currentAttribute}
                              handleSaveClick={(values) => this.saveAttributeAndShowNextImageDetail(values)}
                              handleSkipClick={() => this.showNextImageDetail()}
                              handleSkipBackClick={() => this.showPreviousImageDetail()}
                            />
                          )}
                          {!currentAttributeIsSimple && currentAttribute.name === 'LOCATION' && (
                            <LocationSelector
                              locations={this.props.locations}
                              hasDefinedLocation={hasDefinedLocation}
                              attachedLocation={attachedLocation}
                              handleLocationSaveClick={(val) => this.saveLocation(val)}
                              handleSkipClick={() => this.showNextImageDetail()}
                              handleSkipBackClick={() => this.showPreviousImageDetail()}
                            />
                          )}
                          {!currentAttributeIsSimple && currentAttribute.name === 'CONTENT_TAG' && (

                            <div className='columns is-multiline'>
                              <div className='column is-full'>
                                {this.state.yamlError ?
                                  <article className="message is-warning yaml-error-container">
                                    <div className="message-header">
                                      <p>Yaml structure error! Look for errors:</p>
                                    </div>
                                    <p>Error reason: {this.state.yamlError.reason}</p>
                                    {this.state.yamlError.snippet &&
                                      <p className='yaml-error-message-snippet'>Error here: {this.state.yamlError.snippet}</p>
                                    }
                                  </article>
                                  :
                                  <TagSelectionForm
                                    currentlySelectingTagGroup={this.state.currentlySelectingTagGroup}
                                    currentlySelectingTags={this.state.currentlySelectingTags}
                                    setCurrentlySelectingTagGroup={(groupName) => this.setCurrentlySelectingTagGroup(groupName)}
                                    handleStartSavingTags={(tagTreetags, tagGroup, switchImage = false) => this.handleStartSavingTags(tagTreetags, tagGroup, switchImage)}
                                    showNextImageDetail={() => this.showNextImageDetail()}
                                    handleJumpToTagTreeStep={handleJumpToTagTreeStep}
                                    // temporarySelectionHistory={this.state.temporaryTagSelectionHistory}
                                    // setTemporarySelectionHistory={(temporaryTagSelectionHistory) => this.setTemporarySelectionHistory(temporaryTagSelectionHistory)}
                                    savedTagSelectionHistory={this.state.savedTagSelectionHistory}
                                    innerRef={this.tagTreeRef}
                                  />
                                }
                              </div>
                              <div className="column is-full">
                                {this.props.previousImageTags && !!this.props.previousImageTags[this.props.attribute] &&
                                  <button
                                    className='button is-light'
                                    onClick={() => {
                                      // console.log(this.props.previousImageTags);
                                      this.updateSelectedTags(this.props.previousImageTags[this.props.attribute]);
                                      // this.updateSelectedTags(this.props.previousImageTags)
                                    }}>Use values from previous image</button>
                                }
                              </div>
                              <div className="column is-full">
                                {this.props.previouslySavedTags.length > 0 &&
                                  <button
                                    className='button is-light'
                                    onClick={() => this.updateSelectedTags(this.props.previouslySavedTags)}>Use last saved values</button>
                                }
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='arrow-svg' ref={this.imageAttributesEditArrowRef}></div>
                  </div>
                </div>
              </div>
              <div className="columns level">
                <div className='column is-half'>
                  <div className="level-left">
                    <button
                      className="button is-light level-item"
                      onClick={() => this.showPreviousImageDetail()}
                    >← Skip</button>
                    <button
                      className="button is-light level-item"
                      onClick={() => this.showNextImageDetail()}
                    >Skip →</button>
                  </div>
                </div>
                {!currentAttributeIsSimple && currentAttribute.name === 'CONTENT_TAG' && (
                  <div className='column is-half'>
                    <div className="level-left">
                      <button
                        className="button level-item is-submit is-primary"
                        onClick={() => this.tagTreeRef.current.saveTags(false)}
                      >Save, next question [Q]</button>
                      <button
                        className="button level-item is-light"
                        onClick={() => this.tagTreeRef.current.saveTags(true)}
                      >Save, next image [S]</button>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
        {this.props.imageViewerIsActive &&
          <ImageViewer
            sharedMode={false}
            images={this.props.images}
            imageViewerIsActive={this.props.imageViewerIsActive}
            handleViewerCloseClick={() => this.props.handleViewerCloseClick()}
            lightboxImages={this.props.lightboxImages}
            imageToLightbox={(image) => this.props.imageToLightbox(image)}
            showLargeImages={(image) => this.props.showLargeImages(image)}
            imageViewerShowsImagePath={this.props.imageViewerShowsImagePath}
            imageViewerShowsImage={this.props.imageViewerShowsImage}
            removeImageFromLightbox={(image) => this.props.removeImageFromLightbox(image)}
            getLocationForId={(id) => this.props.getLocationForId(id)}
            lightboxCreator={false}
            lightboxName={false}
            user={false}
          />
        }
      </>
    );
  }

}

export default ImageDetails;