import React, { useRef, useImperativeHandle, forwardRef } from "react"
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

export const TagSelectFormRadioButtons = forwardRef(({
    options,
    // selectedTagOption,
    tagGroup,
    setCurrentlySelectingTagGroup,
    handleStartSavingTags,
    // temporaryTagSelectionFormSelectedTags,
    // setTemporaryTagFormSelectedTags,
    // temporarySelectionHistory,
    // setTemporarySelectionHistory,
    savedTagSelectionHistory,
    showNextImageDetail
}, ref) => {

    
    const formRef = useRef();
    const switchImageAfterSave = useRef();

    const submitForm = () => {
        if (formRef.current) {
          formRef.current.handleSubmit();
        }
      }

      useImperativeHandle(ref, () => ({
        saveTags (switchImage) {
            switchImageAfterSave.current = switchImage;
            submitForm();
        }
      }), []);

    // console.log(options)
    // console.log(savedTagSelectionHistory)
    // console.log(tagGroup)

    const prefilledTags = (savedTagSelectionHistory.find((group) => group.group.name === tagGroup.name))?.selectedTags

    // console.log(prefilledTags)
    // console.log(prefilledTags?.length !== 0)

    const prefilledTagIndex = prefilledTags && prefilledTags.length !== 0 && options.findIndex((option) => (option.name).toLowerCase() === (prefilledTags[0]).toLowerCase())
    // console.log(prefilledTagIndex)
    // console.log(options[prefilledTagIndex].name)
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                // picked: selectedTagOption,
                picked: (prefilledTagIndex != null && prefilledTagIndex !== -1) ? options[prefilledTagIndex].name.toLowerCase() : '',
            }}
            validationSchema={Yup.object().shape({
                picked: Yup.string()
                    .required('At least 1 selection required'),
            })}
            innerRef={formRef}
            onSubmit={(values, actions) => {
                // console.log(values)
                // console.log(actions)
                // console.log(values.picked)

                if (values.picked !== '') {
                    let nextGroupOption;
                    let nextGroupName = "";

                    let stateSelectedTags = [];

                    // console.log(options)
                    // console.log(values.picked)
                    nextGroupOption = options.find((tag) => tag.name.toLowerCase() === values.picked.toLowerCase())
                    // console.log(nextGroupOption)

                    nextGroupName = ("to" in nextGroupOption) ? nextGroupOption.to : null
                    // console.log(nextGroupName)

                    stateSelectedTags.push(tagGroup.name + ":" + values.picked)

                    // stateSelectedTags
                    // setTemporaryTagFormSelectedTags(stateSelectedTags)

                    // console.log(temporarySelectionHistory)
                    // //update history
                    // const stateSelectionHistory = [...temporarySelectionHistory]
                    // const newSelection = {
                    //     group: tagGroup,
                    //     selectedTags: [values.picked]
                    // }
                    // stateSelectionHistory.push(newSelection)
                    // setTemporarySelectionHistory(stateSelectionHistory)

                    // console.log(nextGroupName)
                    if (nextGroupName) {
                        setCurrentlySelectingTagGroup(nextGroupName)
                    } else {
                        console.log("last selectable item!!");
                        setCurrentlySelectingTagGroup("")
                    }

                    console.log("Form is starting to save these selected tags: ", stateSelectedTags)
                    handleStartSavingTags(stateSelectedTags, tagGroup, switchImageAfterSave.current ? true : false);
                    switchImageAfterSave.current = null;

                    actions.resetForm({
                        values: {
                            // the type of `values` inferred to be Blog
                            picked: ''
                        }
                    })
                    if(!nextGroupName) {
                        showNextImageDetail();
                    }
                }
                switchImageAfterSave.current = null;
            }}
        >
            {({ errors, touched }) => (
                <Form>
                    <div className="tag-selection-container__options-container">
                        <div role="group" aria-labelledby="my-radio-group">
                            {options.map((option, i) => {
                                return (
                                    <span key={tagGroup.name + option.name + i + "radio"} className="tag-selection-container__option">
                                        <label style={{ cursor: "pointer" }}>
                                            <Field
                                                id="locationType"
                                                name="picked"
                                                type="radio"
                                                value={option.name.toLowerCase()}
                                                style={{ cursor: "pointer" }}
                                            />
                                            {option.name}
                                        </label>
                                    </span>
                                )
                            })}
                            {touched.picked && errors.picked && <div className="tag-selection-container__error-message">{errors.picked}</div>}
                        </div>
                        {/* <div className="submit-button-container">
                            <button type="submit">Submit</button>
                        </div> */}
                    </div>
                </Form>
            )}
        </Formik>
    )
})