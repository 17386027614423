import React, { Component } from "react";
import { DetailHeader } from './modalDetails.js';
import CreatableSelect from 'react-select/creatable';
import { MapComponent } from './mapComponent.js';
import { SearchComponent } from './searchComponent.js';
import getOS from '../contexts/getOs'

class LocationDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warnings: {},
      name: "",
      category: "",
      situation: "",
      contact_name: "",
      contact_phone: "",
      contact_email: "",
      notes: "",
      types: [],
      typeOptions: [],
      selectedTypes: [],
      selectableCreatedTypes: [],
      mapCenter: {}, // default, center of Estonia
      mapZoom: "", // default shows most of Estonia
      mapInitiated: false,
      coordinates: [],
      temporaryMarkerCoordinates: [],
      newMarkerShift: 0,
    };
  }

  clearMarkers = () => {
    console.log("Clear markers!")
    this.setState({ coordinates: [] })
  }

  addMarker = () => {

    if (this.state.coordinates.length === 0) {

      let newMarkerCoordinates = { ...this.state.mapCenter };

      let stateMarkerCoordinates = [...this.state.coordinates]
      let temporaryMarkerCoordinates = [...this.state.temporaryMarkerCoordinates]

      stateMarkerCoordinates.push(newMarkerCoordinates)
      temporaryMarkerCoordinates.push(newMarkerCoordinates)

      this.setState({ 
        coordinates: stateMarkerCoordinates,
        temporaryMarkerCoordinates: temporaryMarkerCoordinates
      })

    } else {
      
      let coordinateArrayLength = this.state.coordinates.length
      let newMarkerCoordinates = { ...this.state.coordinates[coordinateArrayLength - 1]};

      delete newMarkerCoordinates.id // otherwise cannot be saved in db
      if (this.state.newMarkerShift === 0) {
        newMarkerCoordinates.lng = newMarkerCoordinates.lng + 0.01
      } else {
        newMarkerCoordinates.lng = newMarkerCoordinates.lng + this.state.newMarkerShift;
      }

      let stateMarkerCoordinates = [...this.state.coordinates]
      let temporaryMarkerCoordinates = [...this.state.temporaryMarkerCoordinates]

      temporaryMarkerCoordinates.push(newMarkerCoordinates)
      stateMarkerCoordinates.push(newMarkerCoordinates)

      this.setState({ 
        coordinates: stateMarkerCoordinates, 
        mapCenter: newMarkerCoordinates,
        temporaryMarkerCoordinates: temporaryMarkerCoordinates
      })
    }
  }

  handleChange = (event) => {
    // console.log(event.target)
    // console.log(this.state.coordinates)
    this.setState({ [event.target.name]: event.target.value });
  };

  handleMapCenterChange = (center) => {
    if (Object.keys(center).length !== 0 &&
    center.constructor === Object) {

      this.setState({ mapCenter: center })

      if (this.state.coordinates.length === 1) {
        if (this.state.temporaryMarkerCoordinates.length === 1) {
          let stateTemporaryMarkerCoordinates = []
          stateTemporaryMarkerCoordinates.push(center)
          this.setState({temporaryMarkerCoordinates: stateTemporaryMarkerCoordinates})
        }
        let stateCoordinates = []    
        stateCoordinates.push(center)
        this.setState({coordinates: stateCoordinates})
      }
    }
  }

  handleMapCenterZoomChange = (center) => {
    if (Object.keys(center).length !== 0 &&
    center.constructor === Object) {
      this.setState({ mapCenter: center })
    }
  }

  handleTypeSelectChange = values => {
    // console.log(values)
 
    if (values != null) {
      // console.log(values)
      const previouslySelectedTypes = [...this.state.selectedTypes];
      const allExistingTypeOptions = [...this.state.typeOptions];
      
      const previousSelectableCreatedTypes = this.state.selectableCreatedTypes;
      const updatedSelectableCreatedTypes = values.filter(value => previousSelectableCreatedTypes.includes(value));
      // console.log(updatedSelectableCreatedTags)

      let allExistingAndFreshlyCreatedTypes = previouslySelectedTypes.concat(allExistingTypeOptions);

      const allTypesFromSelectComponent = [];
      for (const value of values) {
      
        const indexOfNewTypeOption = allExistingAndFreshlyCreatedTypes.findIndex((type) => type.value === value.value )
        if (indexOfNewTypeOption === -1) {

          updatedSelectableCreatedTypes.push(value)
          allTypesFromSelectComponent.push(value)
        } else {
          allTypesFromSelectComponent.push(value)
        }
      }

      this.setState({ selectedTypes: allTypesFromSelectComponent });
      this.setState({ selectableCreatedTypes: updatedSelectableCreatedTypes });
    } else {
      // console.log(values)
      this.setState({ selectedTypes: [] });
      this.setState({ selectableCreatedTypes: [] });
    }
  }

  handleAddressSearch = (coordinates) => {
  // console.log("🚀 ~ file: locationDetail.js ~ line 116 ~ LocationDetail ~ coordinates", coordinates)

    let searchResultCoord = {
      lat: coordinates[0],
      lng: coordinates[1]
    }

    let stateCoordinates = [...this.state.coordinates]
    let temporaryMarkerCoordinates = [...this.state.temporaryMarkerCoordinates]

    temporaryMarkerCoordinates.push(searchResultCoord)
    stateCoordinates.push(searchResultCoord)

    this.setState({ 
      mapCenter: searchResultCoord, 
      coordinates: stateCoordinates, 
      temporaryMarkerCoordinates: temporaryMarkerCoordinates, 
      mapZoom: 14 })
  }

  handleChangeMarkerLocation = (prevCoordinates, newCoordinates) => {
    
    const updatedCoordinates = [...this.state.coordinates]
    let updatedtemporaryMarkerCoordinates = [...this.state.temporaryMarkerCoordinates]

    const indexOfLocation = updatedCoordinates.findIndex((stateCoord) => stateCoord.lat === prevCoordinates.lat && stateCoord.lng === prevCoordinates.lng)

    if (indexOfLocation !== -1) {
      updatedCoordinates[indexOfLocation].lat = newCoordinates.lat
      updatedCoordinates[indexOfLocation].lng = newCoordinates.lng

      this.setState({coordinates: updatedCoordinates})
    }

    const indexIntemporaryMarkerCoordinates = updatedtemporaryMarkerCoordinates.findIndex((stateCoord) => stateCoord.lat === prevCoordinates.lat && stateCoord.lng === prevCoordinates.lng)

    if (indexIntemporaryMarkerCoordinates !== -1) {
      updatedtemporaryMarkerCoordinates[indexIntemporaryMarkerCoordinates].lat = newCoordinates.lat
      updatedtemporaryMarkerCoordinates[indexIntemporaryMarkerCoordinates].lng = newCoordinates.lng

      this.setState({
        coordinates: updatedCoordinates,
        temporaryMarkerCoordinates: updatedtemporaryMarkerCoordinates, 
      })
    }
  }

  handleDeleteMarkerClick = (coordinates) => {
    const stateCoordinates = [...this.state.coordinates]
    const temporaryMarkerCoordinates = [...this.state.temporaryMarkerCoordinates]

    const updatedCoordinates = stateCoordinates.filter((stateCoord) => stateCoord.lat !== coordinates.lat || stateCoord.lng !== coordinates.lng)
    const updatedSearchResultCoordinates = temporaryMarkerCoordinates.filter((stateCoord) => stateCoord.lat !== coordinates.lat || stateCoord.lng !== coordinates.lng)

    this.setState({coordinates: updatedCoordinates, temporaryMarkerCoordinates: updatedSearchResultCoordinates})
  }

  saveNewMarkerShift = (shift) => {
    this.setState({ newMarkerShift: shift })
  }

  handleSubmit = (event) => {
    console.log("detail view is handling submit!");
    // some kind of error proofing should happen here

    let warnings = this.state.warnings;

    if (!this.state.name || this.state.name.length === 0) {
      warnings["name_empty"] = "Location name may not be empty!";
    } else {
      delete warnings["name_empty"];
    }

    let nameExists = false;
    if (this.props.isAddingNewLocation || this.props.location.name !== this.state.name ) {
      const existingLocations = [...this.props.existingLocations];
      existingLocations.forEach((loc) => {
        if (loc["name"] === this.state.name) {
          nameExists = true;
        }
      });
    }
    if (nameExists) {
      warnings["name_exists"] = "A location with this name already exists!";
    } else {
      delete warnings["name_exists"];
    }

    this.setState({ warnings: warnings });

    if (Object.keys(warnings).length === 0) {
      if (!this.state.category) this.setState({ category: "FIXED" });

      let saveTypes = [];
      this.state.selectedTypes.forEach((type) => {
        saveTypes.push(type.value);
      })
      
      const locationObject = {
        id: this.props.location.id,
        name: this.state.name,
        attributes: {
          CONTACT_NAME: this.state.contact_name,
          CONTACT_EMAIL: this.state.contact_email,
          CONTACT_PHONE: this.state.contact_phone,
          CATEGORY: this.state.category ? this.state.category : "FIXED",
          SITUATION: this.state.situation ? this.state.situation : "NA",
          NOTES: this.state.notes,
        },
        types: saveTypes,
        coords: this.state.coordinates,
      };
      // console.log(locationObject);
      this.props.handleSaveClick(locationObject);
    }

    event.preventDefault();
  };

  componentDidMount() {
    console.log("detail form mounted");
    // console.log(this.props.isAddingNewLocation)
    if (this.props.referenceData?.types) {
      let allTypeOptions = [];
      
      this.props.referenceData.types.forEach((type) => {
        allTypeOptions.push({ 
          value: type,
          label: type
        });
      });

      this.setState({ typeOptions: allTypeOptions }, () => this.displaySelectedTypes());
    }
    if (Object.keys(this.props.location).length !== 0 &&
    this.props.location.constructor === Object) {

      let coordinates = this.props.isAddingNewLocation || !this.props.location.coords || this.props.location.coords?.length === 0 ? [] : this.props.location.coords
      let mapZoom = coordinates.length !== 0 ? 14 : this.state.mapZoom
      let mapCenter = coordinates.length !== 0 ? coordinates[0] : { lat: 58.88785799999999, lng: 25.5411706 }
      
      // new location has been set, also set it in state
      this.setState({
        warnings: {},
        name: this.props.location.name,
        category: this.props.location.category,
        situation: this.props.location.situation,
        contact_name: this.props.location.attributes.CONTACT_NAME,
        contact_phone: this.props.location.attributes.CONTACT_PHONE,
        contact_email: this.props.location.attributes.CONTACT_EMAIL,
        notes: this.props.location.attributes.NOTES,
        types: this.props.location.types,
        selectedTypes: this.compileSelectedTypes(this.props.location.types),
        selectableCreatedTypes: [],  
        coordinates: coordinates,
        mapZoom: mapZoom,
        mapCenter: mapCenter,
        mapInitiated:true,
        temporaryMarkerCoordinates: []
      });
    } else {
      // location has been unset, also reset state
      this.setState({
        warnings: {},
        name: "",
        category: "",
        situation: "",
        contact_name: "",
        contact_phone: "",
        contact_email: "",
        notes: "",
        types: [],
        selectedTypes: [],
        selectableCreatedTypes: [],
        coordinates: [],
        mapZoom: "",
        mapCenter: {lat: 58.88785799999999, lng: 25.5411706},
        mapInitiated: true,
        temporaryMarkerCoordinates: []
      });
    }
  }

  componentWillUnmount() {
    this.setState( { mapInitiated: false } )
  }

  displaySelectedTypes() {
    if (Object.keys(this.props.location).length !== 0 &&
    this.props.location.constructor === Object) {
      this.setState({ selectedTypes: this.compileSelectedTypes(this.props.location.types) })
    }
  }

  compileSelectedTypes(requestedTypes = ['beach', 'cliff']) {

    let compiledTypes = [];
    // console.log(requestedTypes)
    let typeOptions = this.state.typeOptions;
    // console.log(tagFieldOptions)
    if (typeOptions.length !== 0) {
      requestedTypes.forEach(type => {
        let index = typeOptions.findIndex(typeOption => typeOption.value === type);
  
        compiledTypes.push({
          "label": typeOptions[index].label,
          "value": type,
        });
      });
    }
    
    return compiledTypes;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      if (this.props.location) {
        // new location has been set, also set it in state
        let coordinates = this.props.isAddingNewLocation || !this.props.location.coords || this.props.location.coords.length === 0 ? [] : this.props.location.coords
        let mapZoom = coordinates.length !== 0 ? 14 : this.state.mapZoom
        let mapCenter = coordinates.length !== 0 ? coordinates[0] : { lat: 58.88785799999999, lng: 25.5411706 }

        this.setState({
          warnings: {},
          name: this.props.location.name,
          category: this.props.location.category,
          situation: this.props.location.situation,
          contact_name: this.props.location.attributes.CONTACT_NAME,
          contact_phone: this.props.location.attributes.CONTACT_PHONE,
          contact_email: this.props.location.attributes.CONTACT_EMAIL,
          notes: this.props.location.attributes.NOTES,
          types: this.props.location.types,
          selectedTypes: this.compileSelectedTypes(this.props.location.types),
          selectableCreatedTypes: [],
          coordinates: coordinates,
          mapZoom: mapZoom,
          mapCenter: mapCenter,
          temporaryMarkerCoordinates: []
        });
      } else {
        // location has been unset, also reset state
        this.setState({
          warnings: {},
          name: "",
          category: "",
          situation: "",
          contact_name: "",
          contact_phone: "",
          contact_email: "",
          notes: "",
          types: [],
          selectedTypes: [],
          selectableCreatedTypes: [],
          coordinates: [],
          mapZoom: 7,
          mapCenter: {lat: 58.88785799999999, lng: 25.5411706 },
          temporaryMarkerCoordinates: []
        });
      }
    }
  }

  render() {
    // console.log(getOS())
    const currentOs = getOS();
    const modalClass = this.props.isActive ? "is-active" : "";
    // const warningsClass = (Object.keys(this.state.warnings).length) ? 'has-warnings' : '';

    return (
      <div className={`modal ${modalClass}`}>
        <div
          className="modal-background"
          onClick={this.props.handleCloseClick}
        ></div>
        <div className="modal-card">

          <section className="modal-card-body">
          <DetailHeader 
            handleCloseClick={this.props.handleCloseClick} 
            details={Object.keys(this.props.location).length !== 0 ? this.props.location : "adding"}
            label={"location"}
          />
            {this.state.warnings &&
              Object.keys(this.state.warnings).map((key) => {
                return (
                  <article key={key} className="message is-warning">
                    <div className="message-header">
                      <p>{this.state.warnings[key]}</p>
                    </div>
                  </article>
                );
              })}

            <form onSubmit={this.handleSubmit}>
              <div className="columns">

              <div className="column is-half mb-0">
                <label className="label">Location</label>
                {this.state.mapInitiated &&
                  <MapComponent 
                    mapCenter={Object.keys(this.state.mapCenter).length === 0 ? {lat: 58.88785799999999, lng: 25.5411706 } : this.state.mapCenter}
                    mapZoom={this.state.mapZoom === "" ? 7 : this.state.mapZoom}
                    coordinates={this.state.coordinates}
                    handleMapCenterChange={(center) => this.handleMapCenterChange(center)} 
                    handleMapCenterZoomChange={(center) => this.handleMapCenterZoomChange(center)} 
                    handleDeleteMarkerClick={(coordinates) => this.handleDeleteMarkerClick(coordinates)}
                    saveNewMarkerShift={(shift) => this.saveNewMarkerShift(shift)} 
                    handleChangeMarkerLocation={(prevCoordinates, newCoordinates) => this.handleChangeMarkerLocation(prevCoordinates, newCoordinates)}
                    temporaryMarkerCoordinates={this.state.temporaryMarkerCoordinates}
                    expandedLocationImageObjects={this.props.expandedLocationImageObjects}
                  />
                }
                <div className="level is-mobile level-is-shrinkable mb-0">
                  <div className="level-left level-item">
                    <div className="field">
                      <div className="control">
                        <SearchComponent 
                          handleAddressSearch={(coordinates) => this.handleAddressSearch(coordinates)} 
                        />
                      </div>
                    </div>
                  </div>
                  {this.state.coordinates.length === 0 &&
                  <div className="level-right level-item">
                    <button 
                      onClick={this.addMarker}
                      className="button is-danger is-light"
                      type="button"
                    >
                      Place marker
                    </button>
                  </div>
                  }
                  {this.state.coordinates.length === 1 &&
                  <div className="level-right">
                    <div className="level-item mx-1">
                      <button 
                        onClick={this.clearMarkers}
                        className="button is-light"
                        type="button"
                      >
                        Clear marker
                      </button>
                    </div>
                    <div className="level-item">
                      <button 
                        onClick={this.addMarker}
                        className="button is-danger is-light"
                        type="button"
                      >
                        +
                      </button>
                    </div>
                    
                  </div>
                  }
                  {this.state.coordinates.length > 1 &&
                  <div className="level-right">
                    <div className="level-item mx-1">
                      <button 
                        onClick={this.clearMarkers}
                        className="button is-light"
                        type="button"
                      >
                        Clear all markers
                      </button>
                    </div>
                    <div className="level-item">
                      <button 
                        onClick={this.addMarker}
                        className="button is-danger is-light"
                        type="button"
                      >
                        +
                      </button>
                    </div>
                    
                  </div>
                  }
                  
                </div>
                {this.state.coordinates.length > 1 &&
                <div>
                  {currentOs === 'Mac OS' ?
                    <div className="level level-right clear-markers-hint">
                      hold Option to remove a single marker
                    </div>
                    :
                    <div className="level level-right clear-markers-hint">
                      hold Alt to remove a single marker
                    </div>
                  }
                </div>
                }
              </div>
                <div className="column is-half">
                  <div className="columns is-multiline">
                    <div className="column is-full">
                    <div className="field">
                        <label className="label">Name</label>
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            name="name"
                            placeholder="Ministry of Silly Walks"
                            onChange={this.handleChange}
                            value={this.state.name}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="column is-half">
                      <div className="field">
                        <label className="label">Category</label>
                        <div className="field">
                          <div className="control">
                            <div className="select">
                              <select
                                name="category"
                                value={this.state.category}
                                onChange={this.handleChange}
                              >
                                <option default value="FIXED">
                                  Fixed
                                </option>
                                <option value="REGION">Region</option>
                                <option value="MOBILE">Mobile</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="field">
                        <label className="label">Situation</label>
                        <div className="field">
                          <div className="control">
                            <div className="select">
                              <select
                                name="situation"
                                value={this.state.situation}
                                onChange={this.handleChange}
                              >
                                <option default value="NA">
                                  n/a
                                </option>
                                <option value="INTERIOR">Interior</option>
                                <option value="EXTERIOR">Exterior</option>
                                <option value="MIXED">Mixed</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="column is-half">
                      <div className="field">
                        <label className="label">Primary contact</label>
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            placeholder="John Cleese"
                            name="contact_name"
                            value={this.state.contact_name}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="field">
                        <label className="label">Phone number</label>
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            placeholder="+372 123 456"
                            name="contact_phone"
                            value={this.state.contact_phone}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="field">
                        <label className="label">Email</label>
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            placeholder="john@walk.gov.uk"
                            name="contact_email"
                            value={this.state.contact_email}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="column is-full">
                      <div className="field">
                      <label className="label">Types</label>
                        <CreatableSelect
                          key={`typeselect`}
                          isMulti 
                          className="locationselector__select"
                          classNamePrefix="select"
                          options={this.state.typeOptions}
                          onChange={this.handleTypeSelectChange}
                          placeholder="Start typing..."
                          value={this.state.selectedTypes}
                        />
                      </div>
                    </div>

                    <div className="column is-full">
                      <div className="field">
                        <label className="label">Notes</label>
                        <div className="control">
                          <textarea
                            className="textarea"
                            placeholder="It was the best of times, it was the worst of times..."
                            rows="5"
                            name="notes"
                            value={this.state.notes}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="level">
                <div className="level-left">
                </div>
                <div className="level-right">
                  <button
                    className="button level-item is-submit is-primary"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </section>

          {/* <footer className="modal-card-foot">
            <button className="button is-success">Save changes</button>
            <button className="button">Cancel</button>
          </footer> */}
        </div>
      </div>
    );
  }
}

export default LocationDetail;
