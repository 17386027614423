import React, { useEffect, useState } from 'react';
import { MapContainer, Polyline, Polygon, FeatureGroup, Popup } from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { LargeMapLocationMarkerComponent } from "./largeMapLocationMarkerComponent"
import { Map } from "./map"
import landmarkStyle from '../scss/nolandmarksStyle.json';

const LargeMapComponent = ({ 
  locations, 
  showLandmarks 
}) => {

  const [styles, setStyles] = useState([])
  const [mapCenter, setMapCenter] = useState([58.88785799999999, 25.5411706])
  const [mapZoom, setMapZoom] = useState(7)

  const fillOptions = { fillColor: '#E14A24', color: '#E14A24' }
  const mapFitBounds = locations.length === 1 ? locations[0].coords : []
  
  useEffect(() => {
    setMapCenter([locations[0].coords[0].lat, locations[0].coords[0].lng]);
    setMapZoom(14);
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    if (showLandmarks) {
      setStyles([])
    } else {
      setStyles(landmarkStyle)
    }
  },[showLandmarks])

  return (
    <MapContainer style={{ height: "100%", width: "100%" }} 
      zoom={mapZoom} 
      center={mapCenter}
    >
      <Map mapZoom={mapZoom} mapCenter={mapCenter} mapFitBounds={mapFitBounds} />
      <ReactLeafletGoogleLayer key={styles} useGoogMapsLoader={false} styles={styles}/>
        {locations.length !== 0 &&
          locations.map((location) => {
            return (
              <span key={location.id} >
                {location.coords && location.coords.length === 1 && 
                  // location.coords?.map((coordinates) => {
                  // const key = coordinates.lat + coordinates.lng
                  // return (
                    <LargeMapLocationMarkerComponent key={location.id} position={location.coords[0]} location={location}/>
                  // )
                }
                {/* )} */}
                {location.coords && location.coords.length === 2 && 
                  <FeatureGroup>
                    <Polyline pathOptions={fillOptions} positions={location.coords} />
                    <Popup >
                      <h2><b>{location.name}</b></h2>
                    </Popup>
                  </FeatureGroup>
                  
                }
                {location.coords && location.coords.length > 2 && 
                <FeatureGroup>
                  <Polygon pathOptions={fillOptions} positions={location.coords} />
                  <Popup >
                    <h2><b>{location.name}</b></h2>
                  </Popup>
                </FeatureGroup>
                }
              </span>
            )
          })
        }
    </MapContainer>
  );
}

export { LargeMapComponent };