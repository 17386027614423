import React, {useState, useEffect, useRef, useMemo} from 'react';
import ReactDOMServer from "react-dom/server";
import { Marker } from 'react-leaflet'
import Logo from "../lu-tools-logo-white-background.svg";
import L from "leaflet";
import { CloseIcon } from "./icons.js";

const LocationMarkerComponent = ({ 
  mapIsDragged, 
  location, 
  handleDeleteMarkerClick,
  handleChangeMarkerLocation,
  isMarkerTemporary
}) => {
  // console.log("🚀 ~ file: locationMarkerComponent.js ~ line 13 ~ location", location)

  const locationMarkerRef = useRef(null)
  const removeButtonRef = useRef(null)
  const xMarkerRef = useRef(null)
  const tempMarkerRef = useRef(null)
  const draggable = true

  const [isRemovable, setIsRemovable] = useState(false)

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
    }
  },[])

  const onKeyDown = (event) => {
    if (event.keyCode === 18) {
      event.preventDefault();
      setIsRemovable(true)
    }
  }
  
  const onKeyUp = (event) => {
    if (event.keyCode === 18) {
      event.preventDefault();
      setIsRemovable(false)
    }
  }

  const eventHandlers = useMemo(
    () => ({
      drag() {

        const tempMarker = tempMarkerRef.current
        const xMarker = xMarkerRef.current
        const removeMarker = removeButtonRef.current
        const marker = locationMarkerRef.current

        if (marker != null) {

          // const {lat, lng} = marker.getLatLng()
          // const latitude = parseFloat(lat.toFixed(6))
          // const longitude = parseFloat(lng.toFixed(6))
          
          if (xMarker != null) {
            // console.log("🚀 ~ file: locationMarkerComponent.js ~ line 54 ~ drag ~ xMarkerRef", xMarker)
            xMarker.setLatLng(marker.getLatLng())
          }
          if (tempMarker != null) {
            // console.log("🚀 ~ file: locationMarkerComponent.js ~ line 54 ~ drag ~ xMarkerRef", tempMarker)
            tempMarker.setLatLng(marker.getLatLng())
          }
          if (removeMarker != null) {
            // console.log("🚀 ~ file: locationMarkerComponent.js ~ line 54 ~ drag ~ xMarkerRef", tempMarker)
            removeMarker.setLatLng(marker.getLatLng())
          }
        }
      },
      dragend() {
        const marker = locationMarkerRef.current
        if (marker != null) {
          // console.log("Drag end: " + marker.getLatLng())
          const {lat, lng} = marker.getLatLng()
          const latitude = parseFloat(lat.toFixed(6))
          const longitude = parseFloat(lng.toFixed(6))
          handleChangeMarkerLocation(location, { lat: latitude, lng: longitude });
        }
      },
      click() {
        const removeMarker = removeButtonRef.current
        if (removeMarker != null) {
          handleDeleteMarkerClick(location);
        }
      },
    }),
     // eslint-disable-next-line react-hooks/exhaustive-deps
    [location],
  )

  const mapMarkerIcon = L.divIcon({
    className: "custom-icon",
    iconAnchor:   [15, mapIsDragged ? 40 : 30], // point of the icon which will correspond to marker's location
    popupAnchor:  [0, -30], // point from which the popup should open relative to the iconAnchor
    html: ReactDOMServer.renderToString(
      <img src={Logo} alt="Logo" width="30" height="30" />,
    ),
  });

  const xIcon = L.divIcon({
    className: "custom-icon-x",
    iconAnchor:   [5, 10], // point of the icon which will correspond to marker's location
    html: ReactDOMServer.renderToString(
      <span className="custom-icon-x-font" >&#10005;</span>,
    ),
  }) ;

  const newIcon = L.divIcon({
    className: "",
    iconAnchor:   [-3, mapIsDragged ? 50 : 40], // point of the icon which will correspond to marker's location
    html: ReactDOMServer.renderToString(
      <span style={{color: '#355af4', fontSize: '20px'}} className="custom-icon-bullet" >&#9679;</span>,
    ),
  }) ;

  const deleteIcon = L.divIcon({
    className: "",
    iconAnchor:   [-3, 40], // point of the icon which will correspond to marker's location
    html: ReactDOMServer.renderToString(
      <span className="custom-icon-marker-delete" ><CloseIcon fill="#c00" size="18" /></span>,
    ),
  }) ;

  return (
      <>
        <Marker 
          position={location} 
          icon={mapMarkerIcon} 
          eventHandlers={eventHandlers}
          draggable={draggable}
          ref={locationMarkerRef}
        >
        </Marker>

        <Marker 
          position={location} 
          icon={xIcon}
          ref={xMarkerRef}
        >
        </Marker>

        {isRemovable &&
          <Marker 
            position={location} 
            icon={deleteIcon}
            eventHandlers={eventHandlers}
            ref={removeButtonRef}
          >
          </Marker>
        }

        {isMarkerTemporary &&
          <Marker 
            position={location} 
            icon={newIcon}
            ref={tempMarkerRef}

          >
          </Marker>
        }

      </>
  );
}

export { LocationMarkerComponent };