import React, { useEffect } from 'react';
import { ToLightboxIcon, CheckedIcon, TimeofDayIcon, TimeofYearIcon, RemoveFromLightboxIcon, LocationIcon, SituationIcon, TonalityIcon, VantagePointIcon, ContentTagsIcon, DownloadImagesIcon, LightboxIcon } from './icons.js';
import Logo from "../lu-tools-logo.svg";
import useLockBodyScroll from '../hooks/use-lock-body-scroll';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ImageViewer = props => {

  // console.log(props)
  useLockBodyScroll();

  const modalClass = (props.imageViewerIsActive) ? 'is-active' : '';
  const modalIsSharedClass = (!props.sharedMode) ? 'click-to-zoom-out' : 'is-cursor-default';

  const locId = (props.imageViewerShowsImage.locationId) ? (props.imageViewerShowsImage.locationId) : '';
  const locationName = (props.imageViewerShowsImage.locationId ? (props.getLocationForId(props.imageViewerShowsImage.locationId)?.name) : '');
  const largeImageAlreadyInLightbox = (props.lightboxImages.some(lightboxImage => lightboxImage.id === props.imageViewerShowsImage.id)) ? true : false;
  const currentLargeImageIndex = props.images.findIndex(image => image.id === props.imageViewerShowsImage.id);
  let lightboxCreator = '';
  if (props.sharedMode) {
    lightboxCreator = props.lightboxCreator;
  } else if (props.lightboxCreator && props.user?.name) {
    lightboxCreator = props.lightboxCreator === props.user.name ? "you" : props.lightboxCreator;
  }

  const keyboardEventFunction = (event) => {

    if (!props.sharedMode && event.keyCode === 27) {
      // escape button closes viewer
      props.handleViewerCloseClick();

    } else if (!props.sharedMode && event.keyCode === 13) {
      // enter button adds image to lightbox
      props.imageToLightbox(props.imageViewerShowsImage);

    } else if (event.keyCode === 38) {
      // arrow up button shows previous image as large image
      if (currentLargeImageIndex === 0) {
        console.log("No images this way")
      } else {
        props.showLargeImages(props.images[currentLargeImageIndex - 1]);
      }

    } else if (event.keyCode === 40) {
      // arrow down button shows next image as large image
      if (currentLargeImageIndex === props.images.length - 1) {
        console.log("No images this way")
      } else {
        props.showLargeImages(props.images[currentLargeImageIndex + 1]);
      }
    }
  }

  useEffect(() => {
    if (props.imageViewerIsActive) {
      document.addEventListener("keyup", keyboardEventFunction, false);
      return () => {
        document.removeEventListener("keyup", keyboardEventFunction, false);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.imageViewerIsActive, props.imageViewerShowsImage]);

  return (
    <div className={`modal imageviewer ${modalClass}`}>
      <div className={`modal-background is-dark ${modalIsSharedClass}`} onClick={!props.sharedMode ? props.handleViewerCloseClick : undefined}></div>
      <div className="modal-content">
        <div className="level modal-title mb-3">
          <div className="level-left">
            {props.lightboxName && props.lightboxCreator &&
              <>
                <div className="level-item mb-1 mr-1">
                  <LightboxIcon fill="#FFF" size="20" />
                </div>
                <h3 className="imageviewer__lb-title level-item">
                  {props.lightboxName}
                </h3>
                <small className="imageviewer__lb-creator level-item">
                  created by {lightboxCreator}
                </small>
              </>
            }
          </div>
          {props.sharedMode &&
            <div className="imageviewer__logo level-right">
              <img src={Logo} alt="Logo" width="24" height="24" />
            </div>
          }
        </div>
        <div className="modal-content-columns columns  is-mobile is-gapless my-0 py-0">
          <div className="column is-1 imageviewer__left-column">
            <div className="imageviewer__left-column-item-container">
              {props.images.map((image) => {
                return (
                  <div className="imageviewer__left-column-items" key={image.id} >
                    <LazyLoadImage
                      className={`imageviewer__left-column-image-${(props.imageViewerShowsImage === image) ? 'active' : 'normal'}`}
                      src={image.sources.s}
                      alt={image.path}
                      onClick={() => props.showLargeImages(image)}
                    />
                    {!props.sharedMode && props.lightboxImages.some(lightboxImage => lightboxImage.id === image.id) &&
                      <span className="imageviewer__already-in-lightbox-small">
                        <CheckedIcon fill="green" size="12px" />
                      </span>
                    }
                    {!props.sharedMode && !props.lightboxImages.some(lightboxImage => lightboxImage.id === image.id) &&
                      <span className="imageviewer__add-to-lightbox-button-small" onClick={() => props.imageToLightbox(image)}>
                        <DownloadImagesIcon fill="white" size="12px" />
                      </span>
                    }
                    {!props.sharedMode && props.lightboxImages.some(lightboxImage => lightboxImage.id === image.id) &&
                      <span className="imageviewer__remove-from-lightbox-button-small" onClick={() => props.removeImageFromLightbox(image)}>
                        <RemoveFromLightboxIcon fill="red" size="12px" />
                      </span>
                    }
                  </div>
                );
              })}
            </div>
            {!props.sharedMode && !largeImageAlreadyInLightbox &&
              <span className="imageviewer__add-to-lightbox-button-large" >
                <button className="button" onClick={() => props.imageToLightbox(props.imageViewerShowsImage)}>
                  <ToLightboxIcon fill="white" size="24" />
                </button>
              </span>
            }
            {!props.sharedMode && largeImageAlreadyInLightbox &&
              <>
                <span className="imageviewer__remove-from-lightbox" >
                  <button onClick={() => props.removeImageFromLightbox(props.imageViewerShowsImage)}>
                    <RemoveFromLightboxIcon fill="red" size="24" />
                  </button>
                </span>
                <span className="imageviewer__already-in-lightbox" >
                  <button className="button-disabled">
                    <CheckedIcon fill="green" size="24" />
                  </button>
                </span>
              </>
            }
          </div>
          <div className="imageviewer__image-box column is-11">
            <span className={`image ${modalIsSharedClass}`} onClick={!props.sharedMode ? props.handleViewerCloseClick : undefined}>
              <img className="imageviewer-viewer-image" src={props.imageViewerShowsImagePath} alt="" />
            </span>
          </div>
        </div>
        {!props.sharedMode &&
          <div className="imageviewer__image-metadata-box py-2">
            <div className="columns is-mobile imageviewer__image-metadata">
              {locId && locationName !== '' &&
                <p className="imageviewer__attribute-info ">
                  <LocationIcon fill="white" size="14" />
                  <span className="imageviewer__attribute-value">
                    {locationName}
                  </span>
                </p>
              }
              {props.imageViewerShowsImage.situation &&
                <p className="imageviewer__attribute-info ">
                  <SituationIcon fill="white" size="14" />
                  <span className="imageviewer__attribute-value">
                    {props.imageViewerShowsImage.situation.toLowerCase()}
                  </span>
                </p>
              }
              {props.imageViewerShowsImage.timeOfDay &&
                <p className="imageviewer__attribute-info ">
                  <TimeofDayIcon fill="white" size="14" />
                  <span className="imageviewer__attribute-value">
                    {props.imageViewerShowsImage.timeOfDay.toLowerCase()}
                  </span>
                </p>
              }
              {props.imageViewerShowsImage.timeOfYear &&
                <p className="imageviewer__attribute-info ">
                  <TimeofYearIcon fill="white" size="14" />
                  <span className="imageviewer__attribute-value">
                    {props.imageViewerShowsImage.timeOfYear.toLowerCase()}
                  </span>
                </p>
              }
              {props.imageViewerShowsImage.tonality &&
                <p className="imageviewer__attribute-info ">
                  <TonalityIcon fill="white" size="14" />
                  <span className="imageviewer__attribute-value">
                    {props.imageViewerShowsImage.tonality.toLowerCase()}
                  </span>
                </p>
              }
              {props.imageViewerShowsImage.vantagePoint &&
                <p className="imageviewer__attribute-info">
                  <VantagePointIcon fill="white" size="14" />
                  <span className="imageviewer__attribute-value">
                    {props.imageViewerShowsImage.vantagePoint.toLowerCase()}
                  </span>
                </p>
              }
              {props.imageViewerShowsImage.contentTags !== 'undefined' && Array.isArray(props.imageViewerShowsImage.contentTags) &&
                <div className="imageviewer__content-tags">
                  {props.imageViewerShowsImage.contentTags.length > 0 &&
                    <span className="imageviewer__content-tag-icon">
                      <ContentTagsIcon fill="white" size="14" />
                    </span>
                  }
                  {props.imageViewerShowsImage.contentTags.map((tag) => {
                    return (
                      <span className="imageviewer__content-tag-value mb-1" key={tag}>
                        {tag}
                      </span>
                    );
                  })}
                </div>
              }
            </div>
          </div>
        }
      </div>
      {!props.sharedMode &&
        <button className="modal-close is-large click-to-zoom-out" aria-label="close" onClick={props.handleViewerCloseClick}></button>
      }
    </div>

  )
}

export default ImageViewer;