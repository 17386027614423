import React from 'react';
import { LightboxCreatorIcon, ImagesIcon, LightboxIcon, CloseIcon, LocationCategoryIcon, SituationIcon, ContentTagsIcon, LocationNotesIcon, LocationIcon } from "./icons.js";

const DetailHeader = props => {

  // console.log(props)
  
  if (props?.details?.name) {

    return (
        <div className="level">
          <div className="level-left">
            <Detail 
              icon={props.label}
              detail={props.details.name}
            />
          </div>
          <div className="level-right">
            <div className="level-item">
            <span
              className="modal__clickable pt-1"
              onClick={() => props.handleCloseClick()}
            >
              <CloseIcon fill="#c00" size="18" />
            </span>
            </div>
          </div>
        </div>
    )
  }
  if (props?.details === "adding") {

    return (
        <div className="level">
          <div className="level-left">
            <Detail 
              icon={props.label}
              detail={`Adding new ${props.label}`}
            />
          </div>
          <div className="level-right">
            <div className="level-item">
            <span
              className="modal__clickable pt-1"
              onClick={() => props.handleCloseClick()}
            >
              <CloseIcon fill="#c00" size="18" />
            </span>
            </div>
          </div>
        </div>
    )
  }
  return(<></>)
}

const DetailSection = props => {

  if (props?.details) {

    return (
      <>
        {props.details.createdBy &&
          <div className="modal__details-row">
            <Detail 
              icon={"name"}
              detail={props.details.createdBy}
            />
          </div>
        }
        {props.details.images &&
          <div className="">
            <Detail 
              icon={"nrOfImages"}
              detail={props.details.images}
            />
          </div>
        }
        {props.details.attributes?.CATEGORY &&
          <div className="">
            <Detail 
              icon={"category"}
              detail={props.details.attributes.CATEGORY}
            />
          </div>
        }
        {props.details.attributes?.SITUATION &&
          <div className="">
            <Detail 
              icon={"situation"}
              detail={props.details.attributes.SITUATION}
            />
          </div>
        }
        {props.details.types && props.details.types.length !== 0 &&
          <div className="">
            <Detail 
              icon={"contentTags"}
              detail={props.details.types}
            />
          </div>
        }
        {props.details.attributes?.NOTES &&
          <div className="mt-4">
            <Detail 
              icon={"notes"}
              detail={props.details.attributes.NOTES}
            />
          </div>
        }
      </>
    )
  
  }
  return(<></>)
}

const Detail = props => {

  if (props?.detail) {

    // console.log(props)

    switch (props.icon) {
      case "name":
        return (
          <div 
            className="modal__details-row">
              <LightboxCreatorIcon fill="#000" size="18"  /> 
              <span>{props.detail}</span>
          </div>
        );

      case "nrOfImages":
        return (
          <div 
            className="modal__details-row">
              <ImagesIcon fill="#000" size="18"  /> 
              {props.detail.length === 1 ?
                <span>1 photo</span>
                :
                <span>{props.detail.length} photos</span>
              }
          </div>
        );

        case "category":
        return (
          <div 
            className="modal__details-row">
              <LocationCategoryIcon fill="#000" size="18"  /> 
              <span className="tag">{String(props.detail).toLowerCase().replace('_', ' ')}</span>
          </div>
        );

        case "situation":
        return (
          <div 
            className="modal__details-row">
              <SituationIcon fill="#000" size="18"  /> 
              <span className="tag">{String(props.detail).toLowerCase().replace('_', ' ')}</span>
          </div>
        );

        case "contentTags":
        return (
          <div 
            className=" columns is-variable is-0">
              <span className="column is-narrow p-0 mt-1"><ContentTagsIcon fill="#000" size="18"  /></span>
              <div className="column tags modal__details-row-contentTags ml-1">
              {props.detail.map((type) => (
              <span className="tag" key={type}>{String(type).toLowerCase().replace('_', ' ')}</span>
              ))}
              </div>
          </div>
        );

        case "notes":
        return (
          <div 
            className=" columns is-variable is-0">
              <span className="column is-narrow p-0">
                <LocationNotesIcon fill="#000" size="18"  />
              </span>
              <span className="column ml-1"><span className="modal__details-row-notes">{(props.detail)}</span></span>
          </div>
        );

        case "lightbox":
        return (
          <>
            <div className="level-item mb-1">
              <LightboxIcon fill="#black" size="20" />
            </div>
            <h3 className="modal__title level-item">
              {props.detail}
            </h3>
          </>
        );

        case "location":
        return (
          <>
            <div className="level-item mb-1">
              <LocationIcon fill="#black" size="20" />
            </div>
            <h3 className="modal__title level-item">
              {props.detail}
            </h3>
          </>
        );

      default:
        return (
          <span ></span>
        )  
    }
  }
  return(<></>)
}


export { Detail, DetailSection, DetailHeader };