import React, { Component } from 'react';
// import Select from 'react-select';
import Select from 'react-select';
import { SearchSummary } from '../components/searchSummary';
import ImageDetail from '../components/imageDetail';

import { DownloadImagesIcon, CheckedIcon, RemoveFromLightboxIcon, ImagesIcon, SearchIcon, EditIcon } from '../components/icons.js';

import ImageViewer from '../components/imageViewer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import MapViewer from '../components/mapViewer';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class BrowsePage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searchDone:false,
      allSearchResultImages: [],
      isLoading: false,
      resultsAreLimited: false,
      referenceData: {},
      includeNonAvailable: false,
      searchFieldOptions: [],
      searchFieldValues: [],
      searchFreeformText: undefined,
      excludeFreeformText: undefined,
      excludeFieldValues: [],
      searchQueryPayload: {},
      isRowExpanded: true,
      displayTitle:"no image provided",
      searchResultLocations: [],
      searchResultLocationRelevantImages: [],
      searchResultLocationExtraImages: [],
      searchResultImagesWithoutLocation: [],
      editModalAllImages: [],
      showBlankPage: true,
      showEditIcon: false,
      mapViewerIsActive: false,
      mapViewerShowsLocation:{},
    }
  }
    
  onKeyDown = (event) => {
    if (event.keyCode === 18) {
      event.preventDefault();
      this.setState({ showEditIcon: true });
    }
  }
  
  onKeyUp = (event) => {
    if (event.keyCode === 18) {
      event.preventDefault();
      this.setState({ showEditIcon: false });
    }
  }

  handleImageEditClick = (image, images) => {
    this.props.setCurrentlyEditingImages([image]);
    this.props.setCurrentlyEditingAttribute("CONTENT_TAG");
    this.props.setDetailModalIsActive(true);
    this.props.setSearchImageViewerImages([image]);
    this.setState({ editModalAllImages: images });
  }

  loadReferenceData() {

    const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json' } };
    fetch(process.env.REACT_APP_BE_API_HOST + '/api/ref-data', requestOptions)
      .then(res => res.json())
      .then((data) => {
        // console.log('data: ', data);
        this.setState({ referenceData: data['ref'] }, () => this.compileSearchOptions());
      })
      .catch(console.log);
  }

  reloadImages(ids) {

    const requestOptions = { 
      method: 'POST', 
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'ids': ids
      }) 
    };

    fetch(process.env.REACT_APP_BE_API_HOST + '/api/details', requestOptions)
      .then(res => res.json())
      .then((data) => {
        if (data['images']) {
          const dataImages = data['images'];

          let newStateSearchResultLocationRelevantImages = [...this.state.searchResultLocationRelevantImages];
          let newStateSearchResultLocationExtraImages = [...this.state.searchResultLocationExtraImages];
          let newStateSearchResultImagesWithoutLocation = [...this.state.searchResultImagesWithoutLocation];
          let newStateEditModalAllImages = [...this.state.editModalAllImages]

          ids.forEach(idToReplace => {
            const indexInData = dataImages.findIndex(image => image.id === idToReplace);

            if (newStateSearchResultLocationRelevantImages.findIndex(image => image.id === idToReplace) !== -1) {
              const searchResultLocationRelevantImageIndexInState = newStateSearchResultLocationRelevantImages.findIndex(image => image.id === idToReplace);
              newStateSearchResultLocationRelevantImages[searchResultLocationRelevantImageIndexInState] = dataImages[indexInData];
            }

            if (newStateSearchResultLocationExtraImages.findIndex(image => image.id === idToReplace) !== -1) {
              const searchResultLocationExtraImageIndexInState = newStateSearchResultLocationExtraImages.findIndex(image => image.id === idToReplace);
              newStateSearchResultLocationExtraImages[searchResultLocationExtraImageIndexInState] = dataImages[indexInData];
            }

            if (newStateSearchResultImagesWithoutLocation.findIndex(image => image.id === idToReplace) !== -1) {
              const searchResultImageWithoutLocationIndexInState = newStateSearchResultImagesWithoutLocation.findIndex(image => image.id === idToReplace);
              newStateSearchResultImagesWithoutLocation[searchResultImageWithoutLocationIndexInState] = dataImages[indexInData];
            }

            if (newStateEditModalAllImages.findIndex(image => image.id === idToReplace) !== -1) {
              const editModalAllImagesIndexInState = newStateEditModalAllImages.findIndex(image => image.id === idToReplace);
              newStateEditModalAllImages[editModalAllImagesIndexInState] = dataImages[indexInData];
            }
            
          });
          this.setState({ 
            searchResultLocationRelevantImages: newStateSearchResultLocationRelevantImages,
            searchResultLocationExtraImages: newStateSearchResultLocationExtraImages,
            searchResultImagesWithoutLocation: newStateSearchResultImagesWithoutLocation,
            editModalAllImages: newStateEditModalAllImages
          });
        }
      })
      .catch(console.log);
  }

  compileSearchOptions() {

    /*
      TODO add attributes to this list

      WARNING this method is duplicated 1:1 in browsePage and imageDetail. Make it not so.
    */

    // console.log(this.props.locations)

    const tagOptions = [];

    // get all grouped tags, and add them to the tags options group
    for (const [groupName, tags] of Object.entries(this.state.referenceData.tagGroups)) {
      tags.forEach(tag => {
        tagOptions.push({ value: `keyword__${tag}`, label: `${groupName} - ${tag}`});
      })
    }

    // do the same for all user-defined "other" tags
    this.state.referenceData.otherTags.forEach(tag => {
      tagOptions.push({ value: `keyword__${tag}`, label: `OTHER – ${tag}`});
    });


    let groupedOptions = [
      {
        label: 'Tags',
        options: tagOptions,
      },
    ];

    console.log('this.state.referenceData.simpleAttributes: ', this.state.referenceData.simpleAttributes);
    this.state.referenceData.simpleAttributes.forEach(attribute => {
      const attributeReferenceName = attribute.apiName + 'Values';
      const attributeValues = this.state.referenceData[attributeReferenceName];
      if(attributeValues) {
        let attributeOptions = [];
        attributeValues.forEach(value => {
          const apiValue = value.toLowerCase();
          let displayValue = 'n/a';
          if (value !== 'NA') {
            displayValue = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
            displayValue = displayValue.replace('_', ' ');
          }
          attributeOptions.push({ value: `attribute__${attribute.name}__${apiValue}`, label: `${attribute.displayName} - ${displayValue}`});
        });

        const attributeOptionGroup = {
          label: attribute.displayName,
          options: attributeOptions,
        };

        groupedOptions.push(attributeOptionGroup);
        // console.log(groupedOptions)
      }
    });

    this.setState({ searchFieldOptions: groupedOptions })

  }


  handleSearchChange = values => {
    this.setState({ searchFieldValues: values });
  }

  handleFreeformChange = evt => {
    this.setState({ searchFreeformText: evt.target.value });
  }

  handleExcludeChange = values => {
    this.setState({ excludeFieldValues: values});
  }

  handleFreeformExcludeChange = evt => {
    this.setState({ excludeFreeformText: evt.target.value });
  }

  handleIncludeNonAvailaableChange = evt => {
    this.setState({ includeNonAvailable: evt.target.checked });
  }


  handleSearchSubmit = event => {

    event.preventDefault();
    this.setState({ 
      searchDone:false,
      showBlankPage: false 
    })

    // console.log(this.state.searchFieldValues);
    // parse this.state.searchFieldValues and create a searchFieldOptions object
    // this object should look like the search query payload

    let searchObject = {
      'keywords': [],
      'attrs': {},
      'excludeKeywords': [],
      'excludeAttrs': {},
      'freeText': [],
      'excludeFreeText': [],
      'includeNonAvailable': this.state.includeNonAvailable
    }

    if(this.state.searchFreeformText) {
      searchObject.freeText.push(...(this.state.searchFreeformText.split(',').map(tok => tok.trim()).filter(Boolean)));
    }

    if(this.state.excludeFreeformText) {
      searchObject.excludeFreeText.push(...(this.state.excludeFreeformText.split(',').map(tok => tok.trim()).filter(Boolean)));
    }

    if (this.state.searchFieldValues && this.state.searchFieldValues.length > 0) {
      this.state.searchFieldValues.forEach(searchParameter => {
        const valueBits = (searchParameter['value'].toLowerCase()).split('__');
        
        if (valueBits[0] === 'keyword') {

          searchObject['keywords'].push(valueBits[1]);

        } else if (valueBits[0] === 'attribute') {

          if (valueBits[1] in searchObject.attrs) {
            searchObject.attrs[valueBits[1]].push(valueBits[2])
          } else {
            searchObject.attrs[valueBits[1]] = [valueBits[2]];
          }

        }
      });
    }

    if (this.state.excludeFieldValues && this.state.excludeFieldValues.length > 0) {
      this.state.excludeFieldValues.forEach(excludeParameter => {
        const valueBits = (excludeParameter['value'].toLowerCase()).split('__');
        
        if (valueBits[0] === 'keyword') {

          searchObject['excludeKeywords'].push(valueBits[1]);

        } else if (valueBits[0] === 'attribute') {

          if (valueBits[1] in searchObject.excludeAttrs) {
            searchObject.excludeAttrs[valueBits[1]].push(valueBits[2])
          } else {
            searchObject.excludeAttrs[valueBits[1]] = [valueBits[2]];
          }

        }
      });
    }

    //console.log(searchObject)
    this.setState({ 
      searchQueryPayload: searchObject,
      isLoading: true
    }, () => this.handleSearchResults());

  }

  async handleSearchResults() { 

    const searchResultLocations = await this.loadImages();
    const locationRelevantImages = [];
    const locationExtraImages = [];
      
    for (let i = 0; i < searchResultLocations.length; i++) {
      
      const allImagesFromSearchResultLocation = await this.loadLocationImages(searchResultLocations[i].id);

      allImagesFromSearchResultLocation.forEach(image => {
        if (this.state.allSearchResultImages.some((im) => im.id === image.id)) {
          locationRelevantImages.push(image);
        } else {
          locationExtraImages.push(image);
        }
      })
    }

    this.setState({
      searchResultLocationRelevantImages:locationRelevantImages,
      searchResultLocationExtraImages:locationExtraImages,
      searchDone:true,
    })
  }

  loadLocationImages(locationId){

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "locationId":locationId
        })
    };

    return fetch(process.env.REACT_APP_BE_API_HOST + `/api/location/list-images`, requestOptions)
      .then(res => res.json())
      .then((data) => {
        // console.log(data)
        return data.images;
      })
      .catch(console.log);
  }

  loadImages() {

    const payload = this.state.searchQueryPayload;
    // console.log(this.state.searchQueryPayload)

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    };

    return fetch(process.env.REACT_APP_BE_API_HOST + '/api/search', requestOptions)
      .then(res => res.json())
      .then((data) => {
        // console.log(data)
        const searchResultLocationIds = [];
        const searchResultLocations = [];
        const searchResultImagesWithoutLocation = [];
        if (data['images'].length > 0) {
          data['images'].forEach(im => {
            if (im.locationId && searchResultLocationIds.indexOf(im.locationId) === -1) {
              // console.log("new loc id to save")
              searchResultLocationIds.push(im.locationId);
            } else if (!im.locationId) {
              // console.log("no loc id on image")
              searchResultImagesWithoutLocation.push(im);
            } else {
              // console.log("location id has already been saved")
            }
          })
        }

        for (const locId of searchResultLocationIds) {
          const indexOfLocId = this.props.locations.findIndex((location) => location.id === locId )
          searchResultLocations.push(this.props.locations[indexOfLocId]);
        }

        // console.log(data['images']['content'])
        this.setState({ 
          allSearchResultImages: data['images'],
          searchResultImagesWithoutLocation: searchResultImagesWithoutLocation,
          searchResultLocations: searchResultLocations,
          isLoading: false,
          resultsAreLimited: data['limited']
        })
        return searchResultLocations;
      })
      .catch(console.log);
  }


  componentDidMount() {
    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("keyup", this.onKeyUp);
    this.props.loadLocations();
    this.loadReferenceData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lightboxImages !== this.props.lightboxImages) {
      // console.log("🚀 ~ file: browsePage.js ~ line 330 ~ BrowsePage ~ componentDidUpdate ~ this.props.lightboxImages.length", this.props.lightboxImages.length)
      if (this.state.searchQueryPayload && !this.state.isLoading && this.state.searchDone) {
        this.handleSearchResults();
      }
    }
    if (prevProps.locations !== this.props.locations) {
      // console.log("🚀 ~ file: browsePage.js ~ line 336 ~ BrowsePage ~ componentDidUpdate ~ this.props.locations.length", this.props.locations.length)
      if (this.state.searchQueryPayload && !this.state.isLoading && this.state.searchDone) {
        this.handleSearchResults();
      }
    }
  }

  openMapViewer(location) {
    this.setState( { mapViewerShowsLocation: location, mapViewerIsActive: true })
  }

  handleMapViewerCloseClick() {
      this.setState({ mapViewerIsActive: false, mapViewerShowsLocation: {} })
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keyup', this.onKeyUp)
    this.props.closeDetail();
  }

  render() {

    return (
      <>
      <div className="container is-fluid">
        <div className="section">
          <div className="columns is-5">

            <div className="column is-2">
              <h2 className="title is-3">Search</h2>

              <form onSubmit={this.handleSearchSubmit} className="mb-2">
              <div className="field">
                  <label id="freeform-input-label" htmlFor="freeform-input">
                    Matching keywords:
                  </label>
                  <input 
                    className="input" 
                    type="text" 
                    name="freeform-input"
                    placeholder="Start typing..." 
                    onChange={this.handleFreeformChange}
                    value={this.state.searchFreeformText}
                  />
                </div>
                <div className="field">
                  <label id="search-input-label" htmlFor="search-input">
                    Exact tags or attributes::
                  </label>
                  <Select
                    aria-labelledby="search-input-label"
                    name="search-input"
                    isMulti
                    placeholder="Start typing..."
                    classNamePrefix="select"
                    options={this.state.searchFieldOptions}
                    onChange={this.handleSearchChange}
                   />
                </div>
                <div className="field">
                  <label id="freeform-exclude-input-label" htmlFor="freeform-exclude-input">
                    Excluding keywords:
                  </label>
                  <input 
                    className="input" 
                    type="text" 
                    name="freeform-exclude-input"
                    placeholder="Undesired keywords..." 
                    onChange={this.handleFreeformExcludeChange}
                    value={this.state.excludeFreeformText}
                  />
                </div>
                <div className="field">
                  <label id="exclude-input-label" htmlFor="exclude-input">
                    Excluding tags or attributes:
                  </label>
                  <Select 
                    isMulti
                    placeholder="Undesired tags..."
                    classNamePrefix="select"
                    options={this.state.searchFieldOptions} 
                    onChange={this.handleExcludeChange}
                    aria-labelledby="exclude-input-label"
                    name="exclude-input"
                  />
                </div>
                <div className="field">
                  <label class="checkbox" htmlFor="include-non-available">
                  <input 
                    id="include-non-available"
                    type="checkbox" 
                    name="include-non-available"
                    checked={this.state.includeNonAvailable}
                    onChange={this.handleIncludeNonAvailaableChange}
                    />
                      Include non-available images
                  </label>
                </div>

                <button 
                  className="button level-item is-submit is-primary" 
                  type="submit"
                  disabled={(this.state.isLoading)}
                >Search</button>

              </form>

              {this.state.resultsAreLimited && 
                <article className="message is-warning">
                  <div className="message-header">
                    <p>Be more specific :)</p>
                    {/* <button className="delete" aria-label="delete"></button> */}
                  </div>
                  <div className="message-body">
                    These search terms yielded lots of responses. Results are limited to {this.state.allSearchResultImages.length} images.
                  </div>
                </article>
              }

            </div>
            <div className="column is-9">
              {this.state.showBlankPage &&
                <div className="searchPage__searchIcon">
                  <SearchIcon fill="#666666" size="298"/>
                  </div>
              }
              {this.state.searchDone &&
                <div className="title is-3">
                  {this.state.allSearchResultImages.length} results
                  {((this.state.searchQueryPayload.freeText.length !== 0 || this.state.searchQueryPayload.keywords.length !== 0 || Object.values(this.state.searchQueryPayload.attrs).length !== 0)) &&
                    <span> for </span>
                  }
                  <span>{this.state.searchQueryPayload.freeText.length !== 0 && this.state.searchQueryPayload.freeText.map((keyword) => { return '"' + keyword + '"' }).join(' AND ')}</span>
                  {this.state.searchQueryPayload.freeText.length !== 0 && (Object.values(this.state.searchQueryPayload.keywords).length !== 0 || Object.values(this.state.searchQueryPayload.attrs).length !== 0) &&
                  <span> AND </span>
                  }
                  <span>{this.state.searchQueryPayload.keywords.length !== 0 && this.state.searchQueryPayload.keywords.map((keyword) => { return '"' + keyword + '"' }).join(' AND ')}</span>
                  {this.state.searchQueryPayload.keywords.length !== 0 && Object.values(this.state.searchQueryPayload.attrs).length !== 0 &&
                  <span> AND </span>
                  }
                  <span>{Object.values(this.state.searchQueryPayload.attrs).map((attr) => { return '"' + attr + '"' }).join(' AND ')}</span>
                  {(this.state.searchQueryPayload.excludeFreeText.length !== 0 || this.state.searchQueryPayload.excludeKeywords.length !== 0 || Object.values(this.state.searchQueryPayload.excludeAttrs).length !== 0) && (
                    <p>
                      <br/>
                      <span>Excluded: </span>
                      <span>{this.state.searchQueryPayload.excludeFreeText.length !== 0 && this.state.searchQueryPayload.excludeFreeText.map((keyword) => { return '"' + keyword + '"' }).join(' AND ')}</span> 
                      {this.state.searchQueryPayload.excludeFreeText.length !== 0 && (Object.values(this.state.searchQueryPayload.excludeKeywords).length !== 0 || Object.values(this.state.searchQueryPayload.excludeAttrs).length !== 0) &&
                        <span> AND </span>
                      }
                      <span>{this.state.searchQueryPayload.excludeKeywords.length !== 0 && this.state.searchQueryPayload.excludeKeywords.map((keyword) => { return '"' + keyword + '"' }).join(' AND ')}</span>
                      {this.state.searchQueryPayload.excludeKeywords.length !== 0 && Object.values(this.state.searchQueryPayload.excludeAttrs).length !== 0 &&
                        <span> AND </span>
                      }
                      <span>{Object.values(this.state.searchQueryPayload.excludeAttrs).map((attr) => { return '"' + attr + '"' }).join(' AND ')}</span>
                    </p>
                  ) }
                </div>
              }

              {this.state.searchResultLocations.map((location) => {
                // console.log(location)
                // console.log(this.state.searchResultLocationRelevantImages)
                let searchRelevantImages = this.state.searchResultLocationRelevantImages.filter((image) => image.locationId === location.id)
                // console.log(searchRelevantImages)
                let otherLocationImages = this.state.searchResultLocationExtraImages.filter((image) => image.locationId === location.id)

                return (
                  <SearchSummary
                      key={`location_${location.id}`}
                      isRowExpanded={this.state.isRowExpanded}
                      handleEditClick={(loc) => this.props.editLocation(loc)}
                      searchQueryPayload={this.state.searchQueryPayload}
                      location={location}
                      searchRelevantImages={searchRelevantImages}
                      otherImages={otherLocationImages}
                      manyImagesToLightbox={(images) => this.props.manyImagesToLightbox(images)}
                      showLargeImages= {(image, images) => this.props.showLargeSearchLocationImages(image, images)}
                      imageToLightbox={(image) => this.props.imageToLightbox(image)}
                      lightboxImages={this.props.lightboxImages}
                      removeImageFromLightbox={(image) => this.props.removeImageFromLightbox(image)}
                      handleViewerCloseClick={() => this.props.closeViewer()}
                      imageViewerShowsImagePath={this.props.imageViewerShowsImagePath}
                      imageViewerShowsImage={this.props.imageViewerShowsImage}
                      referenceData={this.state.referenceData}
                      loadReferenceData={() => this.loadReferenceData()}
                      setCurrentlyEditingImages={(images) => this.props.setCurrentlyEditingImages(images)}
                      currentlyEditingImages={this.props.currentlyEditingImages}
                      reloadImages={(imageIds) => this.reloadImages(imageIds)}
                      detailModalIsActive={this.props.detailModalIsActive}
                      closeDetail={() => this.props.closeDetail()}
                      showEditIcon={this.state.showEditIcon}
                      handleImageEditClick={(image, images)=>this.handleImageEditClick(image, images)}
                      getLocationForId={(id) => this.props.getLocationForId(id)}
                      currentlyEditingAttribute={this.props.currentlyEditingAttribute}
                      setCurrentlyEditingAttribute={this.props.setCurrentlyEditingAttribute}
                      locations={this.props.locations}
                      editModalAllImages={this.state.editModalAllImages}
                      loadLocations={() => this.props.loadLocations()}
                      openMapViewer={(location) => this.openMapViewer(location)}
                  />
                );
              })}
              
              {this.state.isLoading &&
                <div className="section has-text-centered">
                  <FontAwesomeIcon icon={faCircleNotch} spin size="4x" color="#ddd" /> 
                </div>
              }

              {this.state.searchResultImagesWithoutLocation.length !== 0 &&
              <>
                <div className="searchsummary__locationless-images level mt-6">   
                  <div className="level-left">
                      <div className="level-item searchsummary__level--name">
                        Photos without locations
                      </div>
                  </div>
                  <div className="level-right">
                      {this.state.searchResultImagesWithoutLocation.length !== 0 &&
                        <div className="level-item">
                          <span className="searchsummary__images-count">
                            <ImagesIcon fill="#666666" size="18"  /> 
                            {this.state.searchResultImagesWithoutLocation.length === 1 ?
                            <span>1 photo</span>
                            :
                            <span>{this.state.searchResultImagesWithoutLocation.length} photos</span>
                            }
                          </span>
                        </div>
                      }
                  </div>
                </div>

                <div className="searchsummary__expanded-row-images columns is-multiline">
                  {this.state.searchResultImagesWithoutLocation.map((image) => {
                    return (
                      <div className={`column is-one-quarter`} key={`image_column_${image.id}`}>
                          <div className="searchsummary__expanded-row-image">
                              <LazyLoadImage 
                                  className={`searchsummary__image searchsummary__image--can-be-enlarged`} 
                                  key={`image_${image.id}`} 
                                  src={image.sources.m} 
                                  alt={this.state.displayTitle} 
                                  onClick={() => this.props.showLargeSearchLocationImages(image, this.state.searchResultImagesWithoutLocation)}
                              />

                              {this.props.lightboxImages.some(lightboxImage => lightboxImage.id === image.id) &&
                                <span className="searchsummary__expandedimages-already-in-lightbox">
                                  <CheckedIcon fill="green" size="12"  />
                                </span>
                              }
                              {!this.props.lightboxImages.some(lightboxImage => lightboxImage.id === image.id) ? 
                                <span className="searchsummary__expandedimages-add-image-to-lightbox"
                                  onClick={() => this.props.imageToLightbox(image)}>
                                  <DownloadImagesIcon fill="white" size="12"  />
                                </span>
                                :
                                <span className="searchsummary__expandedimages-remove-image-from-lightbox"
                                  onClick={() => this.props.removeImageFromLightbox(image)}>
                                  <RemoveFromLightboxIcon fill="red" size="12"  />
                                </span>
                              }
                              {this.state.showEditIcon &&
                                <span className="searchsummary__expandedimages-edit-image"
                                    onClick={() => this.handleImageEditClick(image, this.state.searchResultImagesWithoutLocation)}>
                                    <EditIcon fill="white" size="12"  />
                                </span>
                              }  
                          </div>
                      </div>
                    );
                  })}
                </div>  
              </>
              }  
            </div>
          </div>
        </div>
      </div>
      {this.props.searchLocationImageViewerIsActive &&
        <ImageViewer
          sharedMode={false} 
          images={this.props.searchImageViewerImages} 
          imageViewerIsActive={this.props.searchLocationImageViewerIsActive}
          handleViewerCloseClick={() => this.props.closeViewer()}
          lightboxImages={this.props.lightboxImages}
          imageToLightbox={(image) => this.props.imageToLightbox(image)}
          showLargeImages= {(image) => this.props.showLargeSearchLocationImages(image)}
          imageViewerShowsImagePath={this.props.imageViewerShowsImagePath}
          imageViewerShowsImage={this.props.imageViewerShowsImage}
          removeImageFromLightbox={(image) => this.props.removeImageFromLightbox(image)}
          getLocationForId={(id) => this.props.getLocationForId(id)}
          lightboxCreator={false}
          lightboxName={false}
          user={false}
        />
      }
      {this.props.currentlyEditingImages && this.props.currentlyEditingImages.length !== 0 &&
        <ImageDetail {...this.props} 
          isActive={this.props.detailModalIsActive}
          imageViewerIsActive={this.props.imageViewerIsActive}
          prefix={""}
          locations={this.props.locations}
          referenceData={this.state.referenceData}
          allImages={this.state.editModalAllImages}
          images={this.props.currentlyEditingImages}
          attribute={this.props.currentlyEditingAttribute}
          setCurrentlyEditingAttribute={this.props.setCurrentlyEditingAttribute}
          reloadImages={(imageIds) => this.reloadImages(imageIds)}
          loadReferenceData={() => this.loadReferenceData()}
          loadLocations={() => this.props.loadLocations()}     
          setCurrentlyEditingImages={(images) => this.props.setCurrentlyEditingImages(images)}
          closeDetail={() => this.props.closeDetail()}
          imageToLightbox={(image) => this.props.imageToLightbox(image)}
          manyImagesToLightbox={(images) => this.props.manyImagesToLightbox(images)}
          lightboxImages={this.props.lightboxImages}
          removeImageFromLightbox={(image) => this.props.removeImageFromLightbox(image)}
          handleViewerCloseClick={() => this.props.closeViewer()}
          showLargeImages= {(image) => this.props.showLargeImages(image)}
          imageViewerShowsImagePath={this.props.imageViewerShowsImagePath}
          imageViewerShowsImage={this.props.imageViewerShowsImage}
          getLocationForId={(id) => this.props.getLocationForId(id)}
        />
      }
      {this.state.mapViewerIsActive && 
        <MapViewer
            mapViewerIsActive={this.state.mapViewerIsActive}
            location={this.state.mapViewerShowsLocation}
            allLocations={this.props.locations}
            handleViewerCloseClick={() => this.handleMapViewerCloseClick()}
        />
      } 
    </>
    );
  }

}

export default BrowsePage;