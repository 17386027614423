import React from 'react';

const Attribute = props => {

  if (props.value) {

    if (props.type === 'location') {

      return (
        <span className="tag">{props.value}</span>
      );

    } else {

      let displayValue = '';
      let tagColour = false;
      let tagGroupData = false;

      const tagValue = String(props.value).toLowerCase().replace('_', ' ');
      const classValue = tagValue.replace(' ', '_');


      // If it's a yaml-originated tag
      // FIXME: this data is now kept as discrete columns in the db, string 
      // splitting should no longer be necessary

      if (tagValue.includes(":")) {
        const tagGroup = tagValue.split(":")[0]
        displayValue = tagValue.split(":")[1]

        
        if (typeof props.tagTree?.groups != 'undefined') {
          tagGroupData = props.tagTree.groups.filter((group) => (group.name.toLowerCase().replace('_', ' ') === tagGroup));
        }

        if (typeof tagGroupData[0] != 'undefined') {
          tagColour = (typeof tagGroupData[0].colour != 'undefined') ? tagGroupData[0].colour : false;
        }

      } else {
        displayValue = tagValue;
      }

      const appearanceClass = (props.appearance) ? props.appearance : '';
      const tagStyle = (tagColour) ? { 'backgroundColor': tagColour } : {}
      // console.log(tagStyle)

      if (displayValue === 'na') {
        return (
          <span className={`tag is-info ${appearanceClass} `}>∅</span>
        );
      } else {
        // let colorClass = 'is-success';
        // if (displayValue === 'reshoot' || displayValue === 'shoot more') {
        //   colorClass = 'is-warning';
        // } else if (displayValue === 'delete') {
        //   colorClass = 'is-danger';
        // }
        return (
          <span
            className={`tag 
                        ${appearanceClass} 
                        attribute--type-${props.type} 
                        attribute--value-${classValue}
                      `}
            style={tagStyle}
          >{displayValue}</span>
        );
      }

    }

  } else {
    return (
      <span className="tag is-danger is-light">×</span>
    )
  }
}

const SelectableAttribute = props => {

  let colorClass = (props.checked) ? 'is-success' : 'is-light';

  return (
    <span key={props.key} className={`tag selattr ${colorClass}`}>
      <label className="checkbox selattr__label">
        <input
          className="selattr__checkbox"
          type="checkbox"
          onChange={() => props.handleClick(props.value)}
          checked={props.checked}
        />
        {props.value}
      </label>
    </span>
  );
}

const SelectableAttributeField = props => {

  return (
    <div className="selattr-field">
      <span className="label selattr-field__title">{props.title.toLowerCase()}:</span>
      <div className="tags selattr-field__attributes">

        {props.attributes.map((attr) => {

          const attrKey = attr.replace(' ', '-');
          const tagValue = attr;
          let checked = false;
          if (typeof props.checkedAttributes !== 'undefined' && props.checkedAttributes !== null) {
            if (props.checkedAttributes.some(attr => attr.value)) {
              checked = (props.checkedAttributes.some(attr => attr.value === tagValue)) ? true : false;
            } else {
              checked = (props.checkedAttributes.some(attr => attr === tagValue)) ? true : false;
            }
          }

          return (
            <SelectableAttribute
              key={`ref_${attrKey}`}
              value={attr}
              checked={checked}
              handleClick={(attr) => props.clickHandler(attr, props.groupName)}
            />
          );

        })}

      </div>
    </div>
  )

}

export { Attribute, SelectableAttribute, SelectableAttributeField };