import React from 'react';
import Logo from "../lu-tools-logo.svg";

const NotFoundPage = props => {

  return (
    <div className={`modal is-active `}>
      <div className="modal-background is-dark is-cursor-default"></div>
      <div className="modal-content notfoundpage__container">
        <div className="has-text-centered mb-5">
          <img src={Logo} alt="Logo" width="48" height="48" />
        </div>
        <div className="has-text-centered title is-3 mb-6">
          {"No such thing :("}
        </div>
        <div className="has-text-centered subtitle is-5">
          {"Perhaps this resource has moved, or maybe it never existed in the first place. We might never know."}
        </div>
      </div> 
    </div>
  )
}

export default NotFoundPage;