import React from 'react';
import { MapContainer, useMap, Polyline, Polygon } from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { MinimapLocationMarkerComponent } from "./minimapLocationMarkerComponent"

const MiniMapComponent = ({ 
  mapCenter, 
  mapZoom, 
  coordinates, 
  mapVersion
}) => {

  const iconType = mapVersion === "small" && coordinates.length > 1 ? "dots" : "locuIcon";
  const fillOptions = { fillColor: '#E14A24', color: '#E14A24' }

  const Map = () => {
    const map = useMap();
    setTimeout(() => {
      map.invalidateSize();
      if (mapVersion === "small") {
        map.fitBounds(coordinates, {padding: [10, 10]})
      } else {
        map.fitBounds(coordinates, {padding: [20, 20]})
      }
    }, 100)
    // console.log(map)
    return null;
  };

  return (
    <MapContainer style={{ height: "100%", width: "100%" }} 
      zoom={mapZoom} 
      center={mapCenter}
      scrollWheelZoom={false}
      dragging={false}
      zoomControl={false}
      attributionControl={false}
      boxZoom={false}
      doubleClickZoom={false}
      keyboard={false}
    >
      <Map />
      <ReactLeafletGoogleLayer useGoogMapsLoader={false}/>
        {coordinates && coordinates.length!== 0 &&
          coordinates.map((loc) => {
            const key = loc.lat + loc.lng
            return (
              <MinimapLocationMarkerComponent key={key} location={loc} iconType={iconType}/>
            )
          })
        }
        {coordinates && coordinates.length === 2 && 
          <Polyline pathOptions={fillOptions} positions={coordinates} />
        }
        {coordinates && coordinates.length > 2 && 
          <Polygon pathOptions={fillOptions} positions={coordinates} />
        }
    </MapContainer>
  );
}

export { MiniMapComponent };