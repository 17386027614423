import React, { useState, useEffect, useCallback, useRef } from "react";
import { Switch, Route, Link } from "react-router-dom";
import Logo from "./lu-tools-logo.svg";
import { LightboxIcon } from "./components/icons.js";

import { useAuth0 } from "./contexts/auth0-context";
import LoginGuard from "./layouts/loginGuard";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import FrontPage from "./pages/frontPage";
import BrowsePage from "./pages/browsePage";
import ImageList from "./pages/imageList";
import LocationList from "./pages/locationList";
import LightboxList from "./pages/lightboxList";
import { SharePage } from "./pages/sharePage";

import { Lightbox } from "./components/imageLightbox";
import ImageViewer from "./components/imageViewer";

import { useLocalStorage } from "./hooks/useLocalStorage";
import { useRouteMatch } from "react-router-dom"
import { LoadingComponent } from "./components/loadingComponent";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import { YamlInputComponent } from './components/YamlInputComponent';

function App() {
  const [allLightboxes, setAllLightboxes] = useState([]);
  const [selectedLightbox, setSelectedLightbox] = useState({});
  const [currentLightbox, setCurrentLightbox] = useState({});
  const [
    specificLightboxImageViewerIsActive,
    setSpecificLightboxImageViewerIsActive,
  ] = useState(false);
  const [currentlyEditingLightbox, setCurrentlyEditingLightbox] = useState({});
  const [lightboxDetailModalIsActive, setLightboxDetailModalIsActive] = useState(false);

  const { isLoading, isAuthenticated, user, logout } = useAuth0();
  const [tagTreeYaml, setTagTreeYaml] = useState("");
  const [lightboxImages, setLightboxImages] = useState([]);
  const [currentlyEditingImages, setCurrentlyEditingImages] = useState([]);
  const [currentlyEditingLocation, setCurrentlyEditingLocation] = useState({});
  const [currentlyEditingAttribute, setCurrentlyEditingAttribute] = useState({});
  const [currentlyAddingNewLocation, setCurrentlyAddingNewLocation] = useState(false);
  const [detailModalIsActive, setDetailModalIsActive] = useState(false);
  const [locationDetailModalIsActive, setLocationDetailModalIsActive] = useState(false);
  const [imageViewerIsActive, setImageViewerIsActive] = useState(false);

  const [lightboxImageViewerIsActive, setLightboxImageViewerIsActive] = useState(false);
  const [expandedLocationImageViewerIsActive, setExpandedLocationImageViewerIsActive] = useState(false);
  const [searchLocationImageViewerIsActive, setSearchLocationImageViewerIsActive] = useState(false);
  const [searchImageViewerImages, setSearchImageViewerImages] = useState([]);

  const [imageViewerShowsImage, setImageViewerShowsImage] = useState({});
  const [imageViewerShowsImagePath, setImageViewerShowsImagePath] = useState(
    ""
  );
  const [lightboxSize, setLightboxSize] = useState();
  const [lightboxNr, setLightboxNr] = useState("");
  const [locations, setLocations] = useState([]);

  const [storedLightboxNr, setStoredLightboxNr] = useLocalStorage("storedLightboxNr", "");
  const [sharedLightboxes, setSharedLightboxes] = useState(null);
  const [isShowingSharedPage, setIsShowingSharedPage] = useState(false);
  let isSharedPage = useRouteMatch('/shared/:hash')

  const yamlRef = useRef();

  const openYamlComponent = () => {
    if (yamlRef.current) {
      yamlRef.current.classList.add('is-active');
    }
  }

  const closeYamlComponent = () => {
    if (yamlRef.current) {
      yamlRef.current.classList.remove('is-active');
    }
  }

  const loadTagTreeYaml = useCallback(() => {
    const requestOptions = {
      method: "GET",
    };

    return fetch(
      process.env.REACT_APP_BE_API_HOST + `/api/v1/config/tag-tree`,
      requestOptions
    ).then(async res => {
      if (res.ok) {
        const tagTreeText = await res.text();
        setTagTreeYaml(tagTreeText);
      } else {
        console.error("Failed fetching tag-tree yaml!");
      }
      return res;
    })
      .catch(console.log);
  }, []);

  const saveTagTreeYaml = useCallback((data) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: data
    };

    return fetch(
      process.env.REACT_APP_BE_API_HOST + `/api/v1/config/tag-tree`,
      requestOptions
    ).catch(console.log);
  }, []);

  useEffect(() => {
    loadTagTreeYaml();
  }, [loadTagTreeYaml])

  useEffect(() => {
    if (isSharedPage != null) {
      setIsShowingSharedPage(true);
    }
  }, [isSharedPage]);

  useEffect(() => {
    getAllLightboxes();
    loadLocations();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!lightboxNr && storedLightboxNr && allLightboxes.length !== 0 && allLightboxes.some((lb) => lb.id === storedLightboxNr)) {
      setLightboxNr(storedLightboxNr);
    } else if (!lightboxNr && !storedLightboxNr) {
      initiateFirstUserLightboxIfNoLocalStorage();
    }
    // eslint-disable-next-line
  }, [allLightboxes]);

  useEffect(() => {
    if (Object.keys(currentLightbox).length !== 0 && currentLightbox.constructor === Object) {
      setCurrentLightbox(allLightboxes.find((lb) => lb.id === currentLightbox.id));
    }
    // eslint-disable-next-line
  }, [allLightboxes]);

  useEffect(() => {
    if (Object.keys(selectedLightbox).length !== 0 && selectedLightbox.constructor === Object) {
      setSelectedLightbox(allLightboxes.find((lb) => lb.id === selectedLightbox.id));
    }
    if (Object.keys(selectedLightbox).length !== 0 && selectedLightbox.constructor === Object && Object.keys(imageViewerShowsImage).length !== 0 && imageViewerShowsImage.constructor === Object) {
      setImageViewerShowsImage(allLightboxes.find((lb) => lb.id === selectedLightbox.id).images.find((im) => im.id === imageViewerShowsImage.id));
      setImageViewerShowsImagePath(allLightboxes.find((lb) => lb.id === selectedLightbox.id).images.find((im) => im.id === imageViewerShowsImage.id).sources.l);
    }
    // eslint-disable-next-line
  }, [allLightboxes]);

  useEffect(() => {
    if (lightboxNr && lightboxNr !== storedLightboxNr) {
      setStoredLightboxNr(lightboxNr);
    }
    // eslint-disable-next-line
  }, [lightboxNr]);

  useEffect(() => {
    if (lightboxNr) {
      loadLightbox();
    }
    // eslint-disable-next-line
  }, [lightboxNr]);

  useEffect(() => {
    setLightboxSize(
      lightboxImages.length !== 0 && lightboxImages.length > 2
        ? ""
        : lightboxImages.length === 2
          ? "--two"
          : "--one"
    );
    // console.log(lightboxSize)
    // console.log(lightboxImages)
  }, [lightboxImages]);

  useEffect(() => {
    const selector = document.querySelector('html');
    if (detailModalIsActive) {
      window.addEventListener("keydown", preventDefaultForScrollKeys, false);
      selector.style.overflow = 'hidden';
    }
    return () => {
      window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
      selector.style.overflow = 'visible';
    }
  }, [detailModalIsActive]);

  function getAllLightboxes() {

    const requestOptions = {
      method: "GET",
    };

    fetch(process.env.REACT_APP_BE_API_HOST + "/api/lightboxes/all", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data && data.length !== 0) {
          const sortedLightboxes = data.sort(function (a, b) {
            var textA = a.name.toUpperCase();
            var textB = b.name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
          setAllLightboxes(sortedLightboxes);
          const sharedLightboxes = sortedLightboxes.filter((lb) => lb.shareKey)
          setSharedLightboxes(sharedLightboxes);
        }
      })
      .catch(console.log);
  }

  function initiateFirstUserLightboxIfNoLocalStorage() {

    if (!lightboxNr) {

      const requestOptions = {
        method: "GET",
      };

      fetch(process.env.REACT_APP_BE_API_HOST + "/api/lightboxes/all", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          // console.log(data)
          if (data.length === 0) {
            // console.log("User does not have a lightbox")
          } else {
            // console.log(data)
            setLightboxNr(data[0].id);
          }
        })
        .catch(console.log);
    }
  }

  function loadLightbox() {
    const requestOptions = {
      method: "GET",
    };

    const lightboxNrStr = lightboxNr.toString();

    fetch(
      process.env.REACT_APP_BE_API_HOST + `/api/lightboxes/${lightboxNrStr}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data?.images) {
          setLightboxImages(data.images);
          setCurrentLightbox(data);// 
        }
      })
      .catch(console.log);
  }

  function handleCurrentLightboxChange(id) {
    let selectedLightboxIndex = allLightboxes.findIndex((lb) => lb.id === id);
    // console.log(selectedLightboxId)

    setCurrentLightbox(allLightboxes[selectedLightboxIndex]);
    setLightboxImages(allLightboxes[selectedLightboxIndex].images);
    setLightboxNr(id);
  }

  function loadLocations() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_BE_API_HOST + "/api/location/list",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setLocations(data["locations"]);
      })
      .catch(console.log);
  }

  function editLocation(location) {
    setLocationDetailModalIsActive(true);
    setCurrentlyEditingLocation(location)
    setCurrentlyAddingNewLocation(false);
  }

  function closeDetail() {
    setLocationDetailModalIsActive(false);
    setCurrentlyEditingLocation(false)
    setCurrentlyAddingNewLocation(false);
    setDetailModalIsActive(false);
  }

  function showLargeSharedImages(image) {
    setImageViewerShowsImagePath(image.sources.l);
    setImageViewerShowsImage(image);
  }

  function showLargeLightboxImages(image) {
    setLightboxImageViewerIsActive(true);
    setImageViewerShowsImagePath(image.sources.l);
    setImageViewerShowsImage(image);
  }

  function showLargeLightboxImagesById(id) {

    let selectedLightboxId = allLightboxes.findIndex((lb) => lb.id === id)

    if (allLightboxes[selectedLightboxId].images.length !== 0) {
      setSelectedLightbox(allLightboxes[selectedLightboxId]);
      setSpecificLightboxImageViewerIsActive(true);
      setImageViewerShowsImagePath(allLightboxes[selectedLightboxId].images[0].sources.l);
      setImageViewerShowsImage(allLightboxes[selectedLightboxId].images[0]);
    }
  }

  function showLargeSpecificLightboxImages(image) {
    setImageViewerShowsImagePath(image.sources.l);
    setImageViewerShowsImage(image);
  }

  function showLargeImages(image) {
    setImageViewerIsActive(true);
    setImageViewerShowsImage(image);
    setImageViewerShowsImagePath(image.sources.l);
  }

  function showLargeExpandedLocationImages(image) {
    // console.log(image)
    setExpandedLocationImageViewerIsActive(true);
    setImageViewerShowsImage(image);
    setImageViewerShowsImagePath(image.sources.l);
  }

  function showLargeSearchLocationImages(image, images) {
    if (images) {
      setSearchImageViewerImages(images);
    }
    setSearchLocationImageViewerIsActive(true);
    setImageViewerShowsImage(image);
    setImageViewerShowsImagePath(image.sources.l);
  }

  function closeViewer() {
    console.log("closeViewer called");
    setImageViewerIsActive(false);
    setLightboxImageViewerIsActive(false);
    setExpandedLocationImageViewerIsActive(false);
    setSearchLocationImageViewerIsActive(false);
    setSpecificLightboxImageViewerIsActive(false);
    setImageViewerShowsImagePath("");
  }

  function imageToLightbox(image) {

    let isNewProperLightboxNameProvided = false;

    if (!lightboxNr) {

      isNewProperLightboxNameProvided = promptForNewLightboxName();

      if (!isNewProperLightboxNameProvided) {
        console.log("lightbox not created!");
        return;
      }
    }

    const imagesToLightbox = [...lightboxImages];

    if (imagesToLightbox.some((im) => im.id === image.id)) {
      console.log("already in lightbox");
    } else {
      imagesToLightbox.push(image);
      setLightboxImages(imagesToLightbox);
      uploadNewImageToDatabaseLightbox(image, isNewProperLightboxNameProvided);
    }


  }

  async function manyImagesToLightbox(images) {

    let isNewProperLightboxNameProvided = false;

    if (!lightboxNr) {

      isNewProperLightboxNameProvided = promptForNewLightboxName();

      if (!isNewProperLightboxNameProvided) {
        console.log("lightbox not created!");
        return;
      }

    }

    const imagesToLightbox = [...lightboxImages];
    const newImagesToUpload = [];

    for (const image of images) {
      if (imagesToLightbox.some((im) => im.id === image.id)) {
        // console.log("already in lightbox")
      } else {
        imagesToLightbox.push(image);
        setLightboxImages(imagesToLightbox);
        newImagesToUpload.push(image);
      }
    }

    if (newImagesToUpload.length !== 0) {
      uploadNewImageToDatabaseLightbox(newImagesToUpload, isNewProperLightboxNameProvided);
    }
  }

  function promptForNewLightboxName() {
    let newLightboxName = prompt(
      "Please provide a name for your new lightbox",
      ""
    );

    if (!newLightboxName || newLightboxName.length < 2) {

      alert("Lightbox name has to be at least 2 characters long!");
      return false;

    } else if (allLightboxes.length !== 0 && allLightboxes.some((lb) => lb.name === newLightboxName)) {

      alert('A lightbox with the name \'' + newLightboxName + '\' already exists!');
      return false;

    } else {
      return newLightboxName;
    }
  }


  async function uploadNewImageToDatabaseLightbox(image, isNewProperLightboxNameProvided) {

    // console.log(image);

    const newLightboxWasCreated = await createNewDatabaseLightboxWhenAddingImageToLb(isNewProperLightboxNameProvided);
    const lightboxNrStr = (await newLightboxWasCreated) ? newLightboxWasCreated : lightboxNr;

    let imageIds = [];

    if (Array.isArray(image)) {
      image.forEach((im) => {
        imageIds.push(im.id);
      });
    } else {
      imageIds.push(image.id);
    }
    const requestOptions = {
      method: "POST",
      headers: {
        User: user["http://example.com/uuid"],
        "Content-Type": "application/json",
      },
      body: JSON.stringify(imageIds),
    };

    fetch(
      process.env.REACT_APP_BE_API_HOST + `/api/lightboxes/${lightboxNrStr}`,
      requestOptions
    )
      .then(() => {
        console.log("adding to lightbox");
        getAllLightboxes();
        loadLightbox();
      })
      .catch((error) => console.log(error));

  }

  function createNewDatabaseLightboxWhenAddingImageToLb(isNewProperLightboxNameProvided) {
    if (!lightboxNr && isNewProperLightboxNameProvided) {

      const lightboxInfo = {
        name: isNewProperLightboxNameProvided,
        createdBy: user.name,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          User: user["http://example.com/uuid"],
          "Content-Type": "application/json",
        },
        body: JSON.stringify(lightboxInfo),
      };

      return fetch(
        process.env.REACT_APP_BE_API_HOST + "/api/lightboxes",
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          // console.log(data)
          setLightboxNr(data.id);
          return data.id;
        })
        .catch(console.log);

    } else {
      return false;
    }
  }

  function createNewLightbox(newLightboxName) {

    const lightboxInfo = {
      name: newLightboxName,
      createdBy: user.name,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        User: user["http://example.com/uuid"],
        "Content-Type": "application/json",
      },
      body: JSON.stringify(lightboxInfo),
    };

    fetch(process.env.REACT_APP_BE_API_HOST + "/api/lightboxes", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        getAllLightboxes();
      })
      .catch(console.log);
  }

  function removeImageFromLightbox(image) {
    const updateImagesInLightBox = lightboxImages.filter(
      (im) => im.id !== image.id
    );
    setLightboxImages(updateImagesInLightBox);
    removeImageFromDatabaseLightbox(image);

    if (lightboxImageViewerIsActive) {
      const currentImageIndex = lightboxImages.findIndex(
        (im) => im.id === image.id
      );
      if (currentImageIndex > 0) {
        setImageViewerShowsImage(lightboxImages[currentImageIndex - 1]);
        setImageViewerShowsImagePath(
          lightboxImages[currentImageIndex - 1].sources.l
        );
      } else if (currentImageIndex === 0 && lightboxImages.length > 1) {
        setImageViewerShowsImage(lightboxImages[currentImageIndex + 1]);
        setImageViewerShowsImagePath(
          lightboxImages[currentImageIndex + 1].sources.l
        );
      } else {
        closeViewer();
      }
    }
  }

  function removeImageFromDatabaseLightbox(image) {
    const lightboxNrStr = lightboxNr.toString();

    const requestOptions = {
      method: "DELETE",
      headers: {
        User: user["http://example.com/uuid"],
        "Content-Type": "application/json",
      },
      body: JSON.stringify([image.id]),
    };

    fetch(
      process.env.REACT_APP_BE_API_HOST + `/api/lightboxes/${lightboxNrStr}`,
      requestOptions
    )
      .then(() => {
        console.log("removing image from lightbox");
        getAllLightboxes();
        loadLightbox();
      })
      .catch((error) => console.log(error));
  }

  function getLocationForId(id) {
    // console.log(id);
    const indexInState = locations.findIndex((location) => location.id === id);
    return locations[indexInState];
  }

  function removeAllImagesFromDatabaseLightbox() {
    const lightboxNrStr = lightboxNr.toString();

    confirmAlert({
      // title: 'Confirm to submit',
      message:
        "Are you sure that you wish to empty your lightbox? This cannot be undone.",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            setLightboxImages([]);

            const requestOptions = {
              method: "DELETE",
              headers: {
                User: user["http://example.com/uuid"],
                "Content-Type": "application/json",
              },
            };
            fetch(
              process.env.REACT_APP_BE_API_HOST +
              `/api/lightboxes/${lightboxNrStr}/all`,
              requestOptions
            )
              .then(() => {
                console.log("removing all images from lightbox");
                getAllLightboxes();
              })
              .catch((error) => console.log(error));

            alert("Images removed");
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  }

  function preventDefaultForScrollKeys(e) {
    let keys = [37, 38, 39, 40];
    if (keys.includes(e.keyCode)) {
      e.preventDefault();
      return false;
    }
  }

  function handleEditLightboxClick(lightbox) {
    setLightboxDetailModalIsActive(true);
    setCurrentlyEditingLightbox(lightbox);
  }

  function closeLightboxModal() {
    setLightboxDetailModalIsActive(false);
    setCurrentlyEditingLightbox({});
  }

  function downloadImages(lightbox) {

    const date = new Date()
    let dateString = date.getFullYear() + '-' + ((date.getMonth() < 10 ? '0' : '') + (date.getMonth() + 1)) + '-' + ((date.getDate() < 10 ? '0' : '') + date.getDate());
    const collectionName = 'lightbox_' + lightbox.name + '_' + dateString
    const zip = new JSZip();
    const folder = zip.folder(collectionName);
    const nrOfLightboxImages = lightbox.images.length;
    let currentCount = 0;

    lightbox.images.map(async (image) => {

      const imageUrl = image.sources.l;
      const imageLocation = image.locationId ? locations.find((loc) => loc.id === image.locationId).name : '';

      // Fetch the image and parse the response stream as a blob
      const result = await fetch(imageUrl);
      const imageBlob = await result.blob();

      if (imageLocation.length !== 0) {
        folder.file(`${imageLocation}__${image.path}`, imageBlob);
      } else {
        folder.file(`${image.path}`, imageBlob);
      }

      currentCount = currentCount + 1;

      if (currentCount === nrOfLightboxImages) {
        console.log("downloading images");
        zip.generateAsync({ type: "blob" })
          .then(content => {
            saveAs(content, collectionName);
          })
      }
    });
  }

  const imageListProps = {
    user,
    imageViewerIsActive,
    lightboxImageViewerIsActive,
    imageViewerShowsImage,
    imageViewerShowsImagePath,
    lightboxImages,
    lightboxNr,
    locations,
    removeImageFromLightbox,
    getLocationForId,
    imageToLightbox,
    manyImagesToLightbox,
    closeViewer,
    showLargeImages,
    currentlyEditingImages,
    setCurrentlyEditingImages,
    detailModalIsActive,
    setDetailModalIsActive,
    loadLocations,
    closeDetail,
    currentlyEditingAttribute,
    setCurrentlyEditingAttribute,
    tagTreeYaml
  };

  const locationListProps = {
    lightboxImages,
    removeImageFromLightbox,
    imageToLightbox,
    manyImagesToLightbox,
    expandedLocationImageViewerIsActive,
    closeViewer,
    showLargeExpandedLocationImages,
    imageViewerShowsImage,
    imageViewerShowsImagePath,
    currentlyEditingLocation,
    currentlyAddingNewLocation,
    setCurrentlyAddingNewLocation,
    locationDetailModalIsActive,
    setLocationDetailModalIsActive,
    editLocation,
    closeDetail,
    setDetailModalIsActive,
    detailModalIsActive,
    currentlyEditingImages,
    setCurrentlyEditingImages,
    currentlyEditingAttribute,
    setCurrentlyEditingAttribute,
    getLocationForId,
    showLargeImages,
    imageViewerIsActive,
    loadLocations,
    tagTreeYaml
  };

  const browsePageListProps = {
    lightboxImages,
    removeImageFromLightbox,
    imageToLightbox,
    manyImagesToLightbox,
    closeViewer,
    imageViewerShowsImage,
    imageViewerShowsImagePath,
    allLightboxes,
    currentLightbox,
    user,
    showLargeLightboxImagesById,
    selectedLightbox,
    handleCurrentLightboxChange,
    showLargeSpecificLightboxImages,
    createNewLightbox,
    locations,
    setLocations,
    editLocation,
    searchLocationImageViewerIsActive,
    showLargeSearchLocationImages,
    getLocationForId,
    searchImageViewerImages,
    setSearchImageViewerImages,
    loadLocations,

    showLargeImages,
    currentlyEditingImages,
    setCurrentlyEditingImages,
    setDetailModalIsActive,
    closeDetail,
    currentlyEditingAttribute,
    setCurrentlyEditingAttribute,
    detailModalIsActive,
    imageViewerIsActive,
    tagTreeYaml
  };

  const lightboxListProps = {
    lightboxImages,
    removeImageFromLightbox,
    imageToLightbox,
    closeViewer,
    imageViewerShowsImage,
    imageViewerShowsImagePath,
    allLightboxes,
    currentLightbox,
    user,
    specificLightboxImageViewerIsActive,
    showLargeLightboxImagesById,
    selectedLightbox,
    getLocationForId,
    handleCurrentLightboxChange,
    showLargeSpecificLightboxImages,
    createNewLightbox,
    currentlyEditingLightbox,
    handleEditLightboxClick,
    lightboxDetailModalIsActive,
    closeLightboxModal,
    getAllLightboxes,
    downloadImages,
    loadLightbox,
    tagTreeYaml
  };

  const sharedPageProps = {
    sharedLightboxes,
    isSharedPage,
    showLargeSharedImages,
    closeViewer,
    imageToLightbox,
    removeImageFromLightbox,
    imageViewerShowsImage,
    imageViewerShowsImagePath,
    getLocationForId,
    user,
    tagTreeYaml
  }

  return (
    <div className="App">
      {isShowingSharedPage &&
        <>
          {sharedLightboxes != null ?
            <SharePage
              {...sharedPageProps}
            />
            :
            <LoadingComponent />
          }
        </>
      }
      {isLoading && !isShowingSharedPage && <LoginGuard redirect={false} />}
      {!isLoading && !isAuthenticated && !isShowingSharedPage &&
        <LoginGuard redirect={true} />}

      {!isLoading && isAuthenticated && user && !isShowingSharedPage && (
        <>
          <header className="section header">
            <div className="container is-fluid">
              <div className="columns">
                <div className="column is-3">
                  <div className="level">

                    <div className="level-left">
                      <div className="level-item">
                        <img src={Logo} alt="Logo" width="50" height="50" />
                      </div>
                      <div className="level-item">
                        <div>
                          <h1 className="header__appname title is-5">
                            {process.env.REACT_APP_NAME}
                          </h1>
                          <nav
                            className="header__breadcrumbs breadcrumb"
                            aria-label="breadcrumbs"
                          >
                            <ul>
                              <li>
                                <Link to="/">Home</Link>
                              </li>
                              <Route path="/images">
                                <li className="is-active">
                                  <a href="/#" aria-current="page">
                                    Enrich images
                                  </a>
                                </li>
                              </Route>
                              <Route path="/locations">
                                <li className="is-active">
                                  <a href="/#" aria-current="page">
                                    Manage locations
                                  </a>
                                </li>
                              </Route>
                              <Route path="/browse">
                                <li className="is-active">
                                  <a href="/#" aria-current="page">
                                    Browse images
                                  </a>
                                </li>
                              </Route>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="column">
                  <div className="level is-mobile header__right-side-level">
                    <div className="level-left">
                      <div className="columns is-variable is-1">
                        <div className="column is-narrow">
                          <span className="">
                            <LightboxIcon fill="#black" size="14" />
                          </span>
                        </div>
                        <div className="column is-narrow">
                          <span>
                            {Object.keys(currentLightbox).length === 0 &&
                              currentLightbox.constructor === Object ? (
                              <span className="header__lightbox--title-nolightbox title is-5">
                                No lightbox
                              </span>
                            ) : (
                              <span className="header__lightbox--title title is-5">
                                {currentLightbox.name}
                              </span>
                            )}
                            <nav
                              className="header__breadcrumbs breadcrumb"
                              aria-label="breadcrumbs"
                            >
                              <ul>
                                {Object.keys(currentLightbox).length === 0 &&
                                  currentLightbox.constructor === Object ? (
                                  <li>
                                    <Link to="/lightboxes">Create</Link>
                                  </li>
                                ) : (
                                  <li>
                                    <Link to="/lightboxes">Switch</Link>
                                  </li>
                                )}
                              </ul>
                            </nav>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="level-right">
                      <h5 className="is-7 level-item">{user.name}</h5>
                      <button
                        className="button level-item is-small is-link is-outlined"
                        onClick={() => logout({ returnTo: window.location.origin })}
                      >
                        Log out
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>












          <Switch>

            <Route
              // path="/images/:prefix" 
              path={["/images/:prefix/edit/:imageIds/:attributeType", "/images/:prefix"]}
              render={(props) =>
                <ImageList
                  {...props}
                  {...imageListProps}
                />}
            />
            <Route
              path="/locations"
              render={(props) =>
                <LocationList
                  {...props}
                  {...locationListProps}
                />}
            />
            <Route
              path="/browse"
              render={(props) =>
                <BrowsePage
                  {...props}
                  {...browsePageListProps}
                />}
            />

            <Route
              path="/lightboxes"
              render={(props) => (
                <LightboxList {...props} {...lightboxListProps} />
              )}
            />

            <Route path="/">
              <FrontPage
                openYamlComponent={openYamlComponent}
              />
            </Route>

          </Switch>


          {lightboxImages.length !== 0 && (
            <div className={`lightbox__container lightbox__container${lightboxSize}`}>
              <Lightbox
                lightbox={currentLightbox}
                lightboxImages={lightboxImages}
                removeImageFromLightbox={(image) => removeImageFromLightbox(image)}
                showLargeLightboxImages={(image) => showLargeLightboxImages(image)}
                removeAllImagesFromDatabaseLightbox={() => removeAllImagesFromDatabaseLightbox()}
                downloadImages={(lightbox) => downloadImages(lightbox)}
              />
            </div>
          )}

          {lightboxImageViewerIsActive && (
            <ImageViewer
              sharedMode={false}
              showLargeImages={(image) => showLargeImages(image)}
              images={lightboxImages}
              imageViewerIsActive={lightboxImageViewerIsActive}
              handleViewerCloseClick={() => closeViewer()}
              lightboxImages={lightboxImages}
              imageToLightbox={(image) => imageToLightbox(image)}
              imageViewerShowsImagePath={imageViewerShowsImagePath}
              imageViewerShowsImage={imageViewerShowsImage}
              removeImageFromLightbox={(image) =>
                removeImageFromLightbox(image)
              }
              getLocationForId={(id) => getLocationForId(id)}
              lightboxCreator={currentLightbox.createdBy}
              lightboxName={currentLightbox.name}
              user={user}
            />
          )}
        </>
      )}
      <YamlInputComponent
        yamlRef={yamlRef}
        tagTreeYaml={tagTreeYaml}
        closeYamlComponent={closeYamlComponent}
        handleSubmit={data => {
          saveTagTreeYaml(data).then(loadTagTreeYaml).then(closeYamlComponent);
        }}
      />
    </div>
  );
}

export default App;
