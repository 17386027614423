import React from 'react';
import CreatableSelect from 'react-select/creatable';

const LocationSelector = props => {

  const currentValue = (props.attachedLocation.id) ? props.attachedLocation.id : '';

  let options = [];
  props.locations.forEach((loc) => {
    options.push({ 
      value: loc.id,
      label: loc.name
    });
  });
  const selectStyles = {
    menuList: (provided, state) => ({
      ...provided,
      height: 200,
    }),
  }
  // console.log(options);

  return (
    <div className="columns">
      <div className="column is-full">

        <div className="field">
          <div className="control locationselector">

            <CreatableSelect 
              className="locationselector__select"
              options={options} 
              onChange={(event) => props.handleLocationSaveClick(event)}
              placeholder="Select a location"
              value={options.filter(option => option.value === currentValue)}
              styles={selectStyles}
            />

            {/* <div className="select">


              <select 
                value={currentValue}
                onChange={(event) => props.handleLocationSaveClick(event.target.value)}
              >
                <option default onSelect={() => props.handleLocationSaveClick()} value=""
                >Select a location</option>

                {props.locations.map((location) => {
                  if (props.hasDefinedLocation) {
                    if (props.attachedLocation.id === location.id) {
                      console.log('location ' + location.id);
                    }
                  }
                  return (
                    <option 
                      key={`loc_${location.id}`} 
                      value={location.id}
                    >{location.name}</option>
                  );
                })}

              </select>

            </div> */}
          </div>
        </div>
      </div>
      {/* <div className="column is-narrow">
        <button className="button is-light" onClick={() => props.handleSkipBackClick()}>← Skip</button>
      </div>
      <div className="column is-narrow">
        <button className="button is-light" onClick={() => props.handleSkipClick()}>Skip →</button>
      </div> */}
    </div>
  );
}

export default LocationSelector;