import React from 'react';
import {  PhoneIcon, EmailIcon, ContactPersonIcon, LocationNotesIcon, ImagesIcon } from './icons.js';

const ContactDetails = props => {

    let displayProps = [];
    if (props.phone) displayProps.push(props.phone);
    if (props.email) displayProps.push(props.email);

    return (
        <>
            {!props.isRowExpanded &&
                <div className="contactdetails">

                    {props.name && (
                        <span className="contactdetails__fragment--name">{props.name}</span>
                    )}
                    {props.phone && (
                        <span className="contactdetails__fragment contactdetails__fragment--phone">{props.phone}</span>
                    )}
                    {props.email && (
                        <a href={`mailto:${props.email}`} className="contactdetails__fragment contactdetails__fragment--email">{props.email}</a>
                    )}
                    {props.notes && (
                        <span className="contactdetails__fragment contactdetails__fragment--notes"><LocationNotesIcon fill="#b5b5b5" size="15"  /></span>
                    )}
                    <span className="contactdetails__fragment contactdetails__fragment--imageNr">
                        <ImagesIcon fill="#b5b5b5" size="15"  /> 
                        {props.imageNrInLocation === 1 ?
                        <span className="ml-1">1 photo</span>
                        :
                        <span className="ml-1">{props.imageNrInLocation} photos</span>
                        }
                    </span>
                </div>
            }

            {props.isRowExpanded &&
                <div className="contactdetails--expanded">
                    {props.name &&
                        <div>
                            <ContactPersonIcon fill="black" size="18"  /> 
                            <span className="contactdetails__fragment contactdetails__fragment--name--expanded">{props.name}</span>
                        </div>
                    }

                    {props.phone &&
                        <div>
                            <PhoneIcon fill="black" size="18"  /> 
                            <span className="contactdetails__fragment contactdetails__fragment--phone--expanded">{props.phone}</span>
                        </div>
                    }
                    
                    {props.email &&
                        <div>
                            <EmailIcon fill="black" size="18"  /> 
                            <a href={`mailto:${props.email}`} className="contactdetails__fragment contactdetails__fragment--email--expanded">{props.email}</a>
                        </div>
                    }
                </div>
            }
        </>
    );
}

export default ContactDetails;