import React from "react";
import { TagSelectFormCheckboxes } from "./TagSelectFormCheckboxes";
import { TagSelectFormRadioButtons } from "./TagSelectFormRadioButtons";
import { Capitalize } from "../utils/capitalize";
import { EditIcon } from './icons.js';
// import { SelectedTags } from "./SelectedTags"



const TagSelectionForm = ({
    currentlySelectingTagGroup,
    currentlySelectingTags,
    setCurrentlySelectingTagGroup,
    handleStartSavingTags,
    // temporaryTagSelectionFormSelectedTags,
    // setTemporaryTagFormSelectedTags,
    // temporarySelectionHistory,
    // setTemporarySelectionHistory,
    savedTagSelectionHistory,
    showNextImageDetail,
    handleJumpToTagTreeStep,
    innerRef
}) => {

    // console.log('currentlySelectingTagGroup: ', currentlySelectingTagGroup);
    // console.log('currentlySelectingTags: ', currentlySelectingTags);
    // console.log(temporaryTagSelectionFormSelectedTags)
    // // console.log(setTemporaryTagFormSelectedTags)
    // console.log(temporarySelectionHistory)
    let currentStep = null;
    const visibleTagTreeSteps = [];
    for(const historyStep of savedTagSelectionHistory){
        if(historyStep.group?.name === currentlySelectingTagGroup?.name) {
            currentStep = historyStep;
        }
        visibleTagTreeSteps.push(historyStep);
    }
    if(!currentStep && currentlySelectingTagGroup?.name) {
      visibleTagTreeSteps.push({ group: { name: currentlySelectingTagGroup?.name }});
    }
    return (
        <div>
            <>
                {visibleTagTreeSteps.map((historySelection, index) => {
                  if(historySelection.group?.name === currentlySelectingTagGroup?.name) {
                    return (
                      <div className="tag-selection-container" key={currentlySelectingTagGroup?.name}>
                         <div style={{ margin: "5px 0" }} key={currentlySelectingTagGroup?.name}>
                          {index + 1}. <b>{currentlySelectingTagGroup?.name}</b>:{' '}
                        </div>
                        <div className="tag-selection-container__form">
                          {currentlySelectingTagGroup?.allow === "exactly-one" && currentlySelectingTags.length > 0 &&
                            <>
                              <h4><b>Pick exactly one: </b></h4>
                              < TagSelectFormRadioButtons
                                ref={innerRef}
                                options={currentlySelectingTags}
                                // selectedTagOption={selectedTagOptions[0]}
                                tagGroup={currentlySelectingTagGroup}
                                setCurrentlySelectingTagGroup={setCurrentlySelectingTagGroup}
                                handleStartSavingTags={handleStartSavingTags}
                                // temporaryTagSelectionFormSelectedTags={temporaryTagSelectionFormSelectedTags}
                                // setTemporaryTagFormSelectedTags={setTemporaryTagFormSelectedTags}
                                // temporarySelectionHistory={temporarySelectionHistory}
                                // setTemporarySelectionHistory={setTemporarySelectionHistory}
                                savedTagSelectionHistory={savedTagSelectionHistory}
                                showNextImageDetail={showNextImageDetail}
                              />
                            </>
                          }
                          {(currentlySelectingTagGroup?.allow === "zero-or-more" || currentlySelectingTagGroup?.allow === "one-or-more") && currentlySelectingTags.length > 0 &&
                            <>
                              <h4><b>{currentlySelectingTagGroup.allow === "zero-or-more" ? "Pick zero or more: " : "Pick one or more: "}</b></h4>
                              < TagSelectFormCheckboxes
                                  ref={innerRef}
                                  options={currentlySelectingTags}
                                  // selectedTagOptions={selectedTagOptions}
                                  tagGroup={currentlySelectingTagGroup}
                                  setCurrentlySelectingTagGroup={setCurrentlySelectingTagGroup}
                                  handleStartSavingTags={handleStartSavingTags}
                                  // temporaryTagSelectionFormSelectedTags={temporaryTagSelectionFormSelectedTags}
                                  // setTemporaryTagFormSelectedTags={setTemporaryTagFormSelectedTags}
                                  // temporarySelectionHistory={temporarySelectionHistory}
                                  // setTemporarySelectionHistory={setTemporarySelectionHistory}
                                  savedTagSelectionHistory={savedTagSelectionHistory}
                                  showNextImageDetail={showNextImageDetail}

                              />
                            </>
                          }
                        </div>
                      </div>
                    )
                  } else {
                    return (
                      <div style={{ margin: "5px 0" }} key={historySelection.group.name}>
                      {index + 1}. <b>{historySelection.group.name}</b>:{' '}
                      <span
                                              style={{ position: "relative", top: "2px", cursor: "pointer" }}
                                              onClick={() => handleJumpToTagTreeStep(historySelection.group.name)}
                                            >
                                              <EditIcon fill="green" size="12" />
                                            </span>
                                            <div>
                                              {historySelection.selectedTags.length === 0 &&
                                                <span>
                                                  -
                                                </span>
                                              }
                                              <span>
                                                {historySelection.selectedTags.slice(-3).map((tag, i, array) => {
                                                  return (
                                                    <span key={tag}>
                                                      {Capitalize(tag)}
                                                      {(historySelection.selectedTags.length > array.length &&
                                                        i === array.length - 1) ?
                                                        ", ..." :
                                                        (i !== array.length - 1) ?
                                                          ", " :
                                                          ""}
                                                    </span>
                                                  )
                                                })}
                                              </span>
                                              <span>
                                              </span>
                                            </div>
                                          </div>
                                        )
                  }
                    
                                    })
                                    }
                                  </>
        </div>
    )
}

export default TagSelectionForm;