import React, {useState, useEffect} from 'react';
import { LocationIcon, LinkToggleOnIcon, LinkToggleOffIcon } from './icons.js';
import useLockBodyScroll from '../hooks/use-lock-body-scroll';
import { LargeMapComponent } from './largeMapComponent.js';

const MapViewer = props => {

  useLockBodyScroll();
  const [mapLocations, setMapLocations] = useState([props.location]);
  const [showAllLocations, setShowAllLocations] = useState(false);
  const [showLandmarks, setShowLandmarks] = useState(false);

  useEffect(() => {
    if (showAllLocations) {
      setMapLocations(props.allLocations)
    } else {
      setMapLocations([props.location])
    }
    // eslint-disable-next-line
  },[showAllLocations])

  const modalClass = (props.mapViewerIsActive) ? 'is-active' : '';

  return (

    <div className={`modal imageviewer ${modalClass}`}>
      <div className={`modal-background is-dark`} onClick={() => props.handleViewerCloseClick()}></div>
      <div className="modal-content largemap">

        <div className="level modal-title-largemap">
          <div className="level-left">
            {props.location?.name &&
            <>
              <div className="level-item mb-1 mr-1">
                <LocationIcon fill="#FFF" size="20" />
              </div>
              <h3 className="imageviewer__lb-title level-item">
                {props.location.name}
              </h3>
            </>
            }
          </div>
        </div>
      
        <div className="modal-content__mapBox">
          <LargeMapComponent 
            locations={mapLocations} 
            showAllLocations={showAllLocations}
            showLandmarks={showLandmarks}
          />
        </div>

        <div className="level modal-toggle-buttons-row pt-1">
          <div className="level-left">
          
          {!showAllLocations ? 
            <div className="mapviewer__clickable level-item px-1 mr-1" onClick={() => setShowAllLocations(!showAllLocations)}>
              <span >
                <LinkToggleOffIcon fill="#FFF" size="20" />
              </span>
              <h3 className="imageviewer__lb-title level-item ml-2">
                Show all locations
              </h3>
            </div>
          : 
            <div className="mapviewer__clickable level-item px-1 mr-1" onClick={() => setShowAllLocations(!showAllLocations)}>
              <span >
                <LinkToggleOnIcon fill="#FFF" size="20" />
              </span>
              <h3 className="imageviewer__lb-title level-item ml-2">
              Show all locations
              </h3>
            </div>
          }

          {!showLandmarks ? 
            <div className="mapviewer__clickable level-item px-1 mr-1 ml-6" onClick={() => setShowLandmarks(!showLandmarks)}>
              <span >
                <LinkToggleOffIcon fill="#FFF" size="20" />
              </span>
              <h3 className="imageviewer__lb-title level-item ml-2">
                Show Google Maps Landmarks
              </h3>
            </div>
          : 
            <div className="mapviewer__clickable level-item px-1 mr-1 ml-6"  onClick={() => setShowLandmarks(!showLandmarks)}>
              <span >
                <LinkToggleOnIcon fill="#FFF" size="20" />
              </span>
              <h3 className="imageviewer__lb-title level-item ml-2">
                Show Google Maps Landmarks
              </h3>
            </div>
          }
          </div>
        </div>
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={() => props.handleViewerCloseClick()}></button>
    </div>
  )
}

export default MapViewer;