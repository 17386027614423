import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DownloadImagesIcon, ClearAllImagesFromLightboxIcon } from './icons.js';

const Lightbox = props => {
  const lightbox = props.lightbox;
  const lightboxImages = props.lightboxImages;
  const clearAllButtonPosition = props.lightboxImages.length > 1 ? '--normal' : '--hidden'
  const lightboxSize = lightboxImages.length !== 0 && lightboxImages.length > 2 ? "" : (lightboxImages.length === 2 ? "--two" : "--one")

  return (
    <>
      <div className="lightbox__list">
        {lightboxImages.slice(0).reverse().map((lbImage) => {
          return (
              <div className="lightbox__list-item" key={lbImage.id}>
                <LazyLoadImage 
                  className="lightbox__list-image" 
                  src={lbImage.sources.s} 
                  alt={lbImage.path}
                  onClick={() => props.showLargeLightboxImages(lbImage)}
                />
              </div>            
          );
        })}
      </div>
      <div className="lightbox__list-item-amount-container">
        <div className={`lightbox__list-item-amount lightbox__list-item-amount${lightboxSize}`}>
          {props.lightboxImages.length}
        </div>
      </div>
      <div className="lightbox__download" onClick={() => props.downloadImages(lightbox)} >
        <span className="lightbox__download-images-icon"><DownloadImagesIcon fill="white" size="14"  /></span>
        <span className="lightbox__download-images">
             Download all
        </span> 
      </div>
      <div className={`lightbox__clear lightbox__clear${clearAllButtonPosition}`} onClick={() => props.removeAllImagesFromDatabaseLightbox()} >
        <span className="lightbox__clear-images-icon"><ClearAllImagesFromLightboxIcon fill="#FF3939" size="14"  /></span>
        <span className="lightbox__clear-images">
             Clear all
        </span> 
      </div> 
    </>
  );
}

export { Lightbox };