import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { LightboxSummary } from "../components/lightboxSummary";
import ImageViewer from "../components/imageViewer";
import {LightboxModal} from '../components/lightboxModal';

class LightboxList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      importWarning: "",
      newLightboxName: "",
      editedLightboxName: "",
    };
  }

  handleNewLightBoxNameChange = (event) => {
    this.setState({ newLightboxName: event.target.value });
  };

  handleCreateNewLightboxSubmit = (event) => {
    event.preventDefault();

    if (!this.state.newLightboxName || this.state.newLightboxName.length < 2) {

      this.setState({ importWarning: 'Lightbox name has to be at least 2 characters long!' });

    } else {

      if (this.props.allLightboxes.some((lb) => lb.name === this.state.newLightboxName)) {
        
        this.setState({ importWarning: 'A lightbox with the name \'' + this.state.newLightboxName + '\' already exists!' });
      } else {

        this.setState({ importWarning: '' });

        this.props.createNewLightbox(this.state.newLightboxName);
   
        this.setState({ newLightboxName: "" });
      }


    }

    
  };

  editLightbox(lightbox, newLightboxName) {
    
    const newLightboxInfo = {
      id: lightbox.id,
      name: newLightboxName,
      description: lightbox.description,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        User: this.props.user["http://example.com/uuid"],
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newLightboxInfo),
    };

    fetch(process.env.REACT_APP_BE_API_HOST + "/api/lightboxes", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        this.props.getAllLightboxes();
      })
      .catch(console.log);
  }

  handleLightboxWatermarking(lightbox) {
    const requestOptions = {
      method: "POST",
    };

    fetch(process.env.REACT_APP_BE_API_HOST + `/api/lightboxes/${lightbox.id}/watermark`, requestOptions)
      .then(() => {
        this.props.getAllLightboxes();
        this.props.loadLightbox();
      })
      .catch(console.log);
  }

  handleLightboxSharing(lightbox) {
    if (lightbox.shareKey) {
      this.deleteLightboxShareKey(lightbox);
      if (lightbox.watermark) {
        this.handleLightboxWatermarking(lightbox);
      }
    } else {
      this.generateLightboxShareKey(lightbox);
      if (!lightbox.watermark) {
        this.handleLightboxWatermarking(lightbox);
      }
    }
  }

  generateLightboxShareKey(lightbox) {
    
    const requestOptions = {
      method: "POST",
    };

    fetch(process.env.REACT_APP_BE_API_HOST + `/api/lightboxes/${lightbox.id}/share`, requestOptions)
      .then((res) => res.json())
      .then(() => {
        this.props.getAllLightboxes();
      })
      .catch(console.log);
  }

  deleteLightboxShareKey(lightbox) {
    
    const requestOptions = {
      method: "DELETE",
    };

    fetch(process.env.REACT_APP_BE_API_HOST + `/api/lightboxes/${lightbox.id}/share`, requestOptions)
      .then(() => {
        this.props.getAllLightboxes();
      })
      .catch(console.log);
  }

  render() {
    
    let currentUserCreatedLightboxes = (this.props.allLightboxes.length !== 0) ? (this.props.allLightboxes.filter((lb) => lb.createdBy === this.props.user.name)) : [];

    let otherUserCreatedLightboxes = (this.props.allLightboxes.length !== 0) ? (this.props.allLightboxes.filter((lb) => lb.createdBy !== this.props.user.name)) : [];

    let currentlyEditingLightbox = this.props.allLightboxes.find((lb) => lb.id === this.props.currentlyEditingLightbox.id)

    return (
      <>
        {this.props.lightboxDetailModalIsActive &&
          <LightboxModal
            lightbox={currentlyEditingLightbox}
            handleCloseClick={() => this.props.closeLightboxModal()}
            lightboxDetailModalIsActive={this.props.lightboxDetailModalIsActive}
            editLightbox={(lightbox, editedLightboxName) => this.editLightbox(lightbox, editedLightboxName)}
            editedLightboxName={this.state.editedLightboxName}
            handleLightboxSharing={(lightbox) => this.handleLightboxSharing(lightbox)}
            allLightboxes={this.props.allLightboxes}
            handleLightboxWatermarking={(lightbox) => this.handleLightboxWatermarking(lightbox)}   
          />
        }
        <div className="container is-fluid">
          <div className="section lightboxlist">
            <div className="columns is-5">
              <div className="column is-3 lightboxlist__left">
                <form onSubmit={this.handleCreateNewLightboxSubmit}>
                  <div className="lightboxlist__title">
                    <h1 className="title is-3 ">Lightboxes</h1>
                  </div>

                  <div className="lightboxlist__text">
                    Lightboxes that you create, as well as any that you have
                    access to, will be listed here.
                  </div>
                  <div className="columns is-multiline">
                    {this.state.importWarning && (this.state.importWarning.length > 0) && 
                    <div className="column is-full">
                      <article className="message is-warning">
                        <div className="message-header">
                          <p>{this.state.importWarning}</p>
                        </div>
                      </article>
                    </div>
                    }
                    <div className="column is-half">
                      <div className="field">
                        {/* <label className="label">Name</label> */}
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            name="name"
                            placeholder="Give it a name..."
                            onChange={this.handleNewLightBoxNameChange}
                            value={this.state.newLightboxName}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="column is-one-quarter">
                      <button
                        className="button level-item is-submit is-primary"
                        type="submit"
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div className="column is-9 lightboxlist__right">
                <div className="title is-3 mt-4">
                  <span>
                    {" "}
                    Created by you ({currentUserCreatedLightboxes.length}){" "}
                  </span>
                </div>

                {currentUserCreatedLightboxes.map((lightbox) => (
                  <LightboxSummary
                    key={`lightboxByCurrentUser_${lightbox.id}`}
                    lightbox={lightbox}
                    showLargeLightboxImagesById={(id) =>
                      this.props.showLargeLightboxImagesById(id)
                    }
                    handleCurrentLightboxChange={(id) =>
                      this.props.handleCurrentLightboxChange(id)
                    }
                    currentLightbox={this.props.currentLightbox}
                    allLightboxes={this.props.allLightboxes}
                    allowEdit={true}
                    handleEditClick={(lightbox) => this.props.handleEditLightboxClick(lightbox)}
                    downloadImages={(lightbox) => this.props.downloadImages(lightbox)}
                  />
                ))}

                <div className="title is-3 mt-6">
                  <span>
                    {" "}
                    Shared with you ({otherUserCreatedLightboxes.length}){" "}
                  </span>
                </div>

                {otherUserCreatedLightboxes.map((lightbox) => (
                  <LightboxSummary
                    key={`lightboxByCurrentUser_${lightbox.id}`}
                    lightbox={lightbox}
                    showLargeLightboxImagesById={(id) =>
                      this.props.showLargeLightboxImagesById(id)
                    }
                    handleCurrentLightboxChange={(id) =>
                      this.props.handleCurrentLightboxChange(id)
                    }
                    currentLightbox={this.props.currentLightbox}
                    allLightboxes={this.props.allLightboxes}
                    allowEdit={false}
                    handleEditClick={(lightbox) => this.props.handleEditLightboxClick(lightbox)}
                    downloadImages={(lightbox) => this.props.downloadImages(lightbox)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        {this.props.specificLightboxImageViewerIsActive && (
          <ImageViewer
            sharedMode={false}
            images={this.props.selectedLightbox.images}
            imageViewerIsActive={this.props.specificLightboxImageViewerIsActive}
            handleViewerCloseClick={() => this.props.closeViewer()}
            lightboxImages={this.props.lightboxImages}
            imageToLightbox={(image) => this.props.imageToLightbox(image)}
            showLargeImages={(image) =>
              this.props.showLargeSpecificLightboxImages(image)
            }
            imageViewerShowsImagePath={this.props.imageViewerShowsImagePath}
            imageViewerShowsImage={this.props.imageViewerShowsImage}
            removeImageFromLightbox={(image) =>
              this.props.removeImageFromLightbox(image)
            }
            getLocationForId={(id) => this.props.getLocationForId(id)}
            lightboxCreator={this.props.selectedLightbox.createdBy}
            lightboxName={this.props.selectedLightbox.name}
            user={this.props.user}
          />
        )}
      </>
    );
  }
}

export default withRouter(LightboxList);
