import React from "react";
import NotFoundPage from "../components/notFoundPage";
import ImageViewer from "../components/imageViewer";

const SharePage = ({
  sharedLightboxes,
  isSharedPage,
  showLargeSharedImages,
  closeViewer,
  imageToLightbox,
  removeImageFromLightbox,
  imageViewerShowsImage,
  imageViewerShowsImagePath,
  getLocationForId,
  user
}) => {

  const sharedLightbox = sharedLightboxes.find((lb) => lb.shareKey === isSharedPage.params.hash);

  return (
      <>
        { sharedLightbox && sharedLightbox.images.length !== 0  ?
          <ImageViewer
            sharedMode={true}
            showLargeImages={(image) => showLargeSharedImages(image)}
            images={sharedLightboxes.find((lb) => lb.shareKey === isSharedPage.params.hash).images}
            imageViewerIsActive={true}
            handleViewerCloseClick={() => closeViewer()}
            lightboxImages={[]}
            imageToLightbox={(image) => imageToLightbox(image)}
            imageViewerShowsImage={(Object.keys(imageViewerShowsImage).length === 0 &&
              imageViewerShowsImage.constructor === Object) ? (sharedLightboxes.find((lb) => lb.shareKey === isSharedPage.params.hash).images[0]) : (imageViewerShowsImage)}
            imageViewerShowsImagePath={imageViewerShowsImagePath || sharedLightboxes.find((lb) => lb.shareKey === isSharedPage.params.hash).images[0].sources.l}
            removeImageFromLightbox={(image) =>
              removeImageFromLightbox(image)
            }
            getLocationForId={(id) => getLocationForId(id)}
            lightboxCreator={sharedLightbox.createdBy}
            lightboxName={sharedLightbox.name}
            user={user}
          /> 
          :
          <div>
            <NotFoundPage />
          </div>
        }
    </>  
  )
}

export { SharePage };