import React from 'react';
import ReactDOMServer from "react-dom/server";
import { Marker, Popup } from 'react-leaflet'
import L from "leaflet";

const ImageLocationMarkerComponent = ({ imageObj }) => {

  const mapMarkerIcon = L.divIcon({
    className: "custom-icon-dot--large--container",
    iconAnchor:   [3, 14], // point of the icon which will correspond to marker's location
    html: ReactDOMServer.renderToString(
      <span className="custom-icon-dot--large">&#8226;</span>,
    ),
  }) ;

  return (
    <>
      {imageObj == null ? null : 
        <Marker position={imageObj.coords} icon={mapMarkerIcon} interactive={true}>
          <Popup>
            <a href={imageObj.source} target="_blank" rel="noopener noreferrer">See image</a>
          </Popup>
        </Marker>
      }
    </>
  );
}

export { ImageLocationMarkerComponent };