import React from 'react';
import { Attribute } from './attribute';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ImageSummaryReferenceRow = props => {
  return (
    <div className="imagesummaryrow imagesummaryrow--reference columns">
      <div className="column is-4">Path</div>
      {/* <div className="column">Location</div> */}
      <div className="column is-1">Starred</div>
      <div className="column is-5">Content tags</div>
      {/* <div className="column is-1">Vantage point</div>
      <div className="column is-1">ToD</div>
      <div className="column is-1">ToY</div>
      <div className="column is-1">Situation</div>
      <div className="column is-1">Tonality</div> */}
      <div className="column is-1">Availability</div>
    </div>
  );
}

const ImageSummaryRow = props => {
  const displayPath = props.image.path.replace(props.prefix, '…');
  const simpleAttributes = props.referenceData.simpleAttributes;

  const activeClass = (props.isActive) ? 'imagesummaryrow--is-active' : '';
  const selectedClass = (props.isSelected) ? 'imagesummaryrow--is-selected' : '';
  const starredClass = (props.image.starred === "YES") ? 'imagesummaryrow--is-starred' : '';
  // const starredStyle = (props.image.starred === "YES") ? { display: 'none' } : {};
  // const location = (props.location) ? props.location : {};

  return (
    <div className={`imagesummaryrow columns ${activeClass} ${selectedClass} ${starredClass}`}>

      <div className="column is-4 is-drag-selectable" data-imageid={props.image.id} data-image={props.image}>
        <LazyLoadImage
          className="imagesummaryrow__thumbnail"
          src={props.image.sources.m}
          alt={props.image.path}
        />
        <span className="imagesummaryrow__pathname">{displayPath}</span>
      </div>

      {/* <div className="column location is-clickable" onClick={() => props.onAttributeClick(props.image, 'location')}>
        <Attribute type="location" value={location.name} />
      </div> */}

      <div className="column is-1 is-clickable" onClick={() => props.onStarClick(props.image)}>
        <button className="imagestarred">{props.image.starred === "YES" ? "★" : "☆"}</button>
      </div>

      <div
        className="column is-5 tags is-clickable"
        onClick={() => props.onAttributeClick(props.image, 'CONTENT_TAG')}
      >
        {props.image.contentTags.map((tag) => (
          <Attribute
            key={`${props.image.id}_${tag.replace(' ', '_')}`}
            type="tag"
            value={tag}
            tagTree={props.tagTree}
          />
        ))}
      </div>

      {simpleAttributes.filter(attr => attr.name === "AVAILABLE").map((attr) => {
        return (
          <div
            key={`image_${props.image.id}_attr_${attr.name}`}
            className="column tags is-1 is-clickable"
            onClick={() => props.onAttributeClick(props.image, attr.name)}
          >
            <Attribute type={attr.name} value={props.image[attr.apiName]} />
          </div>
        );
      })}

    </div>
  );
}

const ImageSummary = props => {

  const simpleAttributes = props.referenceData.simpleAttributes;

  return (
    <div className={`imagesummary`}>

      <LazyLoadImage
        className="imagesummary__image"
        src={props.image.sources.m}
        alt={props.image.path}
      />

      <div className="imagesummary__tags">
        {props.image.contentTags.map((tag) => (
          <Attribute key={`${props.image.id}_${tag.replace(' ', '_')}`} type="tag" value={tag} />
        ))}
      </div>

      <div className="imagesummary__attrs">
        {simpleAttributes.map((attr) => {
          console.log(attr.apiName);
          console.log(props.image[attr.apiName]);
          return (
            <Attribute
              type={attr.name}
              value={props.image[attr.apiName]}
              appearance="muted"
            />
          );
        })}
      </div>

      {/* <small>{props.image.path}</small> */}

    </div>
  );
}

export { ImageSummary, ImageSummaryRow, ImageSummaryReferenceRow };
