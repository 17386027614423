import React, { useState } from 'react';
import { MapContainer, Polyline, Polygon } from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { LocationMarkerComponent } from "./locationMarkerComponent"
import { ImageLocationMarkerComponent } from "./imageLocationMarkerComponent"
import { MapEditable } from "./mapEditable"

const MapComponent = ({ 
  mapCenter, 
  mapZoom, 
  coordinates, 
  handleMapCenterChange, 
  handleMapCenterZoomChange, 
  handleDeleteMarkerClick,
  saveNewMarkerShift,
  handleChangeMarkerLocation,
  temporaryMarkerCoordinates,
  expandedLocationImageObjects
}) => {

  const [mapIsDragged, setMapIsDragged] = useState(false);

  const fillOptions = { fillColor: '#E14A24', color: '#E14A24' }

  return (
    <div className="modal__location-map mb-5">
      <MapContainer style={{ height: "100%", width: "100%" }} 
        zoom={mapZoom} 
        center={mapCenter}
      >
        <MapEditable 
          mapZoom={mapZoom} 
          center={mapCenter} 
          setMapIsDragged={setMapIsDragged} 
          handleMapCenterChange={handleMapCenterChange} 
          handleMapCenterZoomChange={handleMapCenterZoomChange}
          saveNewMarkerShift={saveNewMarkerShift}
          coordinates={coordinates}
        />
        <ReactLeafletGoogleLayer useGoogMapsLoader={false}/>
          {coordinates && coordinates.length !== 0 &&
            coordinates.map((coord) => {
              
              if (Object.keys(coord).length !== 0 &&
                coord.constructor === Object) {
                const key = coord.lat + coord.lng
                const isMarkerTemporary = temporaryMarkerCoordinates.some((loc) => loc.lat === coord.lat && loc.lng === coord.lng)
                return (
                  <LocationMarkerComponent 
                    key={key} 
                    mapIsDragged={mapIsDragged} 
                    location={coord} 
                    handleDeleteMarkerClick={handleDeleteMarkerClick}
                    handleChangeMarkerLocation={handleChangeMarkerLocation}
                    isMarkerTemporary={isMarkerTemporary}
                  />
                )
              } else {
                return null
              }
            })
          }
          {coordinates && coordinates.length === 2 && 
            <Polyline pathOptions={fillOptions} positions={coordinates} />
          }
          {coordinates && coordinates.length > 2 && 
            <Polygon pathOptions={fillOptions} positions={coordinates} />
          }
          {expandedLocationImageObjects && expandedLocationImageObjects.length!== 0 &&
          expandedLocationImageObjects.map((imageObj) => {
            const key = imageObj.coords.lat + imageObj.coords.lng
            return (
              <ImageLocationMarkerComponent key={key} imageObj={imageObj}/>
            )
          })
        }
      </MapContainer>
    </div>
  );
}

export { MapComponent };