import React, { useRef } from "react"
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { load } from "js-yaml";
// import { load } from 'js-yaml';

export const YamlInputComponent = ({
    yamlRef,
    handleSubmit,
    tagTreeYaml,
    closeYamlComponent,
}) => {
    const formRef = useRef();

    const submitForm = () => {
        if (formRef.current) {
          formRef.current.handleSubmit()
        }
      }

    return (
        <div className={'modal'} ref={yamlRef}>
            <div className="modal-background" onClick={closeYamlComponent}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Update tagging structure</p>
                    <button className="delete" aria-label="close" onClick={closeYamlComponent}></button>
                </header>
                <section className="modal-card-body">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        textArea: tagTreeYaml,
                    }}
                    innerRef={formRef}
                    validationSchema={Yup.object({
                        textArea: Yup.string()
                            .required('Please enter tag structure')
                            .test('correct-yaml','Must be correct YAML-format', async value => {
                                try {
                                    await load(value);
                                    return true;
                                } catch (e) {
                                    return false;
                                }
                            })
                    })}
                    onSubmit={(values) => {
                        console.log("submitting");
                        // console.log(values.textArea)
                        handleSubmit(values.textArea);
                    }}
                >
                    {({ errors, touched, values }) => {
                        // console.log(errors)
                        // console.log(touched)
                        // console.log(values)
                        return (
                            <Form className="text-area-form">
                                {touched.textArea && errors.textArea && <div key={errors.textArea} className="tag-selection-container__error-message">{errors.textArea}</div>}
                                <label htmlFor="textArea">Enter yaml content here:</label>
                                <Field
                                            className="yaml-text-area-container__text-area"
                                            as="textarea"
                                            id="textArea"
                                            name="textArea"
                                        // onChange={handleChange}
                                        />
                            </Form>
                        )
                    }}
                </Formik>
                </section>
                <footer className="modal-card-foot">
                    <button className="button is-success" type="button" onClick={submitForm}>Save changes</button>
                </footer>
            </div>
        </div>
    )
}