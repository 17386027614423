import { useLayoutEffect } from 'react';

export default function useLockBodyScroll() {

  useLayoutEffect(() => {

    //Keyboard keys to prevent
    const preventDefaultForScrollKeys = (e) => {
      let keys = [37, 38, 39, 40];
      if (keys.includes(e.keyCode)) {
        e.preventDefault();
        return false;
      }
    }
    //eventlistener initiated
    window.addEventListener("keydown", preventDefaultForScrollKeys, false);

    // Scrolling prevention
    const selector = document.querySelector('html');
    const originalStyle = window.getComputedStyle(selector).overflow;  
    // Prevent scrolling on mount
    selector.style.overflow = 'hidden';
  
    return () => {
      // Re-enable scrolling and keyboard keys when component unmounts
      selector.style.overflow = originalStyle;
      window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
    }
  }, []); // Empty array ensures effect is only run on mount and unmount
}