import React from 'react';

const AttributeSelector = props => {

    // console.log(props.attribute);

    return (
        <div className="columns is-multiline">
                {props.attribute.values.map((value) => {
                    let valueDisplayName = 'n/a';
                    if (value !== 'NA') {
                        valueDisplayName = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
                        valueDisplayName = valueDisplayName.replace('_', ' ');
                    }
                    return (
                        <div className='column is-full'>
                            <button 
                                key={`attrbtn_${props.attribute.name}_${value}`}
                                className="button is-outlined is-primary level-item"
                                onClick={() => props.handleSaveClick([value])}
                            >{valueDisplayName}</button>
                        </div>
                    );
                })}
        </div>
    );
}

export default AttributeSelector;