import { useEffect } from 'react';
import { useMap } from 'react-leaflet'

const Map = ({ mapCenter, mapZoom, mapFitBounds }) => {
  
  const map = useMap();
  // console.log(map)
  map._onResize();

  useEffect(() => {
    map.panTo(mapCenter);
    // eslint-disable-next-line
  },[mapCenter])

  useEffect(() => {
    if (mapFitBounds.length > 1) {
      setTimeout(() => {
        map.invalidateSize();
        map.fitBounds(mapFitBounds)
      }, 100)
    }
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    map.setZoom(mapZoom);
    map.panTo(mapCenter);
    // eslint-disable-next-line
  },[mapZoom])
  
  return null;
  
}

export { Map };